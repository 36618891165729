import {css, html} from "lit";
import "@material/web/iconbutton/icon-button";

import {WidgetBaseElement} from "./widget-base-element.js";

import "./ui-parts/ui-container.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-title.js";
import "./ui-parts/ui-video-cloudflare.js";
import "./ui-parts/ui-emoji.js";
import "./ui-parts/ui-textfield.js";

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: 0;
    position: relative;
    height: 100%;

    --ui-emoji-color: #ffda6a;
  }

  :host([_hasquestioninput]) {
    --ui-emoji-size: 48px;
  }

  ui-main {
    display: block;
  }

  ui-video-cloudflare {
    z-index: 0;
  }

  ui-title {
    padding: var(--widget-gutter) var(--widget-gutter) 0 var(--widget-gutter);
    box-sizing: border-box;
  }

  .emotions {
    text-align: center;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
  }

  .emotions.pulse ui-emoji {
    box-shadow:
      0 2px 2px 0 var(--ui-emoji-color),
      0 1px 5px 0 var(--ui-emoji-color),
      0 3px 1px -2px var(--ui-emoji-color);
    animation: pulse 1.5s 0s infinite cubic-bezier(0, 0, 0.2, 1);
  }

  .emotions ui-emoji {
    cursor: pointer;
  }

  .emotions.disabled {
    pointer-events: none;
  }

  .emotions .emotion {
    display: inline-block;
    margin: 20px 32px;
  }

  .emotions .emotion-content {
    display: flex;
  }

  .emotions .emotion.floated {
    animation-timing-function: cubic-bezier(0.17, 0.67, 1, 0.85);
    animation: waveY 0.5s;
    animation-timing-function: ease-in;
  }

  .emotions .emotion.floated .emotion-content {
    animation: waveX 0.5s;
    animation-timing-function: ease-in;
  }

  .emotions .emotion.floated ui-emoji {
    animation: scaleOut 0.5s;
  }

  .question {
    display: flex;
    visibility: hidden;
    position: absolute;
    bottom: 48px;
    left: 8px;
    right: 8px;
    background-color: #fff;
    border-radius: 8px;
    padding: 4px 8px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }

  .question .emotion {
    margin: 4px 9px 0 0;
  }

  .question .input {
    flex: 1;
    --md-sys-color-primary: var(--tertiary-text-color);
    --md-filled-field-container-color: var(--fill-color);
  }

  :host([_hasquestion]) .emotions {
    visibility: hidden;
  }

  :host([_hasquestioninput]) .question {
    visibility: visible;
  }

  .next-button {
    margin-top: 8px;
  }

  @keyframes pulse {
    to {
      box-shadow: 0 0 0 32px transparent;
    }
  }

  @keyframes waveX {
    0% {
      transform: translate3D(0, 0, 0);
    }
    20% {
      transform: translate3D(0, 0, 0);
    }
    50% {
      transform: translate3D(10%, 0, 0);
    }
    66% {
      transform: translate3D(-20%, 0, 0);
    }
    100% {
      transform: translate3D(0, 0, 0);
    }
  }

  @keyframes waveY {
    0% {
      transform: translate3D(0, 0, 0);
    }
    100% {
      opacity: 0.5;
      transform: translate3D(0, -200px, 0);
    }
  }

  @keyframes scaleOut {
    50% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(0.1);
    }
  }

  /* HD Ready screens 720p */
  @media screen and (min-width: 600px) and (max-height: 700px) {
    .emotions {
      --ui-emoji-size: 60px;
      --ui-emoji-line-width: 4px;
      height: 100px;
    }

    .question {
      bottom: 28px;
    }
  }
`;

/**
  Play a video from Cloudflare
*/
export class WidgetVideoEmotion extends WidgetBaseElement {
  constructor() {
    super();

    /**
     * If `autoplay` is `true`, tells the browser to immediately start downloading the video and play it as soon as it can.
     */
    this.autoplay = false;

    /**
     * If `lightBox` is `true`, display the video full screen when clicked.
     */
    this.lightBox = false;

    /**
     * Cloudflare video id.
     */
    this.videoId = "";

    /**
     * Start of video, in seconds.
     */
    this.start = 0;

    /**
     * End of video, in seconds.
     */
    this.end = 0;

    /**
     * The length of video segment in seconds.
     * The segment is used for play a video at a random start time.
     * Default value is 0, this mean disabled randomization.
     */
    this.randomSegmentLength = 0;

    /**
     * Title for emotion question.
     */
    this.emotionQuestionTitle =
      "Teď vám přehrajeme jednu ze scén, kdy se změnil váš dojem z videa. Napište nám prosím, jaké to mělo důvody.";

    /**
     * Title for emotion question - when there were no mood changes.
     */
    this.hintNoMoodTitle = "Video vás nezaujalo? Napište nám proč.";

    /**
     * Hint inside the input box for the mood comment - when there were no mood changes.
     */
    this.hintNoMood = "Proč jste ani jednou nekliknuli?";

    /**
     * Hint inside the input box for the positive mood comment.
     */
    this.hintPosMood = "";

    /**
     * Hint inside the input box for the negative mood comment.
     */
    this.hintNegMood = "";

    /**
     * Hint inside the input box for the mood comment.
     */
    this.hintMood = "Co vás upoutalo?";

    /**
     * The maximum number of questions to ask. If 0, no questions are asked,
     * not even the "technical" one when the slider was not moved.
     */
    this.maxQuestions = 1;

    /**
     * Moody segment - seconds to seek before the 'jump'.
     */
    this.segmentBefore = 2;

    /**
     * Moody segment - seconds to play after the 'jump'.
     */
    this.segmentAfter = 2;

    this.addEventListener("enter-stage", this._onStageEntered);
    this.addEventListener("exit-stage", this._onStageExited);

    this._isVideoRunning = false;
    this._hasQuestion = false;
    this._hasQuestionInput = false;

    this._emoji = {pos: "yay", neg: "sad"};
    this._emojiTypes = [this._emoji.pos, this._emoji.neg];

    this._question = "";
    this._showDetails = true;

    /**
     * Delay after video end for user click on emotions.
     */
    this._delayAfterEnd = 700;

    // FIXME(liborm): temporarily disabled due to possible confusion
    // when the previous 'example' widget-image has these emotions
    // in reverse order
    // this._shuffleArray(this._emojiTypes);
  }

  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      videoId: {
        type: String,
      },

      lightBox: {
        type: Boolean,
        reflect: true,
      },

      randomSegmentLength: {
        type: Number,
      },

      start: {
        type: Number,
      },

      end: {
        type: Number,
      },

      autoplay: {
        type: Boolean,
      },

      _isVideoRunning: {
        type: Boolean,
        reflect: true,
      },

      _hasQuestion: {
        type: Boolean,
        // reflect attribute for css styles
        reflect: true,
      },

      _hasQuestionInput: {
        type: Boolean,
        // reflect attribute for css styles
        reflect: true,
      },

      _question: {
        type: String,
      },

      _showDetails: {
        type: Boolean,
      },
    };
  }

  render() {
    return html`
      <ui-container id="container" isFlex>
        <ui-title
          .question="${this._question || this.question}"
          .details="${this._showDetails ? this.details : ""}"
        ></ui-title>

        <ui-main>
          <ui-video-cloudflare
            id="player"
            .videoId="${this.videoId}"
            .lightBox="${this.lightBox}"
            .randomSegmentLength="${this.randomSegmentLength}"
            .start="${this.start}"
            .end="${this.end}"
            .autoplay="${this.autoplay}"
            .parent="${{name: this.localName, id: this.id}}"
            .cloudFlareBaseUrl="${this._cloudFlareBaseUrl}"
            @play="${this._onVideoPlay}"
            @ended="${this._onVideoEnded}"
            @canplay="${() =>
              this.dispatchEvent(
                new CustomEvent("media-loaded", {
                  detail: {src: this.videoId},
                }),
              )}"
          ></ui-video-cloudflare>

          <div class="question">
            ${this._questionEmoji
              ? html`
                  <div class="emotion">
                    <ui-emoji type="${this._questionEmoji}"></ui-emoji>
                  </div>
                `
              : ""}
            <ui-textfield
              class="input"
              id="input"
              label="${this._inputLabel}"
              @keyup="${this._onInputKeyUp}"
            ></ui-textfield>
            <md-icon-button
              class="next-button"
              id="nextButton"
              @click="${this._onNextButtonClick}"
              hidden
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <path
                  fill="currentColor"
                  d="M6 40V8l38 16Zm3-4.65L36.2 24 9 12.5v8.4L21.1 24 9 27Zm0 0V12.5 27Z"
                />
              </svg>
            </md-icon-button>
          </div>

          <div class="emotions">
            <div class="emotion" @click="${this._onEmotionClick}">
              <div class="emotion-content">
                <ui-emoji type="${this._emojiTypes[0]}" animated></ui-emoji>
              </div>
            </div>
            <div class="emotion" @click="${this._onEmotionClick}">
              <div class="emotion-content">
                <ui-emoji type="${this._emojiTypes[1]}" animated></ui-emoji>
              </div>
            </div>
          </div>
        </ui-main>
      </ui-container>
    `;
  }

  firstUpdated() {
    super.firstUpdated();

    this._player = this.shadowRoot.getElementById("player");
    this._emotions = this.shadowRoot.querySelector(".emotions");
    this._nextButton = this.shadowRoot.getElementById("nextButton");
    this._input = this.shadowRoot.getElementById("input");

    setTimeout(() => {
      const emojis = this.shadowRoot.querySelectorAll("ui-emoji");

      emojis.forEach((emoji) => {
        emoji.removeAttribute("animated");
      });
    }, 4000);

    // init the player here, so we can preload the video on second a card on background
    this._player.init();

    this.dispatchEvent(
      new CustomEvent("waiting-for-media", {
        detail: {src: this.videoId},
      }),
    );
  }

  _onStageExited() {
    /**
     * if the widget goes off stage for any reason, we don't want the video
     * to play any more...
     */
    if (this._player) {
      this._player.pause();
      this._player.cleanDOM();
      this._globalStyle?.remove();
    }
  }

  async _onStageEntered() {
    this._emotionAnswers = [];
    this._questionAnswers = [];

    await this.updateComplete;

    // reset the answer for back navigation to previous widget
    this.answer = null;

    await this._player.updateComplete;

    // reset the player for displaying the video again, if the user goes back to this widget
    this._player.reset();

    if (this.lightBox) {
      this._addGlobalStyle();
    }

    if (this.autoplay) {
      this._player.play();
    }
  }

  _onVideoPlay() {
    if (this._hasQuestion) {
      this._hasQuestionInput = true;
      return;
    }

    this._isVideoRunning = true;
    this._emotions.classList.add("pulse");

    if (this.lightBox && !document.querySelector(".lightbox-video .emotions")) {
      // move emotions to lightbox
      document.querySelector(".lightbox-video").appendChild(this._emotions);
    }
  }

  _onVideoEnded(event) {
    if (this._hasQuestion) {
      if (this.lightBox) this._player.hideLightBox();
      return;
    }

    this._answer = event.detail;
    this._answer.delayAfterEnd = this._delayAfterEnd;

    // wait for specific time after the video end for user click on emotions
    setTimeout(() => {
      this._isVideoRunning = false;
      this._emotions.classList.remove("pulse");

      this._answer.emotions = this._emotionAnswers;

      if (this.lightBox && this._emotions) this._emotions.remove();
      if (this.lightBox) this._player.hideLightBox();

      if (this.maxQuestions) {
        this._questions = [...this._emotionAnswers]; // clone
        this._hasQuestion = true;
        this._question = this._questions.length
          ? this.emotionQuestionTitle
          : this.hintNoMoodTitle;
        this._showDetails = false;
        this._showQuestion();
      } else {
        this._emotions.classList.add("disabled");
        this.style.setProperty("--ui-emoji-color", "#eee");
        this.style.setProperty("--ui-emoji-mouth-color", "#eee");
        this.style.setProperty("--ui-emoji-line-color", "#aaa");
        this.answer = this._answer;
      }
    }, this._delayAfterEnd);
  }

  _onEmotionClick(event) {
    this._emotions.classList.remove("pulse");

    const emotionClone = event.currentTarget;
    const emoji = emotionClone.firstElementChild.firstElementChild;
    emoji.removeAttribute("animated");
    emotionClone.classList.add("floated");

    if (this._isVideoRunning) {
      this._emotionAnswers.push({
        type:
          emoji.getAttribute("type") === this._emoji.pos
            ? "positive"
            : "negative",
        time: this._player.player.currentTime,
      });
    }

    setTimeout(() => {
      emotionClone.style.opacity = 0;
    }, 450);

    setTimeout(() => {
      emotionClone.classList.remove("floated");
    }, 500);

    setTimeout(() => {
      emotionClone.style.opacity = 1;
      if (this._isVideoRunning) this._emotions.classList.add("pulse");
    }, 550);
  }

  // https://stackoverflow.com/a/12646864/1614237
  _shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1));
      // eslint-disable-next-line no-param-reassign
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  _getRandomInt(min, max) {
    // eslint-disable-next-line no-param-reassign
    min = Math.ceil(min);
    // eslint-disable-next-line no-param-reassign
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  _showQuestion() {
    if (this._questions.length) {
      const questionIndex = this._getRandomInt(0, this._questions.length);
      this._currentQuestion = this._questions[questionIndex];
      this._questions.splice(questionIndex, 1);

      const isPositiveQuestion = this._currentQuestion.type === "positive";
      const startTime = Math.max(
        this.start,
        this._currentQuestion.time - this.segmentBefore,
      );

      // end time can be either the end of the video or the end of the segment
      // end of the video segment can be more than the end of the video
      const endTime = this.end
        ? Math.min(this.end, this._currentQuestion.time + this.segmentAfter)
        : this._currentQuestion.time + this.segmentAfter;

      if (isPositiveQuestion) {
        this._questionEmoji = this._emoji.pos;
        this._inputLabel = this.hintPosMood || this.hintMood;
      } else {
        this._questionEmoji = this._emoji.neg;
        this._inputLabel = this.hintNegMood || this.hintMood;
      }

      this._player.start = startTime;
      this._player.end = endTime;
      this._player.randomSegmentLength = 0;
      this._player.autoplay = false;
      this._player.reset();
    } else {
      this._inputLabel = this.hintNoMood;
      this._hasQuestionInput = true;
    }
  }

  _saveQuestionAnswer(value) {
    this._questionAnswers.push({
      value,
      type: this._currentQuestion?.type || "none",
      time: this._currentQuestion?.time || 0,
    });
    this._hasQuestionInput = false;

    if (
      this._emotionAnswers.length === 0 ||
      this._questionAnswers.length ===
        Math.min(this.maxQuestions, this._emotionAnswers.length)
    ) {
      this._player.pause();
      this._answer.questions = this._questionAnswers;
      this._skipNextActionButton = true;
      this.answer = this._answer;
    } else {
      this._showQuestion();
    }
  }

  _onInputKeyUp(event) {
    const input = event.currentTarget;
    const value = input.value.trim();

    if (event.key === "Enter" && value) {
      this._saveQuestionAnswer(value);
      input.value = "";
    }

    this._nextButton.hidden = value === "";
  }

  _onNextButtonClick() {
    this._saveQuestionAnswer(this._input.value.trim());
  }

  _onWidgetResize() {
    if (this._player) this._player.setPlayerSize();
  }

  _reset() {
    this._player.reset();

    this._isVideoRunning = false;
    this._emotions.classList.remove("pulse");
    this._hasQuestion = false;
    this._hasQuestionInput = false;
    this._question = "";
    this._showDetails = true;

    this._answer = null;
  }

  _addGlobalStyle() {
    this._globalStyle = document.createElement("style");
    this._globalStyle.id = "widget-video-emotion";
    this._globalStyle.innerHTML = `
      .lightbox-video .emotions {
        text-align: center;
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: center;

        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1001;
        width: 300px;
      }
    
      .lightbox-video .emotions.pulse ui-emoji {
        box-shadow: 0 2px 2px 0 var(--ui-emoji-color),
          0 1px 5px 0 var(--ui-emoji-color), 0 3px 1px -2px var(--ui-emoji-color);
        animation: pulse 1.5s 0s infinite cubic-bezier(0, 0, 0.2, 1);
      }
    
      .lightbox-video .emotions ui-emoji {
        cursor: pointer;
      }
    
      .lightbox-video .emotions.disabled {
        pointer-events: none;
      }
    
      .lightbox-video .emotions .emotion {
        display: inline-block;
        margin: 20px 32px;
      }
    
      .lightbox-video .emotions .emotion-content {
        display: flex;
      }
    
      .lightbox-video .emotions .emotion.floated {
        animation-timing-function: cubic-bezier(0.17, 0.67, 1, 0.85);
        animation: waveY 0.5s;
        animation-timing-function: ease-in;
      }
    
      .lightbox-video .emotions .emotion.floated .emotion-content {
        animation: waveX 0.5s;
        animation-timing-function: ease-in;
      }
    
      .lightbox-video .emotions .emotion.floated ui-emoji {
        animation: scaleOut 0.5s;
      }

      @keyframes pulse {
        to {
          box-shadow: 0 0 0 32px transparent;
        }
      }
    
      @keyframes waveX {
        0% {
          transform: translate3D(0, 0, 0);
        }
        20% {
          transform: translate3D(0, 0, 0);
        }
        50% {
          transform: translate3D(10%, 0, 0);
        }
        66% {
          transform: translate3D(-20%, 0, 0);
        }
        100% {
          transform: translate3D(0, 0, 0);
        }
      }
    
      @keyframes waveY {
        0% {
          transform: translate3D(0, 0, 0);
        }
        100% {
          opacity: 0.5;
          transform: translate3D(0, -200px, 0);
        }
      }
    
      @keyframes scaleOut {
        50% {
          transform: scale(0.7);
        }
        100% {
          transform: scale(0.1);
        }
      }
    `;
    document.head.appendChild(this._globalStyle);
  }
}

window.customElements.define("widget-video-emotion", WidgetVideoEmotion);
