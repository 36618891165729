// Clicking on the children will cause them to be selected.
// The ui-multi-selector elements monitors for the addition and removal of child
// elements and will update the 'ui-selected' class as needed. Note that it
// does not monitor the 'ui-selected' class of child elements, and will not
// update the 'ui-selected' class if they are changed directly.

// Inspired by
// https://github.com/google/elements-sk/blob/master/src/multi-select-sk/multi-select-sk.ts
// https://bitworking.org/news/2019/07/looking-back-on-five-years-of-web-components/

import {upgradeProperty} from "../utils/utils.js";

class UIMultiSelector extends HTMLElement {
  constructor() {
    super();
    // Keep _selection up to date by monitoring DOM changes.
    this._obs = new MutationObserver(() => this._bubbleUp());
    this._selection = [];
  }

  connectedCallback() {
    upgradeProperty(this, "selection");
    upgradeProperty(this, "disabled");
    this.addEventListener("click", this._click);
    this._obs.observe(this, {
      childList: true,
    });
    this._bubbleUp();
    this.attrForSelected = this.getAttribute("attr-for-selected") || "name";
  }

  disconnectedCallback() {
    this.removeEventListener("click", this._click);
    this._obs.disconnect();
  }

  /** Whether this element should respond to input. */
  get disabled() {
    return this.hasAttribute("disabled");
  }

  set disabled(val) {
    if (val) {
      this.setAttribute("disabled", "");
      this.selection = [];
    } else {
      this.removeAttribute("disabled");
      this._bubbleUp();
    }
  }

  /**
   * A sorted array of indices that are selected or [] if nothing is selected.
   * If selection is set to a not sorted array, it will be sorted anyway.
   */
  get selection() {
    // clone array
    return this._selection.slice(0);
  }

  set selection(value) {
    if (this.disabled) return;

    this._selection = value || [];

    this._rationalize();

    this.dispatchEvent(
      new CustomEvent("selection-changed", {
        detail: {
          selection: this.selection,
        },
        bubbles: true,
      }),
    );
  }

  /**
   * Reset the selection to the empty array.
   */
  reset() {
    this._selection = [];
    this._rationalize();
  }

  selectItem(name) {
    const newSelection = this.selection;
    newSelection.push(name);
    this.selection = newSelection;
  }

  unselectItem(name) {
    const index = this._selection.indexOf(name);
    if (index >= 0) {
      const newSelection = this.selection;
      newSelection.splice(index, 1);
      this.selection = newSelection;
    }
  }

  _click(e) {
    if (this.disabled) return;

    // Look up the DOM path until we find an element that is a child of
    // 'this', and set _selection based on that.
    let {target} = e;
    while (target && target.parentElement !== this) {
      target = target.parentElement;
    }

    if (!target || target.parentElement !== this) return; // not a click we care about

    const itemName = target.getAttribute(this.attrForSelected);

    // deselect the item
    if (this._selection.includes(itemName)) {
      if (this.getAttribute("rank") !== null) {
        // the user can deselect only the last choice
        // if deselecting, prevent deselection of non-last elements
        if (itemName !== this._selection.slice(-1)[0]) {
          if (this._selection.length === this.children.length) {
            this.selection = [];
          }
        } else {
          this.unselectItem(itemName);
        }
      } else {
        this.unselectItem(itemName);
      }
      // select the item
    } else if (this.getAttribute("multi") !== null) {
      // selecting 'none' deselect all other values
      if (itemName === "none") this.selection = [];

      // selecting other value deselects 'none', if there is any
      this.unselectItem("none");

      this.selectItem(itemName);
    } else if (this.getAttribute("rank") !== null) {
      // show badge
      if (!this._selection.includes(itemName)) {
        const badge = target.querySelector(".badge");

        if (badge) badge.textContent = this._selection.length + 1;
      }

      this.selectItem(itemName);
    } else {
      this.selection = [itemName];
    }
  }

  // Loop over all immediate child elements update the selected attributes
  // based on the selected property of this element.
  _rationalize() {
    Array.from(this.children).forEach((child) => {
      if (child.getAttribute("tabindex") == null) {
        child.setAttribute("tabindex", "0");
      }

      if (
        this._selection.find(
          (item) => item === child.getAttribute(this.attrForSelected),
        ) !== undefined
      ) {
        child.classList.add("ui-selected");
      } else {
        child.classList.remove("ui-selected");
      }
    });
  }

  // Loop over all immediate child elements and find all with the selected
  // attribute.
  _bubbleUp() {
    if (this.disabled) return;

    this._selection = [];

    for (let i = 0; i < this.children.length; i += 1) {
      if (this.children[i].classList.contains("ui-selected")) {
        this._selection.push(
          this.children[i].getAttribute(this.attrForSelected),
        );
      }
    }
    this._rationalize();
  }
}

window.customElements.define("ui-multi-selector", UIMultiSelector);
