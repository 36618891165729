// Mixins with JavaScript Classes
// https://justinfagnani.com/2015/12/21/real-mixins-with-javascript-classes/

import {
  customEvent,
  getAssetPath,
  getImageSrcSet,
  debounce,
} from "../utils/utils.js";

import {decodeSlug, encodeSlug, encodeValues} from "../utils/base58.js";

/**
 * Base mixin for all widgets
 *
 * Implements the widget lifecycle.
 */
export const WidgetBaseMixin = (superclass) =>
  class extends superclass {
    /**
     * Should be fired when the whole widget is initialized.
     *
     * {tasks: tasks, widget_id: this.id}
     *
     * @event widget-ready
     */

    static get properties() {
      return {
        /**
         * Identifies the question in the qset.
         * Has to be unique per qset.
         */
        id: {type: String, reflect: true},

        /**
         * Chooses the widget type.
         * type `xyz` loads `widget-xyz.html`.
         */
        type: {type: String},

        /**
         * The valid answer to the question. If empty,
         * the answer is considered invalid.
         * - if `multi`: Array of selected IDs
         * - if `rank`: Array of all IDs in chosen order
         * - if single choice (!multi && !rank): `["String ID"]`
         *   (same as one choice in `multi`)
         */
        answer: {
          type: Object,
        },

        /**
         * Text. Inline markdown formating (italic, bold). Every Minute has a question.
         */
        question: {type: String},

        /**
         * If there is a question given in image form (fancy fonts...), use this
         * as alt text. Also use it in the back end to caption exports.
         */
        questionAlt: {type: String},

        /**
         * Short text under the question. Helps users understand what they
         * have to do. Inline markdown formating (italic, bold).
         */
        details: {type: String},

        /**
         * If `answer` is invalid (empty), allow the user to continue
         * after `skipTimeout` seconds. `0` means valid answer
         * is required
         */
        skipTimeout: {type: Number},

        /**
         * Each question can be conditioned on answers from previous
         * questions, and some other user info sent from the server.
         * The conditions are expressed in special language based on
         * s-expressions ala `(is self.piggy_lover yes)`.
         *
         * The language is documented in [WidgetBehavior](#TrendaroBehaviors.WidgetBehavior).
         */
        condition: {type: String},

        /**
         * Time in seconds for countdown timer.
         */
        countdown: {type: Number},

        /**
         * Theme of parent qset.
         */
        _theme: {type: String},

        /**
         * Base URL for media.
         */
        _mediaBaseUrl: {type: String},

        /**
         * Base URL for CloudFlare media.
         */
        _cloudFlareBaseUrl: {type: String},

        /**
         * The `evalCondition` of parent `qset-questions`. Some widgets need to
         * query the values (answers) from other widgets. This provides an (intentionally)
         * limited interface so we don't get too much spaghetti.
         */
        _evalCondition: {type: Object},

        /**
         * The `getValue` of parent `qset-questions`. Some widgets need to
         * query the values (answers) from other widgets. This provides an (intentionally)
         * limited interface so we don't get too much spaghetti.
         */
        _getValue: {type: Object},

        _timeoutRemaining: {type: Boolean},
        _timerHandle: {type: Object},

        /**
         * If not sure, widgets can check if they're on stage.
         */
        _isOnStage: {type: Boolean},

        /**
         * If the widget is on tablet screen size.
         */
        _isTabletWide: {type: Boolean},

        /**
         * If true, don't show next action button button and go to the next question.
         */
        _skipNextActionButton: {type: Boolean},

        /**
         * Right to left text and UI direction.
         */
        _rtl: {type: Boolean, attribute: "rtl", reflect: true},
      };
    }

    constructor() {
      super();
      this.answer = null;
      this.question = "";
      this.questionAlt = "";
      this.details = "";
      this.skipTimeout = 0;
      this.countdown = 0;

      /**
       * The widget is ready when all tasks are done.
       * @type {boolean} true if widget is ready
       */
      this.isReady = false;

      /**
       * Name of the host app.
       */
      this._hostApp = "";

      this._mediaBaseUrl = "";

      // default value for stories
      this._cloudFlareBaseUrl =
        "https://customer-9ahnq69tzs27hc88.cloudflarestream.com";

      this._isOnStage = false;

      this._skipNextActionButton = false;

      // If true, don't show next action button.
      this._hideNextActionButton = false;

      // array of images and videos are waiting for load
      this._mediaWaiting = [];

      // array of loaded images (for tracking load times)
      this._imagesLoaded = [];

      // get RTL from document element <html>
      this._rtl = document.documentElement.dir === "rtl";

      // detect if the widget is on tablet wide screen
      this._isTabletWide = window.matchMedia(
        "screen and (min-width:600px) and (min-height:480px)",
      ).matches;

      // debug mode
      this._debugMode = !!window.__debugWidgets;
      this._log = (...args) => {
        if (this._debugMode) {
          // eslint-disable-next-line no-console
          console.log(
            `%cWidget ${this.type}-${this.id}:`,
            `color: LimeGreen`,
            ...args,
          );
        }
      };

      // debounce widget resize, because it's called too often, and it's not needed
      this._debouncedWidgetResize = debounce(() => {
        // We wrap it in requestAnimationFrame to avoid this error in test - ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => {
          this._isTabletWide = window.matchMedia(
            "screen and (min-width:600px) and (min-height:480px)",
          ).matches;

          this._onWidgetResize();

          this._log(
            "Resize, width:",
            this.offsetWidth,
            "height:",
            this.offsetHeight,
          );
        });
      }, 100);

      this.__resizeObserver = new ResizeObserver(() => {
        this._debouncedWidgetResize();
      });

      this.addEventListener("next-clicked", this._nextClicked);
      this.addEventListener("skip-clicked", this._skipClicked);

      this.addEventListener("enter-stage", this._stageEntered);
      this.addEventListener("exit-stage", this._stageExited);
      this.addEventListener("stage-ready", this._onStageReady);

      this.addEventListener("countdown-ended", this.__onCountdownEnded);

      this.addEventListener("ui-image-waiting-for-media", this._onMediaInit);
      this.addEventListener("ui-image-media-loaded", this._onMediaLoad);
      this.addEventListener("ui-svg-waiting-for-media", this._onMediaInit);
      this.addEventListener("ui-svg-media-loaded", this._onMediaLoad);

      // local events from widget-image, widget-video, widget-video-cloudflare
      // widget-video-emotion, widget-random-pairs
      this.addEventListener("waiting-for-media", this._onMediaInit);
      this.addEventListener("media-loaded", this._onMediaLoad);
    }

    updated(changedProperties) {
      if (super.updated) super.updated(changedProperties);

      if (changedProperties.has("answer")) {
        this._log(
          "Answer, old:",
          changedProperties.get("answer"),
          "new:",
          this.answer,
        );
        this._answerChanged(this.answer, changedProperties.get("answer"));
      }
    }

    /**
     * Called after the component's DOM has been updated the first time, immediately before updated() is called.
     * Implement firstUpdated() to perform one-time work after the component's DOM has been created.
     * Setting properties inside firstUpdated() will trigger the updated() lifecycle method.
     * Generally, you should try to delay work until this time.
     * @see https://lit.dev/docs/components/lifecycle/#firstupdated
     * @returns {void}
     * @override LitElement firstUpdated method
     */
    firstUpdated() {
      // var tasks = this._getTasks();
      // this._fireReadyEvent(tasks);

      this._hasMedia = this._detectMedia();

      // if the widget has no media, fire widget-ready event
      if (!this._hasMedia) this._fireReadyEvent();

      // initial resize, when the widget is not resized by the parent
      this._debouncedWidgetResize();

      /**
       * Throttled `advance-question` event.
       */
      this._throttledAdvanceQuestion = this._throttle(() => {
        this.dispatchEvent(
          customEvent(
            "widget-advance-question",
            {just_answered: this.id, answer: this.answer}, // answer var is for debug only
            true,
          ),
        );
      }, 1000);
    }

    connectedCallback() {
      super.connectedCallback();
      this.__resizeObserver?.observe(this);
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      this.__resizeObserver?.unobserve(this);
    }

    /**
     * Dispatch throttled `advance-question` event.
     */
    _advanceQuestion() {
      this._throttledAdvanceQuestion();
    }

    /**
     * Default action for clicking the `next` button.
     * Override if the widget needs to do something else.
     */
    _nextClicked() {
      this._throttledAdvanceQuestion();
    }

    /**
     * Default action for clicking the `skip` button.
     * Override if the widget needs to do something else.
     */
    _skipClicked() {
      this._throttledAdvanceQuestion();
    }

    /**
     * Return the number of mini-tasks in this widget.
     * Defaults to 1. Override if the widget requires more work by the user.
     */
    _getTasks() {
      return 1;
    }

    /**
     * Fires `widget-ready` event in the default behavior.
     * It's called from `attach`. If the widget needs more processing to become
     * ready (loading images, buffering video), override this with empty function
     * and fire the event when needed.
     *
     * Lifecycle callbacks are called for all behaviors, so this is a workaround
     * for canceling the default behavior.
     */
    _fireReadyEvent(tasks = 1) {
      this.isReady = true;
      this.dispatchEvent(
        customEvent("widget-ready", {tasks, widget_id: this.id}, true),
      );
    }

    /**
     * Convert object to bool in the 'python way'
     * ie empty compound objects are still `false`.
     */
    _objBool(obj) {
      if (obj instanceof Object) return Boolean(Object.keys(obj).length);

      if (obj instanceof Array) return Boolean(obj.length);

      return Boolean(obj);
    }

    /**
     * Check if `answer` is valid, if any change in validity
     * occured, and call the appropriate callback.
     */
    _answerChanged(newVal, oldVal, force) {
      // FIXME: remove multiple changes of .answer using the following .log
      // console.log('_answerChanged', this.nodeName, this.id, oldVal, newVal);

      // it we're not on stage, answer is not supposed to change
      if (!this._isOnStage) return;

      this.dispatchEvent(
        customEvent("testing-answer-updated", {answer: newVal, oldVal}, true),
      );

      // compare the validity of old and new answers
      // so we don't send repeated events when the answer changes,
      // but the validity stays
      const newOk = this._objBool(newVal);
      const oldOk = this._objBool(oldVal);

      if (newOk !== oldOk || this._skipNextActionButton || force) {
        if (newOk) this._validAnswer();
        else this._invalidAnswer();
      }
    }

    /**
     * Default action for valid answer is to show the 'next' action button.
     */
    _validAnswer() {
      if (this._skipNextActionButton) {
        this._skipNextActionButton = false;
        // Ignore double click, ex. from widget-buttons
        this._throttledAdvanceQuestion();
      } else if (!this._hideNextActionButton) {
        this.dispatchEvent(customEvent("show-next-action-button", null, true));
      }
    }

    /**
     * Default action for invalid answer is to hide the 'next' action button
     * and if the user is allowed to skip the question, show the 'skip' action button.
     */
    _invalidAnswer() {
      if (this.skipTimeout > 0 && !this._timeoutRemaining) {
        this.dispatchEvent(customEvent("show-skip-action-button", null, true));
      } else {
        this.dispatchEvent(customEvent("hide-action-button", null, true));
      }
    }

    /**
     * Remove diacritics, spaces and commas from string. Used in filter.
     */
    _normalizeString(text) {
      // ES6 solution: https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
      // var chars='áäčďéěíĺľňóôőöŕšťúůűüýřžÁÄČĎÉĚÍĹĽŇÓÔŐÖŔŠŤÚŮŰÜÝŘŽ';
      // var normalizedChars='aacdeeillnoooorstuuuuyrzAACDEEILLNOOOORSTUUUUYRZ';
      const chars = "áäčďéěíĺľňóôőöŕšťúůűüýřž";
      const normalizedChars = "aacdeeillnoooorstuuuuyrz";
      let normalizedText = "";
      const lowerText = text.toLowerCase();

      for (let index = 0; index < lowerText.length; index += 1) {
        if (chars.indexOf(lowerText.charAt(index)) !== -1) {
          normalizedText += normalizedChars.charAt(
            chars.indexOf(lowerText.charAt(index)),
          );
        } else {
          normalizedText += lowerText.charAt(index);
        }
      }

      return normalizedText.replace(/[ ,]/g, "");
    }

    /**
     * When the widget enters stage:
     * - hide the action button
     * - start timeout for the 'skip' button
     * - TODO: start answer timing
     */
    _stageEntered() {
      // start the timer
      if (this.skipTimeout > 0) {
        this._timeoutRemaining = true;

        this._timerHandle = setTimeout(() => {
          this._timeoutRemaining = false;
          if (!this._objBool(this.answer)) this._invalidAnswer();
        }, this.skipTimeout * 1000);
      }

      this._isOnStage = true;

      // fake notification on answer - if eg. default answer was provided
      // this has to be done after the element has any parent to have any effect
      // and also it has to be done after the widget entered stage
      // so `attached()` is not an option
      this._answerChanged(this.answer, undefined, true);

      if (this.countdown) this._startCountdown(Number(this.countdown));
    }

    /**
     * When the widget exits stage, cancel 'skip' timeout.
     */
    _stageExited() {
      this._isOnStage = false;

      // cancel the timeout
      clearTimeout(this._timerHandle);

      // Cleanup icons for SVG gradient issue with multiple IDs on ShadowDOM.
      const icons = this.shadowRoot.querySelectorAll("s-svg");
      if (icons.length) {
        setTimeout(() => {
          icons.forEach((icon) => {
            icon.remove();
          });
        }, 500);
      }
    }

    /**
     * Helper functions.
     */

    /**
     * `_any()` returns `true` if any of its arguments is true.
     */
    _any() {
      for (let i = 0; i < arguments.length; i += 1) {
        // eslint-disable-next-line prefer-rest-params
        if (this._objBool(arguments[i])) return true;
      }
      return false;
    }

    /**
     * `_all()` returns `true` if all of its arguments are true.
     */
    _all() {
      for (let i = 0; i < arguments.length; i += 1) {
        // eslint-disable-next-line prefer-rest-params
        if (!this._objBool(arguments[i])) return false;
      }
      return true;
    }

    /**
     * Return asset path from hash.
     */
    _computeAssetPath(path) {
      return getAssetPath(this._mediaBaseUrl, path);
    }

    /**
     * Return dummy array for <template is="dom-repeat">.
     * http://stackoverflow.com/questions/3895478/does-javascript-have-a-method-like-range-to-generate-an-array-based-on-suppl
     */
    _computeDummyArray(count) {
      // eslint-disable-next-line prefer-spread
      return Array.apply(null, Array(Number(count))).map((_, i) => i);
    }

    /**
     * Return image srcset.
     */
    _computeImageSrcSet(getImageSrcFunction, path, imageWithText) {
      // the image with text doesn't need srcset, because the image src contains path to image in double size
      if (imageWithText) return undefined;

      return getImageSrcSet(getImageSrcFunction, path);
    }

    /**
     * Return boolean of value.
     */
    _isValue(value) {
      return !!value;
    }

    /**
     * Run `callback(key, value)` for each key in Object.
     */
    _forEach(obj, callback) {
      Object.keys(obj).forEach((key) => {
        callback(key, obj[key]);
      });
    }

    /**
     * Return `{val1:k1, val2:k2}` for `{k1:val1, k2:val2}`.
     * FIXME: check this and replace ;)
     * Object.keys(o).reduce(function(acc, k){ acc[o[k]] = k; return acc }, {})
     */
    _invertMap(obj) {
      const tuples = Object.keys(obj).map((key) => [key, obj[key]]);
      const result = {};
      // eslint-disable-next-line prefer-destructuring
      tuples.forEach((t) => {
        [result[t[1]]] = t;
      });
      return result;
    }

    /**
     * Returns A == B, useful for property bindings.
     */
    _equals(A, B) {
      return A === B;
    }

    /**
     * Return A if P(redicate) is true, else B, useful for property bindings.
     */
    _ifelse(P, A, B) {
      return P ? A : B;
    }

    /**
     * _ifelse(V1 == V2, A, B), useful for property bindings.
     */
    _ifelseEq(V1, V2, A, B) {
      return V1 === V2 ? A : B;
    }

    /**
     * Return A unless it's one of V1, V2 .., useful for property bindings.
     */
    _valueExcept(A) {
      for (let i = 1; i < arguments.length; i += 1) {
        // eslint-disable-next-line prefer-rest-params
        if (A === arguments[i]) return "";
      }
      return A;
    }

    /**
     * Convert string to 'expected' Boolean value, useful for property bindings.
     */
    _strBool(value) {
      if (typeof value === "string") {
        // eslint-disable-next-line no-param-reassign
        value = value.toLowerCase();
      }
      switch (value) {
        // handle cases which we need to be false
        case "false":
        case "0":
        case "no":
          return false;
        default:
          return Boolean(value);
      }
    }

    /**
     * Empty function for using in a widget.
     */
    _onWidgetResize() {}

    /**
     * Handle stage ready event.
     */
    _onStageReady() {}

    /**
     * Throttle function for ignore double click.
     * https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
     */
    _throttle(func, limit) {
      let inThrottle = false;
      return () => {
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => {
            inThrottle = false;
          }, limit);
        }
      };
    }

    /**
     * Return true if the widget has media.
     * @returns {boolean}
     */
    _detectMedia() {
      const values = [
        ...(this.values || []),
        ...(this.buttons || []), // widget-pairs
        ...(this.optionsFirst || []), // widget-random-pairs
        ...(this.optionsSecond || []), // widget-random-pairs
      ];

      return Boolean(
        this.image ||
          this.video || // widget-video
          this.videoId || // widget-video-cloudflare, widget-video-emotion
          values.find((i) => i.image || i.icon),
      );
    }

    _onMediaInit(event) {
      // console.log("media-init", this.id, event.detail.src);

      // ignore media without src, ex. cloned chip by move in widget-pairs
      if (!event.detail.src) return;

      // don't add the same image twice to the array, because it will be loaded only once
      if (this._mediaWaiting.includes(event.detail.src)) return;

      this._mediaWaiting.push(event.detail.src);
    }

    _onMediaLoad(event) {
      // console.log("media-load", this.id, event.detail.src, this.offsetWidth);

      // ignore the event if the media is not in the waiting list `_mediaWaiting`
      const index = this._mediaWaiting.indexOf(event.detail.src);
      if (index === -1) return;

      // only splice array when item is found
      this._mediaWaiting.splice(index, 1);

      // store lodaded image and time it took to load
      // don't store video, because the video has another events for that
      // the events are fired from ui-video-cloudflare
      if (!this.localName.includes("video")) {
        this._imagesLoaded.push({
          image: event.detail.src,
          delay: event.detail.loadTime,
        });
      }

      // if all images and videos are loaded
      if (this._mediaWaiting.length === 0) {
        // fire `widget-ready` event
        this._fireReadyEvent();

        if (this._imagesLoaded.length) {
          this.dispatchEvent(
            customEvent(
              "images-load-timing",
              {
                images: this._imagesLoaded,
                widget: {id: this.id, name: this.localName},
              },
              true,
            ),
          );
          this._imagesLoaded = [];
        }
      }
    }

    /**
     * Start the countdown timer in qset-questions element.
     * @param {number} time The countdown time in seconds.
     */
    _startCountdown(time) {
      this.dispatchEvent(customEvent("start-countdown", {time}, true));
    }

    /**
     * Countdown ended event handler. Private method of the mixin.
     */
    __onCountdownEnded() {
      if (this.countdown) {
        // go to the next question (card)
        this.dispatchEvent(customEvent("next-clicked"));
      }
    }

    /**
     * Encode slugs (ids) to Base58 in values array for using slugs in attributes of DOM.
     * @param {Array} values
     */
    _encodeValues(values) {
      return encodeValues(values);
    }

    /**
     * Decode slug (id) from Base58 to utf8 string.
     * @param {String} slug
     */
    _decodeSlug(slug) {
      return decodeSlug(slug);
    }

    /**
     * Encode slug (id) from utf8 string to Base58.
     * @param {String} slug
     */
    _encodeSlug(slug) {
      return encodeSlug(slug);
    }
  };
