import {css} from "lit";

export const WidgetPairsStyles = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  :host([has-buttons]) {
    padding-top: calc(var(--gutter) * 2);
  }

  ui-main {
    overflow: hidden;
    position: relative;
  }

  .button {
    height: 100%;
    padding: 0;
  }

  .chip-measurement {
    position: absolute;
    opacity: 0;
    display: none;
  }

  .chips-counter {
    color: var(--default-theme-text-secondary-color);
    margin-bottom: 8px;
    line-height: 16px;
    height: 16px;
  }

  .chips-pages {
    height: 96px;
    width: 100%;
    z-index: 100;
  }

  .item {
    position: relative;
    width: 50%;
    height: var(--button-height);
    box-sizing: border-box;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin-top: 8px;
    z-index: 1;
  }

  .item.ui-selected,
  .item.over {
    border: 2px solid var(--primary-color);
  }

  .item.ui-selected > .button,
  .item.over > .button {
    background-color: var(--light-primary-color);
    color: var(--text-light-primary-color);
    fill: var(--text-light-primary-color);
  }

  .item.ui-selected .item-image:before,
  .item.over .item-image:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    content: "";
    display: block;
    background-color: var(--light-primary-color);
    opacity: 0.5;
    z-index: 1;
  }

  .item[has-image].ui-selected,
  .item[has-image].over {
    padding: 0 !important;
  }

  .items3 .item {
    width: calc(100% / 3 + 1px);
  }

  .items6 .item {
    width: calc(100% / 3 + 1px);
  }

  .item:nth-of-type(1) {
    border-radius: var(--border-radius) 0 0 0;
  }

  .item:nth-of-type(1) > .button,
  .item:nth-of-type(1) ui-image:not([has-label]) {
    border-radius: var(--border-radius-inner) 0 0 0;
  }

  .items2 .item:nth-of-type(1),
  .items3 .item:nth-of-type(1) {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
  }

  .items2 .item:nth-of-type(1) > .button,
  .items2 .item:nth-of-type(1) ui-image:not([has-label]),
  .items3 .item:nth-of-type(1) > .button,
  .items3 .item:nth-of-type(1) ui-image:not([has-label]) {
    border-radius: var(--border-radius-inner) 0 0 var(--border-radius-inner);
  }

  .item:nth-of-type(2) {
    border-radius: 0 var(--border-radius) 0 0;
    margin-left: -2px;
  }

  .item:nth-of-type(2) > .button,
  .item:nth-of-type(2) ui-image:not([has-label]) {
    border-radius: 0 var(--border-radius-inner) 0 0;
  }

  .items3 .item:nth-of-type(2) {
    border-radius: 0;
  }

  .items3 .item:nth-of-type(2) > .button,
  .items3 .item:nth-of-type(2) ui-image:not([has-label]) {
    border-radius: 0;
  }

  .items6 .item:nth-of-type(2) {
    border-radius: 0;
  }

  .items6 .item:nth-of-type(2) > .button,
  .items6 .item:nth-of-type(2) ui-image:not([has-label]) {
    border-radius: 0;
  }

  .item:nth-of-type(3) {
    border-radius: 0 0 0 var(--border-radius);
    margin-top: -2px;
  }

  .item:nth-of-type(3) > .button,
  .item:nth-of-type(3) ui-image:not([has-label]) {
    border-radius: 0 0 0 var(--border-radius-inner);
  }

  .items2 .item:nth-of-type(2),
  .items3 .item:nth-of-type(3) {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    margin-top: 0;
    margin-left: -2px;
  }

  .items2 .item:nth-of-type(2) > .button,
  .items2 .item:nth-of-type(2) ui-image:not([has-label]),
  .items3 .item:nth-of-type(3) > .button,
  .items3 .item:nth-of-type(3) ui-image:not([has-label]) {
    border-radius: 0 var(--border-radius-inner) var(--border-radius-inner) 0;
  }

  .items6 .item:nth-of-type(3) {
    border-radius: 0 var(--border-radius) 0 0;
    margin-top: 0;
    margin-left: -2px;
  }

  .items6 .item:nth-of-type(3) > .button,
  .items6 .item:nth-of-type(3) ui-image:not([has-label]) {
    border-radius: 0 var(--border-radius-inner) 0 0;
  }

  .item:nth-of-type(4) {
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .item:nth-of-type(4) > .button,
  .item:nth-of-type(4) ui-image:not([has-label]) {
    border-radius: 0 0 var(--border-radius-inner) 0;
  }

  .items6 .item:nth-of-type(4) {
    border-radius: 0 0 0 var(--border-radius);
    margin-left: 0;
    margin-top: -2px;
  }

  .items6 .item:nth-of-type(4) > .button,
  .items6 .item:nth-of-type(4) ui-image:not([has-label]) {
    border-radius: 0 0 0 var(--border-radius-inner);
  }

  .item:nth-of-type(5) {
    margin-left: -2px;
    margin-top: -2px;
  }

  .items6 .item:nth-of-type(5) {
    border-radius: 0;
  }

  .items6 .item:nth-of-type(5) > .button,
  .items6 .item:nth-of-type(5) ui-image:not([has-label]) {
    border-radius: 0;
  }

  .item:nth-of-type(6) {
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .item:nth-of-type(6) > .button,
  .item:nth-of-type(6) ui-image:not([has-label]) {
    border-radius: 0 0 var(--border-radius-inner) 0;
  }

  .item[has-image] {
    padding: 1px;
  }

  .item[has-image]:nth-of-type(2) {
    padding-left: 2px;
  }

  .items3 .item[has-image]:nth-of-type(3) {
    padding-left: 2px;
  }

  .items4 .item[has-image]:nth-of-type(3) {
    padding-top: 2px;
  }

  .items6 .item[has-image]:nth-of-type(3) {
    padding-top: 1px;
    padding-left: 2px;
  }

  .item[has-image]:nth-of-type(4) {
    padding-left: 2px;
    padding-top: 2px;
  }

  .items6 .item[has-image]:nth-of-type(4) {
    padding-left: 1px;
  }

  .item[has-image]:nth-of-type(5) {
    padding-top: 2px;
    padding-left: 2px;
  }

  .item[has-image]:nth-of-type(6) {
    padding-left: 2px;
    padding-top: 2px;
  }

  .item-image {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .item-imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .button .label {
    line-height: 15px;
    padding: 0 6px;
    box-sizing: border-box;
    /* IE11 */
    width: 100%;
    text-align: center;
  }

  .item[has-icon] .button .label,
  .item[has-image] .button .label {
    padding: 4px 6px;
  }

  .button ui-svg {
    display: block;
    width: 100%;
    height: 32px;
    margin: 0;
    fill: var(--primary-text-color);
  }

  .chip {
    position: initial;
    cursor: grab;
  }

  .chip.move {
    z-index: 100;
    position: absolute;
    cursor: grabbing;
  }

  .chip.clone {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .chip.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  @keyframes slide {
    from {
      left: 70%;
    }
    to {
      left: 0;
    }
  }

  .chips-selector {
    display: none;
    text-align: center;
    max-height: 112px;
    /* need to use visible so the drag handles can be dragged outside of the box */
    overflow: visible;
    position: relative;
    flex-flow: wrap;
    justify-content: center;
  }

  .chips-selector.selected {
    display: flex;
  }

  .skeleton {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: var(--text-secondary-color);
    background-color: var(--secondary-color);
    box-sizing: border-box;
  }

  .skeleton-block {
    float: left;
    border: 1px dashed var(--divider-color);
    box-sizing: border-box;
    width: 50%;
    height: var(--button-height);
  }

  .skeleton-block:nth-of-type(1) {
    border-radius: var(--border-radius) 0 0 0;
    margin-left: 0;
  }

  .blocks2 .skeleton-block:nth-of-type(1),
  .blocks3 .skeleton-block:nth-of-type(1) {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
  }

  .skeleton-block:nth-last-of-type(1) {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  .blocks3 .skeleton-block {
    width: calc(100% / 3 + 1px);
  }

  .blocks6 .skeleton-block {
    width: calc(100% / 3 + 1px);
  }

  .skeleton-block:nth-of-type(2) {
    border-left: 1px dashed transparent;
    border-radius: 0 var(--border-radius) 0 0;
    margin-left: -2px;
  }

  .blocks3 .skeleton-block:nth-of-type(2),
  .blocks6 .skeleton-block:nth-of-type(2) {
    border-radius: 0;
  }

  .skeleton-block:nth-of-type(3) {
    border-top: 1px dashed transparent;
    border-radius: 0 0 0 var(--border-radius);
    margin-top: -2px;
  }

  .blocks2 .skeleton-block:nth-of-type(2),
  .blocks3 .skeleton-block:nth-of-type(3) {
    border-top: 1px dashed var(--divider-color);
    border-left: 1px dashed transparent;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    margin-top: 0;
    margin-left: -2px;
  }

  .blocks6 .skeleton-block:nth-of-type(3) {
    border-top: 1px dashed var(--divider-color);
    border-left: 1px dashed transparent;
    border-radius: 0 var(--border-radius) 0 0;
    margin-top: 0;
    margin-left: -2px;
  }

  .skeleton-block:nth-of-type(4) {
    border-top: 1px dashed transparent;
    border-left: 1px dashed transparent;
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .blocks6 .skeleton-block:nth-of-type(4) {
    border-left: 1px dashed var(--divider-color);
    border-radius: 0 0 0 var(--border-radius);
    margin-left: 0;
  }

  .skeleton-block:nth-of-type(5) {
    border-top: 1px dashed transparent;
    border-left: 1px dashed transparent;
    border-radius: 0;
    margin-top: -2px;
    margin-left: -2px;
  }

  .skeleton-block:nth-of-type(6) {
    border-top: 1px dashed transparent;
    border-left: 1px dashed transparent;
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .chip[is-icon] .chip-label,
  .chip[is-image] .chip-label {
    margin: 0 10px 0 -2px;
  }

  .chip ui-svg,
  .chip ui-image {
    width: 36px;
    height: 36px;
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) {
    .button .label {
      line-height: 16px;
      padding: 0 8px;
    }

    .chips-pages {
      height: 112px;
    }

    .chip[is-icon] .chip-label,
    .chip[is-image] .chip-label {
      margin: 0 12px 0 -4px;
    }

    .chip ui-svg,
    .chip ui-image {
      width: 44px;
      height: 44px;
    }
  }
`;
