import {css, html} from "lit";
import {ifDefined} from "lit/directives/if-defined.js";

import {WidgetBaseElement} from "./widget-base-element.js";
import {onIOS} from "./utils/browser.js";

import "./ui-parts/ui-container.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-main-image.js";
import "./ui-parts/ui-title.js";

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: 0;
    position: relative;
    height: 100%;
    overflow: auto;
    --ui-main-image-padding-bottom: 0;
  }

  video {
    width: 100%;
    border-radius: var(--border-radius);
  }

  ui-main {
    display: block;
  }

  ui-title {
    padding: var(--widget-gutter) var(--widget-gutter) 0 var(--widget-gutter);
    box-sizing: border-box;
  }
`;

/**
  ## Display one image

  Useful for some static messages that do not require interaction.
  Use question text to display formatted text (?).
*/
export class WidgetImage extends WidgetBaseElement {
  constructor() {
    super();

    /**
     * URL of the image to display.
     */
    this.image = "";

    /**
     *  If true, show the image with text in double size 2x.
     */
    this.imageWithText = false;

    /**
     * Video mode to display GIF converted to MP4 on backend.
     */
    this.videoMode = false;

    this.addEventListener("enter-stage", this._onEnterStage);
    this.addEventListener("exit-stage", this._onExitStage);
  }

  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      image: {
        type: String,
      },

      imageWithText: {
        type: Boolean,
      },

      videoMode: {
        type: Boolean,
      },
    };
  }

  render() {
    return html`
      <ui-container isFlex has-scroll>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
          ?hidden="${!this.question}"
        ></ui-title>

        ${this.videoMode
          ? html`
              <ui-main>
                <video
                  loop
                  muted
                  playsinline
                  @loadeddata="${this._onLoadedData}"
                >
                  <source
                    src="${ifDefined(this._computeAssetPath(this.image))}"
                    type="video/mp4"
                  />
                </video>
              </ui-main>
            `
          : html`
              <ui-main-image
                src="${this._computeAssetPath(this.image)}"
                question="${this.question}"
                isLargeSizeOnly
                ?imageWithText="${this.imageWithText}"
              ></ui-main-image>
            `}
      </ui-container>
    `;
  }

  firstUpdated() {
    super.firstUpdated();

    if (this.videoMode) {
      this._player = this.shadowRoot.querySelector("video");

      // fire init event here, so we can preload the video on second a card on background
      this.dispatchEvent(
        new CustomEvent("waiting-for-media", {
          detail: {src: this.image},
        }),
      );

      // Safari on iOS needs load video manually
      if (onIOS && this._player.canPlayType("application/vnd.apple.mpegurl")) {
        this._player.load();
      }
    }
  }

  _onEnterStage() {
    if (this.videoMode) {
      this._player.play();
    }

    // show action button
    this.answer = ["watched"];
  }

  _onExitStage() {
    if (this.videoMode) {
      this._player.pause();
    }
  }

  // for video like GIF we need to fire event when whole video is loaded
  _onLoadedData() {
    this.dispatchEvent(
      new CustomEvent("media-loaded", {
        detail: {src: this.image},
      }),
    );
  }
}

window.customElements.define("widget-image", WidgetImage);
