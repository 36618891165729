import {html} from "lit";

import {WidgetBaseElement} from "./widget-base-element.js";
import {WidgetScaleStyles} from "./styles/widget-scale-styles.js";
import {mdDefaultRender} from "./utils/markdown.js";

import "./ui-parts/ui-container.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-main-image.js";
import "./ui-parts/ui-title.js";
import "./ui-parts/ui-multi-selector.js";

/**
  ## Horizontal Scale
*/
export class WidgetScale extends WidgetBaseElement {
  constructor() {
    super();

    this.steps = 5;
    this.values = [];
    this.variant = "default";
    this._selectedItem = -1;
    this._items = [];

    /**
     * Resulting value for bottom point.
     * **Beware** set this only after `numericMax`, otherwise you'll
     * get a failed widget due to invalid range (min > max).
     */
    this._numericMin = 1;

    /**
     * Resulting value for step size.
     */
    this._numericStep = 1;
  }

  static get styles() {
    return [super.styles, WidgetScaleStyles];
  }

  static get properties() {
    return {
      answer: {
        type: String,
      },

      /**
       * Label for bottom of the range. Inline markdown formating (italic, bold).
       */
      labelMin: {
        type: String,
      },

      /**
       * Optional label for midpoint of the range.
       */
      labelMid: {
        type: String,
      },

      /**
       * Label for top of the range. Inline markdown formating (italic, bold).
       */
      labelMax: {
        type: String,
      },

      /**
       * Number of distinct values returned from the control.
       * Default value is 5. Allowed values are 5, 7, 11.
       */
      steps: {
        type: Number,
      },

      /**
       * List of values - this triggers the `list` mode,
       * displaying the ordered values in a big readout above.
       * [{id: slug, label: text to display}]
       * @type {Array<object>}
       */
      values: {
        type: Array,
      },

      /**
       * Color variant of the items.
       * Default value is 'default'. Allowed values are 'default', 'red-green'.
       */
      variant: {
        type: String,
      },

      /**
       * Additional fixed image be displayed above the slider.
       */
      image: {
        type: String,
      },

      _items: {
        type: Array,
      },

      /**
       * Variable bound to the slider value.
       */
      _selectedItem: {
        type: Number,
      },
    };
  }

  render() {
    const renderItem = (item) => html`
      <div class="item variant-${this.variant}" name="${item.id}">
        <div class="item-point"></div>
      </div>
    `;

    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main-image
          .src="${this._computeAssetPath(this.image)}"
          .question="${this.question}"
        ></ui-main-image>

        <ui-main>
          <div class="labels steps${this.steps}">
            ${this.labelMin
              ? html`<div class="left">${mdDefaultRender(this.labelMin)}</div>`
              : html`<div></div>`}
            ${this.labelMid
              ? html`<div class="center">
                  ${mdDefaultRender(this.labelMid)}
                </div>`
              : ""}
            ${this.labelMax
              ? html`<div class="right">${mdDefaultRender(this.labelMax)}</div>`
              : html`<div></div>`}
          </div>

          <ui-multi-selector
            class="items steps${this.steps}"
            id="items"
            @selection-changed="${this._onSelectionChange}"
          >
            ${this._items.map(renderItem)}
          </ui-multi-selector>
        </ui-main>
      </ui-container>
    `;
  }

  updated(changedProperties) {
    if (super.updated) super.updated(changedProperties);

    if (changedProperties.has("steps")) {
      const items = [];
      for (let i = 0; i < this.steps; i += 1) {
        items.push({id: this._numericMin + this._numericStep * i});
      }

      this._items = items;
    }
  }

  _onSelectionChange(event) {
    this._selectedItem = event.detail.selection;
    this._skipNextActionButton = true;
    this.answer = String(this._selectedItem);
  }
}

window.customElements.define("widget-scale", WidgetScale);
