import {css, html} from "lit";

import {WidgetBaseElement} from "./widget-base-element.js";

import "./ui-parts/ui-container.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-main-image.js";
import "./ui-parts/ui-title.js";
import "./ui-parts/ui-combo-box.js";
import "./ui-parts/ui-textfield.js";

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  ui-main {
    position: static;
  }

  .input {
    width: 100%;
    margin: 0 auto 8px auto;

    --md-sys-color-primary: var(--tertiary-text-color);
    --md-filled-field-container-color: var(--fill-color);
  }

  .input-container {
    width: 100%;
    overflow-y: auto;
  }
`;

/**
  ## Input freeform text

  Text input with optional type-ahead. Can generate an array of inputs.
*/
export class WidgetInput extends WidgetBaseElement {
  constructor() {
    super();
    this.image = "";
    this.arrayCount = 1;
    this.arrayGrow = false;
    this.suggestions = [];
    this.suggestionsOnly = false;
    this.dropdownChars = 2;
    this.validate = {};

    this._suggestions = [];
    this._isValidValue = false;

    this.addEventListener("enter-stage", this._onEnterStage);
  }

  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      /**
       * Image asset ID.
       */
      image: {
        type: String,
      },

      /**
       * `Array` of `String`, in the order of appearance.
       */
      answer: {
        type: Array,
      },

      /**
       * Count of displayed input fields.
       */
      arrayCount: {
        type: Number,
      },

      /**
       * Use with `arrayCount > 2`, `displayType == combo`. Initially show only two
       * input fields, as they get filled more fields appear below, up to `arrayCount`.
       */
      arrayGrow: {
        type: Boolean,
      },

      /**
       * Hint to display in the first input of an array
       * (does not behave like real value, eg disappears on focus).
       *
       * If this is an array (`["hint1", "hint2"]`), use the hints
       * one by one in array of inputs (do not recycle).
       */
      hint: {
        type: String,
      },

      /**
       * Suggestions for the combo-box drop down.
       *
       * `["Displayed text", ...]`
       */
      suggestions: {
        type: Array,
      },

      /**
       * If `true`, answers have to be from the `suggestions` list.
       */
      suggestionsOnly: {
        type: Boolean,
      },

      /**
       * User has to type at least `dropdownChars` before the dropdown
       * with suggestions pops up.
       * If value = 0, show suggestions dropdown when the widget is displayed.
       */
      dropdownChars: {
        type: Number,
      },

      /**
       * Validate options object:
       * `{"rule": "regex", message: "", _validator_args_...}`
       * example: `{"rule": "regex",
       *   "message": "Jen cisla a mezery", "pattern": "^[0-9 ]+$"}`
       */
      validate: {
        type: Object,
      },

      _suggestions: {
        type: Array,
      },
    };
  }

  render() {
    const renderInput = (index) => html`
      <ui-textfield
        class="input"
        id="input${index}"
        label="${this._getHint(index)}"
        ?hidden="${this._computeInputHidden(this.arrayGrow, index)}"
        pattern="${this._ifelseEq(
          this.validate.rule,
          "regex",
          this.validate.pattern,
          "",
        )}"
        @input="${this._onInputChange}"
        @keyup="${this._onInputKeyUp}"
        @invalid="${this._onInputInvalid}"
      ></ui-textfield>
    `;

    const renderComboBox = (index) => html`
      <ui-combo-box
        class="input"
        id="input${index}"
        .suggestions="${this._suggestions}"
        label="${this._getHint(index)}"
        .allowCustomValue="${!this.suggestionsOnly}"
        ?hidden="${this._computeInputHidden(this.arrayGrow, index)}"
        @change="${this._onComboBoxChange}"
        @keyup="${this._onInputKeyUp}"
        @input-click="${this._onComboBoxInputClick}"
        @blur="${this._onComboBoxBlur}"
      >
      </ui-combo-box>
    `;

    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main-image
          .src="${this._computeAssetPath(this.image)}"
          .question="${this.question}"
        ></ui-main-image>

        <ui-main>
          <div class="input-container scrollbar" id="inputContainer">
            ${this.suggestions.length
              ? this._computeDummyArray(this.arrayCount).map(renderComboBox)
              : this._computeDummyArray(this.arrayCount).map(renderInput)}

            <div id="inputContainerBottom"></div>
          </div>
        </ui-main>
      </ui-container>
    `;
  }

  firstUpdated(changedProperties) {
    if (super.firstUpdated) super.firstUpdated(changedProperties);

    this._suggestions = this.suggestions;
  }

  _onEnterStage() {
    const inputs = this.shadowRoot.querySelectorAll(".input");

    if (this._isTabletWide) {
      window.requestAnimationFrame(() => {
        // focus the first input
        inputs[0].focus();
      });
    }
  }

  _computeInputHidden(arrayGrow, index) {
    if (arrayGrow) {
      if (index <= 1) return false;

      return true;
    }
    return false;
  }

  _filterSuggestions(value) {
    this._suggestions = this.suggestions.filter(
      (suggestion) =>
        this._normalizeString(suggestion).indexOf(
          this._normalizeString(value.trim().toLowerCase()),
        ) !== -1,
    );
  }

  _onComboBoxBlur(event) {
    // eslint-disable-next-line no-param-reassign
    if (!this._isValidValue) event.target.value = "";
  }

  _onComboBoxInputClick(event) {
    if (this.dropdownChars === 0 || this.suggestionsOnly) {
      this._filterSuggestions(event.target.value);
      event.target.open();
    }
  }

  _onInputKeyUp(event) {
    const {target} = event;
    const {value} = target;
    const input = this.shadowRoot.getElementById(event.target.id);

    if (value === "" && this.dropdownChars !== 0) {
      // Close combo box dropdown list
      if (typeof target.close === "function") target.close();
    }

    if (event.key === "Enter") {
      if (this.arrayCount === 1 && this._objBool(this.answer)) {
        input.classList.remove("red");
        this._isValidValue = true;
        this._advanceQuestion();
      }

      if (this.arrayCount > 1) {
        // if there is no more input to jump to, confirm
        if (target.nextElementSibling.className === "input") {
          target.nextElementSibling.focus();
        } else if (this._objBool(this.answer)) {
          this._advanceQuestion();
        }
      }
    } else if (target.localName === "ui-combo-box") {
      // Show suggestions after writing into combo box
      if (value.trim() !== "" && value.length >= this.dropdownChars) {
        this._filterSuggestions(value);

        if (this._suggestions.length === 0 && !this.suggestionsOnly) {
          event.target.close();
        } else {
          event.target.open();
        }

        if (this._suggestions.length === 0 && this.suggestionsOnly) {
          this._suggestions = this.suggestions;
          input.classList.add("red");
          this._isValidValue = false;
          this.answer = [];
        } else {
          input.classList.remove("red");
          this._isValidValue = true;
        }
      } else {
        event.target.close();
        input.classList.remove("red");
        this._isValidValue = false;
        this.answer = [];
      }
    }

    if (this.arrayGrow) this._showNextInput(target);

    if (this.arrayGrow && event.key === "Enter") {
      // scroll to the container bottom
      const inputContainer = this.shadowRoot.querySelector("#inputContainer");
      inputContainer.scrollTo({
        top: inputContainer.scrollHeight - inputContainer.offsetHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  _saveComboBoxValues() {
    const array = [];
    const inputs = this.shadowRoot.querySelectorAll("ui-combo-box");

    for (let i = 0; i < inputs.length; i += 1) {
      const value = inputs[i].value.trim();
      array.push(value);
    }

    if (array.filter((item) => item !== "").length === 0) this.answer = [];
    else this.answer = array;
  }

  _showNextInput(activeInput) {
    const {id} = activeInput;
    const index = Number(id.replace("input", ""));
    const input = this.shadowRoot.querySelector(`#input${index + 1}`);

    if (input) input.hidden = false;
  }

  _onComboBoxChange(event) {
    // it we're not on stage, answer is not supposed to change
    if (!this._isOnStage) {
      this.answer = [];
      return;
    }

    this._saveComboBoxValues();

    if (this.arrayGrow) this._showNextInput(event.target);
  }

  _onInputChange() {
    // it we're not on stage, answer is not supposed to change
    if (!this._isOnStage) {
      this.answer = [];
      return;
    }

    const array = [];
    let areInputsValid = true;
    const inputs = this.shadowRoot.querySelectorAll(".input");

    for (let i = 0; i < inputs.length; i += 1) {
      areInputsValid *= inputs[i].reportValidity();
      inputs[i].setCustomValidity("");
      const value = inputs[i].value.trim();
      array.push(value);
    }

    if (array.filter((item) => item !== "").length === 0 || !areInputsValid) {
      this.answer = [];
    } else {
      this.answer = array;
    }
  }

  _onInputInvalid(event) {
    event.target.setCustomValidity(this.validate.message);
  }

  /**
   * When using array of inputs, hits are a bit special:
   * - if `hint` is `String`, display it only in the first input
   * - if `hint` is `Array`, set hints by picking items from the array
   */
  _getHint(index) {
    // single hint
    if (this.hint instanceof String || typeof this.hint === "string") {
      if (index === 0) return this.hint;
      return "";
    }

    // more hints
    if (this.hint instanceof Array) {
      if (index < this.hint.length) return this.hint[index];
    }

    return "";
  }
}

window.customElements.define("widget-input", WidgetInput);
