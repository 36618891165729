import {html} from "lit";
import {ifDefined} from "lit/directives/if-defined.js";

import {WidgetBaseElement} from "./widget-base-element.js";
import {WidgetMultiChoiceMixin} from "./mixins/widget-multi-choice-mixin.js";
import {RandomizeMixin} from "./mixins/randomize-mixin.js";
import {WidgetListStyles} from "./styles/widget-list-styles.js";
import {mdDefaultRender} from "./utils/markdown.js";
import {
  checkBoxOutlineBlankIcon,
  checkBoxIcon,
  radioButtonUncheckedIcon,
  radioButtonCheckedIcon,
} from "./qset-icons.js";

import "./ui-parts/ui-button.js";
import "./ui-parts/ui-container.js";
import "./ui-parts/ui-image.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-main-image.js";
import "./ui-parts/ui-svg.js";
import "./ui-parts/ui-title.js";
import "./ui-parts/ui-multi-selector.js";

/**
  ## Choose from options, 'list' appearance

  Options (text only) are displayed in a vertical column. Useful for many longer strings,
  use with care!
*/
export class WidgetList extends WidgetMultiChoiceMixin(
  RandomizeMixin(WidgetBaseElement),
) {
  constructor() {
    super();
    /**
     * Instead of using `values` to populate the items,
     * load answers of a previous question. `sourceData` is
     * the id of that question, and the expected format is
     * array of labels (ids are automatically generated as consecutive numbers)
     * @type {String}
     */
    this.sourceData = undefined;
    this.values = [];
  }

  static get styles() {
    return [super.styles, WidgetListStyles];
  }

  static get properties() {
    return {
      /**
       * URL path to image.
       */
      image: {type: String},

      /**
       * Array of options to choose from,
       * `{id: slug,
       * label: text to display, inline markdown formating (italic, bold)
       * image: linked image,
       * icon: SVG icon}`.
       * @type {Array<object>}
       */
      values: {
        type: Array,
      },
    };
  }

  render() {
    const renderItemSymbols = () => html`
      ${this.multi
        ? html`
            <div class="item-symbol check unchecked">
              ${checkBoxOutlineBlankIcon}
            </div>

            <div class="item-symbol check checked">${checkBoxIcon}</div>
          `
        : html`
            <div class="item-symbol radio unchecked">
              ${radioButtonUncheckedIcon}
            </div>

            <div class="item-symbol radio checked">
              ${radioButtonCheckedIcon}
            </div>
          `}
    `;

    const renderItem = (item) => html`
      <div
        name="${item.id}"
        ?has-icon="${this._isValue(item.icon)}"
        ?has-image="${this._isValue(item.image)}"
        ?has-label="${this._isValue(item.label)}"
        class="item"
      >
        <ui-button class="button" tabindex="-1">
          <div class="button-content">
            ${!(item.image || item.icon) ? renderItemSymbols() : ""}
            ${item.image
              ? html`
                  <div class="item-imageContainer">
                    <ui-image
                      class="item-image"
                      .src="${this._computeItemImagePath(item.image)}"
                      srcset="${ifDefined(
                        this._computeImageSrcSet(
                          this._computeItemImagePath.bind(this),
                          item.image,
                        ),
                      )}"
                    ></ui-image>
                  </div>
                `
              : ""}
            ${item.icon
              ? html`
                  <div class="item-iconContainer">
                    <ui-svg
                      class="item-icon"
                      .src="${this._computeAssetPath(item.icon)}"
                    ></ui-svg>
                  </div>
                `
              : ""}
            ${item.label
              ? html`<div class="label">${mdDefaultRender(item.label)}</div>`
              : ""}
            ${item.image || item.icon ? renderItemSymbols() : ""}
          </div>
        </ui-button>
      </div>
    `;

    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main-image
          .src="${this._computeAssetPath(this.image)}"
          .question="${this.question}"
        ></ui-main-image>

        <ui-main>
          <ui-multi-selector
            class="items items${this.values.length}"
            id="items"
            ?multi="${this.multi}"
            @selection-changed="${(e) => {
              this._selection = e.detail.selection;
            }}"
          >
            ${this._encodeValues(this.values).map(renderItem)}
          </ui-multi-selector>

          <div class="skeleton blocks${this.values.length}">
            ${this.values.map(() => html`<div class="skeleton-block"></div>`)}
          </div>
        </ui-main>
      </ui-container>
    `;
  }

  // TODO
  // listeners: {
  //     'enter-stage': '_onEnterStage'
  // },

  // _onEnterStage: function(event) {
  //     // Get values from other widget
  //     if (this.sourceData !== undefined) {
  //         var data = this._getValue('self.' + this.sourceData);
  //         var values = [];

  //         for (var i = 0; i < data.length; i++) {
  //             values.push({id: i, label: data[i]});
  //         }
  //         this.values = values;
  //     }
  // },

  _computeItemImagePath(path) {
    if (!path) return path;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (path.indexOf("/") !== -1) return this._computeAssetPath(path);

    // imgix note
    // all the items occupy 32px of width and 32px of height

    const itemSize = 32;

    return `${this._computeAssetPath(path)}?auto=compress,format&w=${itemSize}`;
  }
}

window.customElements.define("widget-list", WidgetList);
