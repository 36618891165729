/* eslint-disable camelcase */
/* eslint-disable prefer-template */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unreachable */
/* eslint-disable vars-on-top */
/* eslint-disable object-shorthand */
/* eslint-disable no-plusplus */
/* eslint-disable no-var */
/* eslint-disable func-names */

export const now =
  window.performance && window.performance.now
    ? window.performance.now.bind(window.performance)
    : Date.now;

// return a getter function, which triggers initialization
// of nForward items in advance
export const forwardCache = (src, operation, nForward) => {
  var source = src;
  var cache = new Array(src.length);

  function ensure(index) {
    if (cache[index] === undefined) cache[index] = operation(source[index]);
  }

  return function (index) {
    for (var i = 0; i < nForward; i++) {
      // this handles also out-of-range index
      if (index + i >= source.length) break;
      ensure(index + i);
    }
    return cache[index];
  };
};

export const timeoutPromise = (timeout) =>
  new Promise((resolve) => {
    if (timeout) setTimeout(resolve, timeout);
    else resolve();
  });

export const rafPromise = () => new Promise(requestAnimationFrame);

export const domChildPromise = (parent, timeout) => {
  var debugMode = !!window.__debugMO;
  var tStart = performance.now();

  return new Promise((_resolve) => {
    var observer;
    var timer;
    var resolved = false;
    function resolve(byMutation) {
      if (debugMode) {
        // eslint-disable-next-line no-console
        console.log(
          Math.round(tStart),
          "MO resolve",
          parent,
          byMutation ? "by mutation" : "by timeout",
          resolved ? "(alreadyResolved)" : "",
        );
      }

      if (resolved) return;
      // clean up
      resolved = true;
      observer.disconnect();
      if (timer) clearTimeout(timer);

      // call the real resolve
      _resolve();
    }

    observer = new MutationObserver((mlist) => {
      var addsChild = function (mr) {
        return mr.type === "childList" && mr.addedNodes.length > 0;
      };

      // eslint-disable-next-line no-console
      if (debugMode) console.log(Math.round(tStart), "MO observed", mlist);

      if (mlist.some(addsChild)) resolve(true);
    });

    if (timeout) timer = setTimeout(resolve);

    // eslint-disable-next-line no-console
    if (debugMode) console.log(Math.round(tStart), "MO added", parent);

    observer.observe(parent, {childList: true});
  });
};

// thenable transition
// `el` is DOM element
// `classes` is `{add, remove, toggle}` dict, where each keyword is
//    applied to element's `classList`, either a single string, or Array
// `transitionStr` is valid CSS transition definition (optional, if not set,
//    the classes are set and the Promise resolves instantly)
// `timeout` is a safety timeout, which comes handy when not sure if all the
//    transitions actually change any value (which would make the Promise never
//    resolve.
export const transition = (function () {
  // basic Map (delete, has, set, size) is ie11 compatible
  // FF13, Safari 8 .. let's try to keep it here
  var runningTransitions = new Map();

  return function (el, classes, transitionStr, timeout) {
    var tStart = performance.now();

    // use window global to debug transitions
    var debugMode = !!window.__debugTransition;

    // eslint-disable-next-line no-console
    if (debugMode) console.log(Math.round(tStart), "trans-start", classes);

    var ops = ["add", "remove", "toggle"];

    // set up transition
    transitionStr = transitionStr || "";
    el.style.transition = transitionStr;

    // handle invalid transition
    if (el.style.transition === "" && transitionStr) {
      return Promise.reject("invalid transition: " + transitionStr);
    }

    // if `a` is a single value, return it wrapped in array
    function getArray(a) {
      return Array.isArray(a) ? a : [a];
    }

    // get rid of all cubic-bezier(a, b..) and then count comma separated
    // transitions
    var nTransitions = el.style.transition
      .replace(/\([^)]+\)/g, "")
      .split(",").length;

    // check and register in 'global' map
    if (runningTransitions.has(el)) {
      // eslint-disable-next-line no-console
      console.error("transition: element still in transition", el.id);
    }
    runningTransitions.set(el, 1);

    return new Promise((resolve) => {
      var alreadyResolved = false;
      function logResolve(src) {
        if (!alreadyResolved) {
          resolve(el);

          // remove from the global map
          runningTransitions.delete(el);
        }

        if (debugMode) {
          // eslint-disable-next-line no-console
          console.log(
            Math.round(tStart),
            Math.round(performance.now() - tStart),
            "trans resolve",
            src,
            alreadyResolved ? "(already resolved)" : "",
            runningTransitions.size,
            "in flight",
          );
        }

        alreadyResolved = true;
      }

      // set up handler before applying styles, so we don't race
      // if there is no transition, the event does not fire
      // it even does not fire when there is no change in the resulting css ;(
      if (transitionStr) {
        var transitionsDone = 0;

        // transitionend fires only for transitions that actually make some change
        // to the underlying poperty .. the rest has to be handled by timeout
        el.addEventListener("transitionend", function te_handler(e) {
          if (debugMode) {
            // debug css events
            // eslint-disable-next-line no-console
            console.log(
              Math.round(tStart),
              Math.round(performance.now() - tStart),
              "trans-end",
              classes,
              transitionsDone,
              nTransitions,
              e.target.id,
            );
          }

          // ignore events bubbling from the DOM below
          if (e.target !== el) return;

          // resolve only after all transitions for current element are done
          transitionsDone += 1;

          if (transitionsDone === nTransitions) {
            el.removeEventListener("transitionend", te_handler);
            logResolve("trans-end");
          }
        });
      }

      // set up timeout to be sure that the returned Promise resolves
      // TODO: resolve no-restricted-globals, isFinite() is not defined
      // eslint-disable-next-line no-restricted-globals
      if (isFinite(timeout) && timeout > 0) {
        setTimeout(() => {
          logResolve("timer");
        }, timeout);
      }

      // for each permitted operation, try to apply it on element's
      // class list, possibly a whole list of classes
      ops.forEach((op) => {
        if (classes[op]) {
          getArray(classes[op]).forEach((cls) => {
            // RAF resolves freezed UI on Safari
            // https://developer.mozilla.org/en-US/docs/Mozilla/Firefox_OS_for_TV/Web_animations_on_large_screen#JavaScript-based_animations
            requestAnimationFrame(() => {
              el.classList[op](cls);
            });
          });
        }
      });

      // if there was no transition, there's nothing to wait for
      if (!transitionStr) logResolve("no transition");
    });
  };
})();

// Create microtask.
// https://stackoverflow.com/questions/47694896/polymers-this-async-vs-promise-then-vs-settimeout
// https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/
// https://v8.dev/blog/fast-async
export const microtask = () => Promise.resolve();

// Create macrotask.
export const macrotask = (timeout = 0) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

// Creates a debounced function that delays invoking the provided function until at least ms milliseconds
// have elapsed since the last time it was invoked.
// https://www.30secondsofcode.org/js/s/debounce
export const debounce = (fn, ms = 0) => {
  let timeoutId;
  // eslint-disable-next-line func-names
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

// return a function, that when called the first time returns true right away,
// when called the next time, returns false until wait miliseconds pass
// since it last returned true
// https://runkit.com/josefjezek/throttle
export const throttleGate = (wait) => {
  var lastTime = 0;

  return () => {
    const currentTime = Date.now();
    if (currentTime - lastTime > wait) {
      lastTime = currentTime;
      return true;
    }
    return false;
  };
};

/**
 * Capture the value from the unupgraded instance and delete the property so
 * it does not shadow the custom element's own property setter.
 *
 * See this [Google Developers article]{@link https://developers.google.com/web/fundamentals/web-components/best-practices#lazy-properties } for more details.
 *
 * @param ele -The element.
 * @param prop - The name of the property to upgrade.
 *
 * @example
 *
 * // Upgrade the 'duration' property if it was already set.
 * window.customElements.define('my-element', class extends HTMLElement {
 *   connectedCallback() {
 *     upgradeProperty(this, 'duration');
 *   }
 *
 *   get duration() { return +this.getAttribute('duration'); }
 *   set duration(val) { this.setAttribute('duration', val); }
 * });
 *
 */
export const upgradeProperty = (element, property) => {
  // eslint-disable-next-line no-prototype-builtins
  if (element.hasOwnProperty(property)) {
    // if (Object.prototype.hasOwnProperty.call(element, property)) {
    const value = element[property];
    // eslint-disable-next-line no-param-reassign
    delete element[property];
    // eslint-disable-next-line no-param-reassign
    element[property] = value;
  }
};

// Get the value of a CSS custom property at runtime.
// This is handled slightly differently depending on whether the shady CSS polyfill is loaded.
// https://polymer-library.polymer-project.org/3.0/docs/devguide/custom-css-properties#style-api
export const getComputedStyleValue = (element, value) => {
  if (window.ShadyCSS) {
    return window.ShadyCSS.getComputedStyleValue(element, value);
  }

  return getComputedStyle(element).getPropertyValue(value);
};

// When the polyfill is at play, ensure that styles are updated.
export const updateShadyStyles = (element) => {
  if (window.ShadyCSS) window.ShadyCSS.styleElement(element);
};

// this.updateStyles() from Polymer 3
// https://polymer-library.polymer-project.org/3.0/docs/devguide/custom-css-properties#style-api
// https://github.com/Polymer/polymer/blob/master/lib/mixins/element-mixin.js#L785

/**
 * Get the custom event.
 * By default, a bubbling custom event fired inside shadow DOM will stop bubbling when it reaches the shadow root.
 * To make a custom event pass through shadow DOM boundaries, you must set bubbles arg to true.
 * https://lit-element.polymer-project.org/guide/events#custom-events
 * @param {string} eventName
 * @param {*} detail
 * @param {boolean} bubbles
 */
export const customEvent = (eventName, detail, bubbles = false) =>
  new CustomEvent(eventName, {detail, bubbles, composed: bubbles});

/**
 * Return asset path from path or hash.
 * @param {string} baseUrl - Base URL.
 * @param {string} path - Path or hash.
 * @returns {string} Asset path.
 */
export const getAssetPath = (baseUrl, path) => {
  if (path) {
    // if the path contains only hash, no slash means probably asset hash
    if (path.indexOf("/") === -1) {
      return `${baseUrl + path.substring(0, 2)}/${path}`;
    }

    return baseUrl + path;
  }

  return undefined;
};

/**
 * Return image srcset.
 * @param {function} getImageSrcFunction - Function to get image src.
 * @param {string} path - Path or hash.
 * @returns {string} Image srcset.
 */
export const getImageSrcSet = (getImageSrcFunction, path) => {
  if (!path) return undefined;

  // skip if the path doesn't contain image hash, ex. widgets demo
  if (path.indexOf("/") !== -1) return undefined;

  // return the path with imgix params
  // DPR = 2 has quality = 40, see https://docs.imgix.com/tutorials/responsive-images-srcset-imgix#use-variable-quality
  return `${getImageSrcFunction(path)} 1x, ${getImageSrcFunction(
    path,
  )}&dpr=2&q=40 2x`;
};
