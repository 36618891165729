import {css, html, LitElement} from "lit";

const style = css`
  :host {
    display: inline-flex;
    align-items: center;

    position: relative;
    z-index: 0;

    overflow: hidden;

    box-sizing: border-box;
    height: 32px;
    padding: 8px 0;
    margin-right: 8px;
    margin-bottom: 8px;

    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: var(--primary-text-color);
    border-radius: 16px;
    outline-width: 0;
    background-color: #e0e0e0;

    font-size: 13px;
    line-height: 16px;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
  }

  :host([focused]),
  :host([pressed]) {
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  :host(:hover) {
    background-color: #757575;
    color: #fff;
  }

  .label,
  :host ::slotted([slot="label"]:first-child) {
    margin: 0 10px;
  }

  :host ::slotted([slot="prefix"]),
  :host ::slotted([slot="suffix"]) {
    border-radius: 16px;
    box-sizing: border-box;

    width: 32px;
    height: 32px;
  }

  :host ::slotted([slot="prefix"]) {
    margin-right: -4px;
  }

  :host ::slotted([slot="suffix"]) {
    color: var(--secondary-text-color);
    cursor: pointer;
    margin-left: -10px;
    padding: 6px;
  }

  :host(:hover) ::slotted([slot="suffix"]) {
    color: #fff;
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) {
    .label,
    :host ::slotted([slot="label"]:first-child) {
      margin: 0 12px;
    }
  }
`;

/**
  `<ui-chip>` implements the Material Design chip. The following is the example
  of the `<ui-chip>` usage

  ```html
    <ui-chip label="text"></ui-chip>

    <ui-chip><span slot="label">text</span></ui-chip>

    <ui-chip label="text">
      <img slot="prefix" src="">
      <iron-icon slot="suffix" icon="cancel"></iron-icon>
    </ui-chip>
  ```
 */
class UIChip extends LitElement {
  static get styles() {
    return style;
  }

  static get properties() {
    return {
      /**
       * The text to display in the chip.
       */
      label: {
        type: String,
      },
    };
  }

  render() {
    return html`
      <slot name="prefix"></slot>

      <slot name="label"><span class="label">${this.label}</span></slot>

      <slot name="suffix"></slot>
    `;
  }
}

window.customElements.define("ui-chip", UIChip);
