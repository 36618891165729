import {css, html, LitElement} from "lit";

export const StageSimpleCardStyles = css`
  :host {
    /* TODO: replace flex with block */
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    /* the opacity is set by the parent */
    opacity: var(--_card-opacity);

    box-sizing: border-box;
    width: 100%;
    max-width: var(--card-width);
    height: var(--card-height);
    border: var(--card-border-width) solid #eee;
    border-radius: var(--widget-border-radius);
    background-color: var(--tertiary-background-color);
    line-height: var(--line-height);

    position: relative;
    margin: 0 auto;
    /* transition: all linear 0.1s; */
    will-change: transform, opacity;
  }

  :host(.shadow) {
    box-shadow: 8px 8px 32px 0px rgba(0, 0, 0, 0.15);
  }

  /* secondary card, card2 and card3 */
  :host(.secondary) {
    position: absolute;

    /* center the card */
    left: 50%;
    transform: translateX(-50%);

    pointer-events: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
  }

  /*TODO: rename move to on-left, out to on-right*/
  :host(.out-left) {
    transform: translateX(-120%) translateY(30px) translateZ(0px) rotate(-15deg);
    opacity: 0;
  }

  :host(.out-right) {
    transform: translateX(120%) translateY(-30px) translateZ(0px) rotate(15deg);
    opacity: 0;
  }

  :host(.stack-bottom) {
    box-shadow: none;
    background-color: transparent;
    border: none;
    z-index: -1;
  }

  ::slotted(.hidden) {
    display: none;
  }

  @media screen and (min-width: 600px) {
    :host {
      margin: 0 auto 8px auto;
      width: var(--card-width);
      height: var(--card-height);
    }
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) and (min-height: 480px) {
    :host {
      box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.15);
    }

    :host(.-outro) {
      margin: 64px 0 0 0;
    }
  }

  @media screen and (min-width: 600px) and (min-height: 760px) {
    :host {
      margin: 0 auto 32px auto;
    }
  }

  /* Desktop 720p */
  @media screen and (min-width: 600px) and (max-height: 760px) {
    :host {
      margin-bottom: 1px;
      box-shadow:
        0 3px 5px -2px hsl(220 40% 2% / calc(10% + 3%)),
        0 7px 14px -5px hsl(220 40% 2% / calc(10% + 5%));
    }
  }

  /* iPhone 4, SE */
  @media screen and (max-width: 400px) and (max-height: 500px) {
    :host {
      height: 448px;
    }
  }
`;

class StageSimpleCard extends LitElement {
  static get styles() {
    return StageSimpleCardStyles;
  }

  render() {
    return html`<slot></slot>`;
  }
}

window.customElements.define("stage-simple-card", StageSimpleCard);
