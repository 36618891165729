import {css} from "lit";

export const WidgetListStyles = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  ui-main {
    flex-direction: row;
    align-items: normal;
    position: relative;
  }

  .button {
    height: 52px;
    padding: 0;
  }

  .button-content {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .item.ui-selected .button-content {
    padding: 0 8px;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    margin-top: -2px;
    height: 52px;
    font-weight: 400;
    color: var(--primary-text-color);
  }

  .item:nth-of-type(1),
  .item:nth-of-type(1) .button {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  .item:nth-last-of-type(1),
  .item:nth-last-of-type(1) .button {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .item.ui-selected {
    border: 2px solid var(--primary-color);
    background-color: var(--light-primary-color);
    color: var(--text-light-primary-color);
  }

  .items {
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    padding-top: 2px;
    box-sizing: border-box;
  }

  .items1 .item {
    border-radius: var(--border-radius);
  }

  .item-symbol {
    width: 24px;
    height: 24px;
    fill: #aaa;
    margin-inline-end: 14px;
  }

  .item[has-icon] .item-symbol,
  .item[has-image] .item-symbol {
    margin-right: 0;
    margin-left: auto;
  }

  .item-symbol.checked {
    display: none;
    fill: var(--accent-color);
  }

  .item.ui-selected .item-symbol.checked {
    display: inline-block;
  }

  .item.ui-selected .item-symbol.unchecked {
    display: none;
  }

  ui-svg {
    fill: var(--primary-text-color);
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }

  .item.ui-selected ui-svg {
    fill: var(--accent-color);
  }

  .item-imageContainer,
  .item-iconContainer {
    width: 32px;
    height: 32px;
    margin-right: 14px;
  }

  .label {
    text-align: left;
    line-height: 16px;
  }

  ui-image {
    width: 100%;
    height: 100%;
  }

  .skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: var(--text-secondary-color);
    background-color: var(--secondary-color);
    padding-top: 2px;
    box-sizing: border-box;
  }

  .skeleton-block {
    border: 1px dashed var(--divider-color);
    border-top: 0;
    box-sizing: border-box;
    margin-top: -2px;
    height: 52px;
  }

  .skeleton-block:nth-of-type(1) {
    border-top: 1px dashed var(--divider-color);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  .skeleton-block:nth-last-of-type(1) {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  .blocks1 .skeleton-block {
    border-radius: var(--border-radius);
  }
`;
