/* eslint-disable prefer-destructuring */
import {css, html, LitElement} from "lit";

import {
  customEvent,
  transition,
  timeoutPromise,
  rafPromise,
} from "../utils/utils.js";

const styles = css`
  :host {
    display: block;
    position: relative;
    overflow: hidden;

    --_card-opacity: 0;
  }
`;

class StageCardStack extends LitElement {
  constructor() {
    super();

    this.animationDisabled = false;
    this._debugMode = !!window.__debugStage;
    this._log = (text) => {
      if (this._debugMode) {
        // eslint-disable-next-line no-console
        console.log(`%c${text}`, "color: orange");
      }
    };
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      animationDisabled: {
        type: Boolean,
      },

      questions: {
        type: Array,
      },
    };
  }

  render() {
    return html`
      <slot name="card3"></slot>
      <slot name="card2"></slot>
      <slot name="card1"></slot>
      <slot name="globalSpinner"></slot>
    `;
  }

  firstUpdated() {
    this._card1 = this._getSlottedNode("card1");
    this._card2 = this._getSlottedNode("card2");
    this._card3 = this._getSlottedNode("card3");
    this._globalSpinner = this._getSlottedNode("globalSpinner");
  }

  /**
   * Get slotted node by name.
   * @param {string} name - name of the slot
   * @returns {Element} slot content
   */
  _getSlottedNode(name) {
    return this.shadowRoot
      .querySelector(`slot[name=${name}]`)
      .assignedNodes({flatten: true})[0];
  }

  /**
   * Save previous widget for back navigation and empty the top card.
   * @returns promise
   */
  _cleanCard1() {
    let savePreviousWidget = false;
    const prevWidget = this._card1.firstElementChild;

    if (prevWidget) {
      // save previous widget for back navigation
      // if the current widget is a button, emotion, grid, chips, input, input-chips, list, scale, or slider
      // these widgets allow users to change their answers
      if (
        [
          "buttons",
          "emotion",
          "grid",
          "chips",
          "input",
          "input-chips",
          "list",
          "scale",
          "slider",
        ].includes(prevWidget.type)
      ) {
        savePreviousWidget = true;
      }

      if (savePreviousWidget) {
        // remove older previous widget if it exists
        if (this._previousWidget) this._previousWidget.remove();

        this._previousWidget = prevWidget;
        prevWidget.setAttribute("hidden", "");

        // move previous widget to shadowRoot to keep the state of the widget
        this.shadowRoot.appendChild(prevWidget);
      } else {
        prevWidget.remove();
        if (this._previousWidget) {
          this._previousWidget.remove();
          this._previousWidget = undefined;
        }
      }
    }

    // create promise
    return timeoutPromise();
  }

  /**
   * Replace the first (supposedly only) child of `conatiner` with `widget`.
   * `container` is the first child of `card` arg.
   * `widget` can be `undefined`, which just removes the content.
   */
  _replaceContent(card, widget) {
    const prevWidget = card.firstElementChild;

    if (prevWidget) {
      prevWidget.remove();
    }

    // insert the widget or move the widget from another card to the card
    if (widget !== undefined) card.appendChild(widget);

    // create promise
    return timeoutPromise();
  }

  /**
   * Put a widget to the 'stage' - the top card.
   * returns a promise that resolves when things can be expected to be ready.
   */
  _putToStage(widget, restorePreviousWidget = false) {
    // we need know the current widget before updateFirst() of the widget
    this._currentWidget = widget;

    if (restorePreviousWidget && this._previousWidget) {
      this._currentWidget = this._previousWidget;
      this._previousWidget = undefined;
      this._currentWidget.removeAttribute("hidden");
    }

    this.dispatchEvent(
      customEvent("current-widget-changed", this._currentWidget),
    );

    return this._replaceContent(this._card1, this._currentWidget).then(() => {
      this.dispatchEvent(new Event("current-widget-entered"));

      // Send event to the entering widget.
      this._currentWidget.dispatchEvent(new Event("enter-stage"));
    });
  }

  /**
   * Put a widget to the 'background' - the second or third card.
   * returns a promise that resolves when things can be expected to be ready.
   */
  _putToBackground(widget, card = this._card2) {
    if (widget === undefined) {
      return Promise.resolve();
    }

    // if the widget is already in the background, do nothing
    // eliminate the need to wait for the widget to be ready and to be put to the stage again
    if (widget.id === card.firstElementChild?.id) {
      return Promise.resolve();
    }

    return this._replaceContent(card, widget);
  }

  _notifyCurrentWidgets(widgets, eventName) {
    return Promise.all(
      widgets.map((widget) => {
        if (widget) {
          widget.dispatchEvent(new Event(eventName));
        }
      }),
    );
  }

  /**
   * Fly in three cards to form a stack on the screen.
   */
  animateNewStack(questionIndex) {
    let currentWidgets = [];
    const newWidget = this._widgetCache(questionIndex);
    const newQuestion = this.questions[questionIndex];
    const isTabletWide = window.matchMedia(
      "screen and (min-width:600px) and (min-height:480px)",
    ).matches;

    if (newWidget === undefined) {
      // eslint-disable-next-line no-console
      console.error("undefined widget for index", questionIndex);
      return Promise.resolve();
    }

    // safely check if question at `index` is a normal card
    // (any question not of type `outro`)
    // we're not animating (flying) outros, they 'lay below' the stack
    const isFlyingCard = (index) => {
      if (index >= this.questions.length) return false;

      const question = this.questions[index];
      return question && question.type !== "outro";
    };

    // if animaiton is disabled, just swap the widgets in the top card
    // (also, if the newQuestion is directly outro, there's no animation..)
    if (this.animationDisabled || !isFlyingCard(questionIndex)) {
      this._styleCard(this._card1, newQuestion);
      return this._putToStage(newWidget);
    }

    const curve = "cubic-bezier(0.00, 0.00, 0.200, 1.00)";

    const animateCard = (el, delay, fadeDuration = 20, duration = 160) =>
      transition(
        el,
        {remove: "out-right"},
        `transform ${duration}ms ${curve} ${delay}ms,` +
          `opacity ${fadeDuration}ms linear ${delay}ms,` +
          `box-shadow ${duration}ms ${curve} ${delay}ms`,
        2 * duration,
      );

    // our best guess at what question will follow is the one just following the
    // current one
    const nextIndex = questionIndex + 1;

    // count flying cards, here we're sure we got at least one
    let nFlying = 1;
    nFlying += isFlyingCard(nextIndex);
    nFlying += isFlyingCard(nextIndex + 1);

    // wait a little so the animation does not feel rushed
    return (
      timeoutPromise(300)
        // wait for an animation frame
        .then(() => rafPromise())
        // move the cards out, with no transition
        // (empty arg removes transition if there was any left on the element)
        // to be sure, remove 'stack-bottom' which is used to display the outros
        .then(() =>
          Promise.all([
            transition(this._card1, {
              add: "out-right",
              remove: "stack-bottom",
            }),
            transition(this._card2, {add: "out-right"}),
            transition(this._card3, {add: "out-right"}),
          ]),
        )
        // wait for another animation frame
        .then(() => rafPromise())
        // as soon as the cards are gone from screen, fill them with new widgets
        // (just the first two)
        .then(() => {
          const updates = [this._putToStage(newWidget)];
          const nextWidget = this._widgetCache(nextIndex);
          const nextNextWidget = this._widgetCache(nextIndex + 1);
          currentWidgets = [newWidget, nextWidget, nextNextWidget];

          if (nFlying > 1) {
            updates.push(this._putToBackground(nextWidget, this._card2));
          }

          if (nFlying > 2) {
            updates.push(this._putToBackground(nextNextWidget, this._card3));
          }

          this._showGlobalSpinner(this._currentWidget);

          if (this._debugMode) {
            performance.mark("waitingForFirstWidget-start");
          }

          return Promise.all(updates);
        })
        // wait for another animation frame
        .then(() => rafPromise())
        // notify the current widgets that the stage is ready
        // stage cards animation is finished
        .then(() => this._notifyCurrentWidgets(currentWidgets, "stage-ready"))
        // wait for current widget to be ready
        .then(() => this._waitForCurrentWidgetReady())
        // wait for another animation frame
        .then(() => rafPromise())
        .then(() => this._afterWidgetReady())
        // fly in the cards, take care to fly the correct amount
        // as flying in 3 and finishing after one feels weird...
        .then(() => {
          // first card to fly gets delay 0, the rest get cardDelay
          const cardDelay = 80;

          // we don't need to display content of first and second flying card on phone wide
          // card 1, 2, and 3 are flying at once
          let delays = [0, 0, 0];

          // on tablet wide we have more space, so we can display all flying cards
          if (isTabletWide) delays = [0, cardDelay, cardDelay * 2];

          const animations = [];

          // 3 cards flying at once
          if (nFlying > 2) {
            animations.push(animateCard(this._card3, delays.shift()));
          }

          // 2 or 3 cards flying at once
          if (nFlying > 1) {
            animations.push(animateCard(this._card2, delays.shift()));
          }

          animations.push(animateCard(this._card1, delays.shift()));

          // wait for all animations to finish
          return Promise.all(animations);
        })
        .then(() => this._onNewStackAnimationEnd())
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
    );
  }

  /**
   * Show three cards on the screen without any animation.
   * @param {number} questionIndex
   * @returns {Promise}
   */
  showNewStack(questionIndex) {
    let currentWidgets = [];
    const newWidget = this._widgetCache(questionIndex);

    if (newWidget === undefined) {
      // eslint-disable-next-line no-console
      console.error("undefined widget for index", questionIndex);
      return Promise.resolve();
    }

    // our best guess at what question will follow is the one just following the
    // current one
    const nextIndex = questionIndex + 1;

    // wait a little so the animation does not feel rushed
    return (
      timeoutPromise(300)
        // wait for an animation frame
        .then(() => rafPromise())
        // don't move the cards, with no transition
        .then(() =>
          Promise.all([
            transition(this._card1, {remove: "out-right"}),
            transition(this._card2, {remove: "out-right"}),
            transition(this._card3, {remove: "out-right"}),
          ]),
        )
        // as soon as the cards are on screen, fill them with new widgets
        .then(() => {
          const updates = [this._putToStage(newWidget)];
          const nextWidget = this._widgetCache(nextIndex);
          const nextNextWidget = this._widgetCache(nextIndex + 1);
          currentWidgets = [newWidget, nextWidget, nextNextWidget];

          updates.push(this._putToBackground(nextWidget, this._card2));
          updates.push(this._putToBackground(nextNextWidget, this._card3));

          this._showGlobalSpinner(this._currentWidget);

          if (this._debugMode) {
            performance.mark("waitingForFirstWidget-start");
          }

          return Promise.all(updates);
        })
        // notify the current widgets that the stage is ready
        // stage cards animation is finished
        .then(() => this._notifyCurrentWidgets(currentWidgets, "stage-ready"))
        // wait for current widget to be ready
        .then(() => this._waitForCurrentWidgetReady())
        // wait for another animation frame
        .then(() => rafPromise())
        .then(() => this._afterWidgetReady())
        .then(() => this._onNewStackAnimationEnd())
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
    );
  }

  _onNewStackAnimationEnd() {
    this.dispatchEvent(customEvent("new-stack-animation-ended"));
  }

  /**
   * set up the card to display outro at "stack bottom",
   * without shadow and background
   * otherwise an simulate a card in a stack
   */
  _styleCard(card, question) {
    if (question.type === "outro") transition(card, {add: "stack-bottom"});
    else transition(card, {remove: "stack-bottom"});
  }

  /**
   * "Remove" the card from top of stack.
   */
  animateFlickLeft(questionIndex) {
    const newWidget = this._widgetCache(questionIndex);
    const newQuestion = this.questions[questionIndex];
    if (newWidget === undefined) {
      // eslint-disable-next-line no-console
      console.error("undefined widget for index", questionIndex);
      return Promise.resolve();
    }

    // our best guess at what question will follow is the one just following the
    // current one
    const nextIndex = questionIndex + 1;

    // if animaiton is disabled, just swap the widgets in the top card
    if (this.animationDisabled) {
      this._styleCard(this._card1, newQuestion);
      return this._putToStage(newWidget);
    }

    const duration = 160;
    const fadeDuration = 20;
    const curve = "cubic-bezier(0.400, 0.00, 1.00, 1.00)";

    const animateCard = (element) =>
      transition(
        element,
        {add: "out-left"},
        `transform ${duration}ms ${curve}, ` +
          `opacity ${fadeDuration}ms linear ${duration - fadeDuration}ms`,
        2 * duration,
      );

    // wait a little before flicking
    return (
      timeoutPromise(200)
        // wait for animation frame
        .then(() => rafPromise())
        // prepare the bottom card to be revealed
        // this cannot be done in advance, as conditions can skip many cards
        .then(() => {
          // clean up the stage, if the widget is outro, fix if changed by previous outro
          if (newQuestion.type === "outro") {
            transition(this._card2, {add: "out-right"});
            transition(this._card3, {add: "out-right"});
          } else {
            transition(this._card2, {remove: "stack-bottom"});
            transition(this._card3, {remove: "out-right"});
          }
        })
        // place new widget in the second and third card
        // it's not visible, but it's needed for media loading
        // media can be loaded from same widget from previous card stack, but it's not guaranteed due to randomization or conditional logic
        .then(() => this._putToBackground(newWidget, this._card2))
        .then(() =>
          this._putToBackground(this._widgetCache(nextIndex), this._card3),
        )
        // wait for next widget to be ready
        .then(
          () =>
            new Promise((resolve) => {
              if (this._debugMode) {
                performance.mark("waitingForNextWidget-start");
              }

              // if next widget is ready, resolve
              if (newWidget.isReady) {
                this._hideCardSpinner();

                this._log("widget is ready, no need to wait for it");

                // resolve the promise
                resolve();
              } else {
                this._showCardSpinner(newWidget);

                // otherwise wait for next widget to be ready
                newWidget.addEventListener(
                  "widget-ready",
                  () => {
                    this._hideCardSpinner();

                    // measure the time it took to get the next widget ready
                    if (this._debugMode) {
                      performance.mark("waitingForNextWidget-end");
                      const measure = performance.measure(
                        "waitingForNextWidget",
                        "waitingForNextWidget-start",
                        "waitingForNextWidget-end",
                      );
                      this._log(
                        `next widget load duration: ${measure.duration.toFixed(
                          3,
                        )}`,
                      );
                      performance.clearMarks("waitingForNextWidget-start");
                      performance.clearMarks("waitingForNextWidget-end");
                      performance.clearMeasures("waitingForNextWidget");
                    }

                    // resolve the promise
                    resolve();
                  },
                  {once: true}, // only once, we don't need call removeEventListener
                );
              }
            }),
        )
        // wait for another animation frame
        .then(() => rafPromise())
        // animate the card flight
        .then(() =>
          // play the flick animation
          animateCard(this._card1),
        )
        // save previous widget for back navigation and empty the top card
        .then(() => this._cleanCard1())
        // put the empty card back to the top of the stack, without any animation
        .then(() => {
          this._styleCard(this._card1, newQuestion);
          return transition(this._card1, {remove: "out-left"});
        })
        // finally activate new widget
        .then(() => this._putToStage(newWidget))
        // put next widget in the dom for media loading
        .then(() =>
          this._putToBackground(this._widgetCache(nextIndex), this._card2),
        )
        .then(() => {
          const nextNextWidget = this._widgetCache(nextIndex + 1);

          if (!nextNextWidget) return Promise.resolve();

          nextNextWidget.dispatchEvent(new Event("stage-ready"));

          return this._putToBackground(nextNextWidget, this._card3);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
    );
  }

  /**
   * Put back the previous card to the top of the stack.
   */
  animateFlickBack(questionIndex) {
    // we need a fresh widget to get back to, because the old one already has
    // a 'broken' state
    const newQuestion = this.questions[questionIndex];
    const newWidget = this._initWidget(newQuestion);

    if (newWidget === undefined) {
      // eslint-disable-next-line no-console
      console.error("undefined widget for index", questionIndex);
      return Promise.resolve();
    }

    // keep the randomization not to confuse user's short term memory
    const prevWidget = this._widgetCache(questionIndex);
    newWidget.randomizeRecipe = "";
    newWidget.values = prevWidget.values;

    // if animaiton is disabled, just swap the widgets in the top card
    if (this.animationDisabled) {
      this._styleCard(this._card1, newQuestion);
      return this._putToStage(newWidget);
    }

    const duration = 240;
    const fadeDuration = 40;
    // a little tuned ease-out
    const curve = "cubic-bezier(0,0,.41,.97)";

    const animateCard = (element) =>
      transition(
        element,
        {remove: "out-left"},
        `transform ${duration}ms ${curve}, ` +
          `opacity ${fadeDuration}ms linear`,
        // add a longer timeout, as the widget is instantiated fresh and
        // the animation itself does not fit in the tight time budget
        2 * duration,
      );

    // wait a little
    return (
      timeoutPromise(200)
        // wait for animation frame
        .then(() => rafPromise())
        // at the same time switch the current widget to the bottom
        .then(() =>
          Promise.all([
            // move the current card contents to the one below
            this._putToBackground(this._currentWidget),
            // move out the top card to be animated back
            // move the top card back to top
            transition(this._card1, {
              add: "out-left",
              remove: "stack-bottom",
            }),
          ]),
        )
        // TODO: i have no idea how to wait for the card to move out reasonably
        // raf() does not help, timeout(1) works worse .. but there should be no
        // animation to wait for..?
        .then(() => timeoutPromise(100))
        // put to stage before the animation
        // hope it will be ok for the widgets to fly;)
        // (incoming animation also flies an on-stage card)
        // and restore the previous widget to the top card
        .then(() => this._putToStage(newWidget, true))
        // after content is ready, animate the top card back
        .then(() => animateCard(this._card1))
        .then(() =>
          Promise.all([
            transition(this._card2, {remove: "stack-bottom"}),
            transition(this._card3, {remove: "out-right"}),
          ]),
        )
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
    );
  }

  /**
   * Reset the cards to the initial state.
   */
  resetCards() {
    if (this.animationDisabled) {
      transition(this._card1, {remove: "stack-bottom"});
    } else {
      // Hide all the cards to the right.
      transition(this._card1, {
        add: "out-right",
        remove: "stack-bottom",
      });
      transition(this._card2, {
        add: "out-right",
        remove: "stack-bottom",
      });
      transition(this._card3, {add: "out-right"});
    }
  }

  /**
   * Function to be called when the current widget is ready.
   */
  _afterWidgetReady() {
    // hide global spinner
    this._hideGlobalSpinner();

    this.dispatchEvent(
      customEvent("current-widget-changed", this._currentWidget),
    );

    // measure the time it took to get the next widget ready
    if (this._debugMode) {
      performance.mark("waitingForFirstWidget-end");
      const measure = performance.measure(
        "waitingForFirstWidget",
        "waitingForFirstWidget-start",
        "waitingForFirstWidget-end",
      );
      this._log(`first widget load duration: ${measure.duration.toFixed(3)}`);
      performance.clearMarks("waitingForFirstWidget-start");
      performance.clearMarks("waitingForFirstWidget-end");
      performance.clearMeasures("waitingForFirstWidget");
    }
  }

  /**
   * Wait for the current widget to be ready.
   * @returns {Promise} Promise that resolves when the current widget is ready.
   */
  _waitForCurrentWidgetReady() {
    return new Promise((resolve) => {
      if (this._currentWidget.isReady) {
        this._log("widget is ready, no need to wait for it");

        // resolve the promise
        resolve();
      } else {
        this._currentWidget.addEventListener(
          "widget-ready",
          () => {
            // resolve the promise
            resolve();
          },
          {once: true}, // only once, we don't need call removeEventListener
        );
      }
    });
  }

  _getSpinnerLogEvent(name, widget) {
    return customEvent(
      "log",
      {
        ts: +new Date(),
        type: "debug",
        name,
        category: "timing",
        connection: {
          type: window.navigator.connection.type,
          effectiveType: window.navigator.connection.effectiveType,
          rtt: window.navigator.connection.rtt,
          downlink: window.navigator.connection.downlink,
        },
        widget: {id: widget.id, name: widget.localName},
      },
      true,
    );
  }

  /**
   * Show the global spinner.
   * @param {Element} widget - The widget that is currently being processed.
   */
  _showGlobalSpinner(widget) {
    // show spinner after 500ms
    this._globalSpinnerTimeoutId = setTimeout(() => {
      this._globalSpinner.visible = true;

      this._log("showing global spinner");
    }, 500);

    // log if the spinner is shown for more than 3s
    this._globalSpinnerLogTimeoutId = setTimeout(() => {
      this.dispatchEvent(
        this._getSpinnerLogEvent(
          "showing global spinner waiting for the widget longer than 3s",
          widget,
        ),
      );
    }, 3000);
  }

  _hideGlobalSpinner() {
    // kill the timeout
    if (this._globalSpinnerTimeoutId) {
      clearTimeout(this._globalSpinnerTimeoutId);
      this._globalSpinnerTimeoutId = null;
    }

    if (this._globalSpinnerLogTimeoutId) {
      clearTimeout(this._globalSpinnerLogTimeoutId);
      this._globalSpinnerLogTimeoutId = null;
    }

    if (this._globalSpinner.visible) {
      this._log("hiding global spinner");
    }

    this._globalSpinner.visible = false;

    // show the cards after the spinner is hidden
    this.style.setProperty("--_card-opacity", 1);
  }

  /**
   * Show the card spinner for the given widget.
   * @param {Element} widget - The widget for which to show the spinner.
   */
  _showCardSpinner(widget) {
    // show spinner after 500ms
    this._spinnerTimeoutId = setTimeout(() => {
      this._card1.showSpinner = true;

      this._log("showing card spinner");
    }, 500);

    // log if the spinner is shown for more than 3s
    this._spinnerLogTimeoutId = setTimeout(() => {
      this.dispatchEvent(
        this._getSpinnerLogEvent(
          "showing card spinner waiting for the widget longer than 3s",
          widget,
        ),
      );
    }, 3000);
  }

  _hideCardSpinner() {
    // kill the timeout
    if (this._spinnerTimeoutId) {
      clearTimeout(this._spinnerTimeoutId);
      this._spinnerTimeoutId = null;
    }

    if (this._spinnerLogTimeoutId) {
      clearTimeout(this._spinnerLogTimeoutId);
      this._spinnerLogTimeoutId = null;
    }

    if (this._card1.showSpinner) {
      this._log("hiding card spinner");
    }

    this._card1.showSpinner = false;
  }
}

window.customElements.define("stage-card-stack", StageCardStack);
