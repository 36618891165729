import {html} from "lit";
import {ifDefined} from "lit/directives/if-defined.js";

import {WidgetBaseElement} from "./widget-base-element.js";
import {WidgetMultiChoiceMixin} from "./mixins/widget-multi-choice-mixin.js";
import {WidgetRankChoiceMixin} from "./mixins/widget-rank-choice-mixin.js";
import {RandomizeMixin} from "./mixins/randomize-mixin.js";
import {WidgetGridStyles} from "./styles/widget-grid-styles.js";
import {mdDefaultRender} from "./utils/markdown.js";
import {checkBoxOutlineBlankIcon, checkBoxIcon} from "./qset-icons.js";

import "./ui-parts/ui-button.js";
import "./ui-parts/ui-container.js";
import "./ui-parts/ui-image.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-svg.js";
import "./ui-parts/ui-title.js";
import "./ui-parts/ui-multi-selector.js";

/**
  ## Choose from options, 'grid' appearance

  Display a grid of selectable items.
  Each can contain one image (either standard icon, or arbitrary image)
  and a label.
 */
export class WidgetGrid extends WidgetRankChoiceMixin(
  WidgetMultiChoiceMixin(RandomizeMixin(WidgetBaseElement)),
) {
  constructor() {
    super();
    this.values = [];

    /**
     *  If true, show the image with text in double size 2x.
     */
    this.imageWithText = false;
  }

  static get styles() {
    return [super.styles, WidgetGridStyles];
  }

  static get properties() {
    return {
      /**
       * Array of options to choose from,
       * `{id: slug,
       * label: text to display, inline markdown formating (italic, bold)
       * image: linked image,
       * icon: SVG icon}`.
       * @type {Array<object>}
       */
      values: {
        type: Array,
      },

      imageWithText: {
        type: Boolean,
      },
    };
  }

  render() {
    const renderItem = (item) => html`
      <div
        name="${item.id}"
        ?is-icon="${this._isValue(item.icon)}"
        ?is-image="${this._isValue(item.image)}"
        ?is-label="${this._isValue(item.label)}"
        class="item"
      >
        <ui-button class="button" tabindex="-1">
          ${item.image
            ? html`
                <div class="item-imageContainer">
                  <ui-image
                    class="item-image"
                    ?has-label="${item.label}"
                    .src="${this._computeItemImagePath(item.image)}"
                    srcset="${ifDefined(
                      this._computeImageSrcSet(
                        this._computeItemImagePath.bind(this),
                        item.image,
                        this.imageWithText,
                      ),
                    )}"
                  ></ui-image>
                </div>
              `
            : ""}
          ${item.icon
            ? html`
                <div class="item-iconContainer">
                  <ui-svg
                    class="item-icon"
                    .src="${this._computeAssetPath(item.icon)}"
                  ></ui-svg>
                </div>
              `
            : ""}
          ${item.label
            ? html`<div class="label">${mdDefaultRender(item.label)}</div>`
            : ""}
          ${this.multi
            ? html`
                <div class="check outline">${checkBoxOutlineBlankIcon}</div>

                <div class="check checked">${checkBoxIcon}</div>
              `
            : ""}
          ${this.rank ? html`<div class="badge"></div>` : ""}
        </ui-button>
      </div>
    `;

    return html`
      <ui-container autoMainSize isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main class="grid${this.values.length}">
          <ui-multi-selector
            class="items items${this.values.length}"
            id="items"
            ?multi="${this.multi}"
            ?rank="${this.rank}"
            @selection-changed="${(e) => {
              this._selection = e.detail.selection;
            }}"
          >
            ${this._encodeValues(this.values).map(renderItem)}
          </ui-multi-selector>

          <div class="skeleton blocks${this.values.length}">
            ${this.values.map(() => html`<div class="skeleton-block"></div>`)}
          </div>
        </ui-main>
      </ui-container>
    `;
  }

  _computeItemImagePath(path) {
    if (!path) return path;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (path.indexOf("/") !== -1) return this._computeAssetPath(path);

    // imgix note
    // borders are 1px, keep 1px more as padding (that's closer to the original code)
    // the widget has 4, 6 or 9 items

    // use 1 as default to avoid division by 0
    const itemsCount = this.values ? this.values.length : 1;
    let itemsCountRow = 1;
    let itemsCountColumn = 1;

    if (itemsCount === 9) {
      itemsCountRow = 3;
      itemsCountColumn = 3;
    } else if (itemsCount === 6) {
      itemsCountRow = 2;
      itemsCountColumn = 3;
    } else if (itemsCount === 4) {
      itemsCountRow = 2;
      itemsCountColumn = 2;
    }

    const bordersCountRow = itemsCountRow + 1;
    const bordersCountColumn = itemsCountColumn + 1;
    const availWidth = this.offsetWidth - 2 * bordersCountRow;
    let availHeight = this.offsetHeight - 2 * bordersCountColumn;

    if (this.question) availHeight -= 56; // one line
    if (this.details) availHeight -= 22; // one line

    const itemWidth = Math.round(availWidth / itemsCountRow);
    const itemHeight = Math.round(availHeight / itemsCountColumn);

    // imgix resizes the image to fit within the width and height boundaries without cropping or distorting the image
    // https://docs.imgix.com/apis/url/size/fit#clip
    // we take max the image size, because the image aspect ratio is 1:1
    let itemSize = Math.max(itemWidth, itemHeight);

    if (this.imageWithText) itemSize *= 2;

    // the image with text in doubled size has quality = 40, see https://docs.imgix.com/tutorials/responsive-images-srcset-imgix#use-variable-quality
    return `${this._computeAssetPath(path)}?auto=compress,format&w=${itemSize}${
      this.imageWithText ? "&q=40" : ""
    }`;
  }
}

window.customElements.define("widget-grid", WidgetGrid);
