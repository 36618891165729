import {css, html, LitElement} from "lit";
import {ifDefined} from "lit/directives/if-defined.js";

import "./ui-image.js";

const style = css`
  :host {
    display: block;
    width: 100%;
  }

  .container {
    box-sizing: border-box;
    padding-bottom: var(--ui-main-image-padding-bottom, var(--widget-gutter));
    width: 100%;
  }

  .image {
    display: block;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    width: 100%;
    margin: 0 auto;
    aspect-ratio: 1 / 1;
  }

  .image.small {
    aspect-ratio: 2 / 1;
  }
`;

class UIMainImage extends LitElement {
  constructor() {
    super();

    /**
     * Question text.
     */
    this.question = "";

    /**
     * Image source.
     */
    this.src = "";

    this.isLargeSizeOnly = false;

    this.imageWithText = false;

    /**
     * If true, then a image of ui-image element has size 510x255px else the size is 510x510px.
     */
    this._isSmallImage = false;
  }

  static get styles() {
    return style;
  }

  static get properties() {
    return {
      question: {type: String},
      src: {type: String},
      isLargeSizeOnly: {type: Boolean},
      imageWithText: {type: Boolean},
      _isSmallImage: {type: Boolean},
    };
  }

  render() {
    // use small image for a question with the question text
    this._isSmallImage = Boolean(this.question) && !this.isLargeSizeOnly;
    this.hidden = !this.src;

    if (this.hidden) return "";

    return html`
      <div class="container">
        <ui-image
          class="image ${this._isSmallImage ? "small" : ""}"
          id="image"
          src="${ifDefined(
            this._computeImageSrc(this.src, this._isSmallImage),
          )}"
          srcset="${ifDefined(
            this._computeImageSrcSet(this.src, this._isSmallImage),
          )}"
        ></ui-image>
      </div>
    `;
  }

  _computeImageSrc(src, isSmallImage) {
    if (!src || src === "undefined") return undefined;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (src.indexOf("imgix") === -1) return src;

    // first key is `isSmallImage`, second `this.imageWithText`
    const sizes = {
      "00": {width: 510, height: 510},
      "01": {width: 1020, height: 1020},
      "10": {width: 510, height: 255},
      "11": {width: 1020, height: 510},
    };

    const bool01 = (val) => (val ? "1" : "0");
    const sizeKey = `${bool01(isSmallImage)}${bool01(this.imageWithText)}`;
    const {width, height} = sizes[sizeKey];

    // the image with text in doubled size has quality = 40, see https://docs.imgix.com/tutorials/responsive-images-srcset-imgix#use-variable-quality
    return `${src}?auto=compress,format&w=${width}&h=${height}${
      this.imageWithText ? "&q=40" : ""
    }`;
  }

  _computeImageSrcSet(src, isSmallImage) {
    if (!src || src === "undefined" || this.imageWithText) return undefined;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (src.indexOf("imgix") === -1) return undefined;

    const width = 510;
    let height = 510;

    if (isSmallImage) height = 255;

    const path = `${src}?auto=compress,format&w=${width}&h=${height}`;

    // return the path with imgix dpr params
    // DPR = 2 has quality = 40, see https://docs.imgix.com/tutorials/responsive-images-srcset-imgix#use-variable-quality
    return `${path} 1x, ${path}&dpr=2&q=40 2x`;
  }
}

window.customElements.define("ui-main-image", UIMainImage);
