// Browser has touch screen
export const hasTouchScreen =
  "ontouchstart" in window ||
  // eslint-disable-next-line no-undef
  (window.DocumentTouch && document instanceof DocumentTouch) ||
  !!(window.navigator.msPointerEnabled && window.MSGesture);

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== "undefined";

// Chrome 1+
export const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Chrome on iOS
export const isChromeOnIOS = /CriOS/.test(navigator.userAgent);

// Chromium / Blink engine detection
export const isChromiumBased = !!window.chrome;

// Internet Explorer 6-11
// eslint-disable-next-line spaced-comment
export const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Old MS Edge (EdgeHtml) < 75
export const isOldEdge = /Edge/.test(navigator.userAgent);

// MS Edge Chromium
export const isEdge = /Edg\//.test(navigator.userAgent);

// Opera 8.0+
export const isOpera =
  // eslint-disable-next-line no-undef
  (!!window.opr && !!opr.addons) ||
  !!window.opera ||
  /OPR/.test(navigator.userAgent);

// Safari
// https://www.whatismybrowser.com/guides/the-latest-user-agent/macos
// https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
export const isSafari =
  /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);

// Browser on Android
export const onAndroid = /Android/.test(navigator.userAgent);

// Browser on iOS
export const onIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

// Browser on ChromeOS - Chromebook
export const onChromeOS = /CrOS/.test(navigator.userAgent);

// Browser on Windows
export const onWindows = /Win/.test(navigator.userAgent);

// Browser on Apple OS - iOS, MacOS, iPadOS
// https://www.whatismybrowser.com/guides/the-latest-user-agent/macos
export const onApple = /Mac OS/.test(navigator.userAgent);
