import {css} from "lit";

export const WidgetGridStyles = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  ui-main {
    position: relative;
    aspect-ratio: 1/1.3;
  }

  ui-main.grid4 {
    aspect-ratio: 1/1;
  }

  .items {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    z-index: 1;
  }

  .title {
    flex: none;
  }

  .item {
    display: flex;
    flex-direction: row;
    float: left;

    position: relative;
    width: 50%;
    height: 50%;
    box-sizing: border-box;
  }

  .button {
    background-color: transparent;
    border-radius: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .items6 .item {
    height: calc(100% / 3);
  }

  .items9 .item {
    width: calc(100% / 3 + 1px);
    height: calc(100% / 3 + 1px);
  }

  .item:nth-of-type(1) {
    border-radius: var(--border-radius) 0 0 0;
  }

  .item:nth-of-type(1) > .button,
  .item:nth-of-type(1) ui-image,
  .item[is-image].ui-selected:nth-of-type(1) .item-image:before {
    border-radius: var(--border-radius-inner) 0 0 0;
  }

  .item:nth-of-type(2) {
    border-radius: 0 var(--border-radius) 0 0;
    margin-left: -2px;
  }

  .item:nth-of-type(2) > .button,
  .item:nth-of-type(2) ui-image,
  .item[is-image].ui-selected:nth-of-type(2) .item-image:before {
    border-radius: 0 var(--border-radius-inner) 0 0;
  }

  .items9 .item:nth-of-type(2),
  .items9 .item:nth-of-type(2) > .button,
  .items9 .item:nth-of-type(2) ui-image,
  .items9 .item[is-image].ui-selected:nth-of-type(2) .item-image:before {
    border-radius: 0;
  }

  .item:nth-of-type(3) {
    border-radius: 0 0 0 var(--border-radius);
    margin-top: -2px;
  }

  .item:nth-of-type(3) > .button,
  .item:nth-of-type(3) ui-image:not([has-label]),
  .item[is-image].ui-selected:nth-of-type(3) .item-image:before {
    border-radius: 0 0 0 var(--border-radius-inner);
  }

  .items9 .item:nth-of-type(3) {
    margin-top: 0;
    margin-left: -2px;
    border-radius: 0 var(--border-radius) 0 0;
  }

  .items9 .item:nth-of-type(3) > .button,
  .items9 .item:nth-of-type(3) ui-image:not([has-label]),
  .items9 .item[is-image].ui-selected:nth-of-type(3) .item-image:before {
    border-radius: 0 var(--border-radius-inner) 0 0;
  }

  .item:nth-of-type(4) {
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .item:nth-of-type(4) > .button,
  .item:nth-of-type(4) ui-image:not([has-label]),
  .item[is-image].ui-selected:nth-of-type(4) .item-image:before {
    border-radius: 0 0 var(--border-radius-inner) 0;
  }

  .items9 .item:nth-of-type(4) {
    border-radius: 0;
    margin-left: 0;
  }

  .items9 .item:nth-of-type(4) > .button,
  .items9 .item:nth-of-type(4) ui-image:not([has-label]),
  .items9 .item[is-image].ui-selected:nth-of-type(4) .item-image:before {
    border-radius: 0;
  }

  .items6 .item:nth-of-type(3),
  .items6 .item:nth-of-type(4),
  .items6 .item:nth-of-type(3) > .button,
  .items6 .item:nth-of-type(4) > .button,
  .items6 .item:nth-of-type(3) ui-image,
  .items6 .item:nth-of-type(4) ui-image,
  .items6 .item[is-image].ui-selected:nth-of-type(3) .item-image:before,
  .items6 .item[is-image].ui-selected:nth-of-type(4) .item-image:before {
    border-radius: 0;
  }

  .item:nth-of-type(5) {
    border-radius: 0 0 0 var(--border-radius);
    margin-top: -2px;
  }

  .item:nth-of-type(5) > .button,
  .item:nth-of-type(5) ui-image:not([has-label]),
  .item[is-image].ui-selected:nth-of-type(5) .item-image:before {
    border-radius: 0 0 0 var(--border-radius-inner);
  }

  .items9 .item:nth-of-type(5) {
    border-radius: 0;
    margin-top: -2px;
    margin-left: -2px;
  }

  .items9 .item:nth-of-type(5) > .button,
  .items9 .item:nth-of-type(5) ui-image:not([has-label]),
  .items9 .item[is-image].ui-selected:nth-of-type(5) .item-image:before {
    border-radius: 0;
  }

  .item:nth-of-type(6) {
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .item:nth-of-type(6) > .button,
  .item:nth-of-type(6) ui-image:not([has-label]),
  .item[is-image].ui-selected:nth-of-type(6) .item-image:before {
    border-radius: 0 0 var(--border-radius-inner) 0;
  }

  .items9 .item:nth-of-type(6),
  .items9 .item:nth-of-type(6) > .button,
  .items9 .item:nth-of-type(6) ui-image:not([has-label]),
  .items9 .item[is-image].ui-selected:nth-of-type(6) .item-image:before {
    border-radius: 0;
  }

  .item:nth-of-type(7) {
    border-radius: 0 0 0 var(--border-radius);
    margin-top: -2px;
  }

  .item:nth-of-type(7) > .button,
  .item:nth-of-type(7) ui-image:not([has-label]),
  .item[is-image].ui-selected:nth-of-type(7) .item-image:before {
    border-radius: 0 0 0 var(--border-radius-inner);
  }

  .item:nth-of-type(8) {
    margin-left: -2px;
    margin-top: -2px;
  }

  .item:nth-of-type(9) {
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .item:nth-of-type(9) > .button,
  .item:nth-of-type(9) ui-image:not([has-label]),
  .item[is-image].ui-selected:nth-of-type(9) .item-image:before {
    border-radius: 0 0 var(--border-radius-inner) 0;
  }

  .item {
    color: var(--primary-text-color);
  }

  .item.ui-selected {
    border: 2px solid var(--primary-color);
  }

  .item.ui-selected > .button {
    background-color: var(--light-primary-color);
    color: var(--text-light-primary-color);
    fill: var(--text-light-primary-color);
  }

  .item .label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding: 16px;
    text-align: center;
  }

  .item.ui-selected .label {
    padding: calc(16px - 2px);
  }

  .item-image {
    width: 100%;
    height: 100%;
  }

  .item-imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .item[is-label] .item-imageContainer {
    bottom: 52px;
  }

  .item[is-image] {
    padding: 1px;
  }

  .item[is-image]:nth-of-type(2) {
    padding-left: 2px;
  }

  .item[is-image]:nth-of-type(3) {
    padding-top: 2px;
  }

  .items9 .item[is-image]:nth-of-type(3) {
    padding-top: 1px;
    padding-left: 2px;
  }

  .item[is-image]:nth-of-type(4) {
    padding-left: 2px;
    padding-top: 2px;
  }

  .items9 .item[is-image]:nth-of-type(4) {
    padding-left: 1px;
  }

  .item[is-image]:nth-of-type(5) {
    padding-top: 2px;
  }

  .items9 .item[is-image]:nth-of-type(5) {
    padding-left: 2px;
  }

  .item[is-image]:nth-of-type(6) {
    padding-left: 2px;
    padding-top: 2px;
  }

  .item[is-image]:nth-of-type(7) {
    padding-top: 2px;
  }

  .item[is-image]:nth-of-type(8) {
    padding-left: 2px;
    padding-top: 2px;
  }

  .item[is-image]:nth-of-type(9) {
    padding-left: 2px;
    padding-top: 2px;
  }

  .item[is-icon] .label,
  .item[is-image] .label {
    line-height: 20px;
    padding: 0 16px;
    height: 52px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .item[is-image].ui-selected .label {
    height: 51px;
    padding: 0 calc(16px - 2px);
  }

  .item[is-icon].ui-selected .label {
    padding: 0 calc(16px - 2px);
  }

  .item[is-image].ui-selected .item-image:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -2px;
    left: 0;

    content: "";
    display: block;
    background-color: var(--accent-color);
    opacity: 0.5;
    z-index: 1;
  }

  .item[is-image].ui-selected,
  .items9 .item[is-image].ui-selected {
    padding: 0;
  }

  .item-icon {
    fill: var(--primary-text-color);
  }

  .item-iconContainer {
    margin: var(--gutter);
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    margin: 0 auto;
  }

  .items6 .item-iconContainer {
    margin: 10px auto;
  }

  /* implement checkboxes that signal multiselection
   * availability to the user
   */
  .check {
    position: absolute;
    top: 4px;
    left: 4px;
    fill: var(--primary-color);
    display: none;
    background-color: #fff;
    border-radius: 4px;
    z-index: 100;
    width: 24px;
    height: 24px;
  }

  .ui-selected .checked {
    display: block;
    top: 2px;
    left: 2px;
  }

  .item:not(.ui-selected):hover .outline {
    fill: var(--accent-color);
    display: block;
  }

  .badge {
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: var(--primary-color);
    color: var(--text-primary-color);
    height: 24px;
    width: 24px;
    font-size: 14px;
    visibility: hidden;
    border-radius: 12px;
    z-index: 100;
  }

  .ui-selected .badge {
    visibility: visible;
  }

  .skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--text-secondary-color);
    background-color: var(--secondary-color);
    box-sizing: border-box;
  }

  .skeleton-block {
    float: left;
    border: 1px dashed var(--divider-color);
    box-sizing: border-box;
    width: 50%;
    height: 50%;
  }

  .skeleton-block:nth-of-type(1) {
    border-radius: var(--border-radius) 0 0 0;
    margin-left: 0;
  }

  .skeleton-block:nth-last-of-type(1) {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  .blocks6 .skeleton-block {
    height: calc(100% / 3);
  }

  .blocks9 .skeleton-block {
    width: calc(100% / 3 + 1px);
    height: calc(100% / 3 + 1px);
  }

  .skeleton-block:nth-of-type(2) {
    border-left: 0;
    border-radius: 0 var(--border-radius) 0 0;
    margin-left: -2px;
  }

  .blocks9 .skeleton-block:nth-of-type(2) {
    border-radius: 0;
  }

  .skeleton-block:nth-of-type(3) {
    border-top: 1px dashed transparent;
    border-radius: 0 0 0 var(--border-radius);
    margin-top: -2px;
  }

  .blocks9 .skeleton-block:nth-of-type(3) {
    border-top: 1px dashed var(--divider-color);
    border-left: 1px dashed transparent;
    border-radius: 0 var(--border-radius) 0 0;
    margin-top: 0;
    margin-left: -2px;
  }

  .skeleton-block:nth-of-type(4) {
    border-top: 1px dashed transparent;
    border-left: 1px dashed transparent;
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .blocks9 .skeleton-block:nth-of-type(4) {
    border-left: 1px dashed var(--divider-color);
    border-radius: 0;
    margin-left: 0;
  }

  .blocks6 .skeleton-block:nth-of-type(3),
  .blocks6 .skeleton-block:nth-of-type(4) {
    border-radius: 0;
  }

  .skeleton-block:nth-of-type(5) {
    border-top: 1px dashed transparent;
    border-radius: 0 0 0 var(--border-radius);
    margin-top: -2px;
  }

  .blocks9 .skeleton-block:nth-of-type(5) {
    border-left: 1px dashed transparent;
    border-radius: 0;
    margin-left: -2px;
  }

  .skeleton-block:nth-of-type(6) {
    border-top: 1px dashed transparent;
    border-left: 1px dashed transparent;
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .blocks9 .skeleton-block:nth-of-type(6) {
    border-radius: 0;
  }

  .skeleton-block:nth-of-type(7) {
    border-top: 1px dashed transparent;
    border-radius: 0 0 0 var(--border-radius);
    margin-top: -2px;
  }

  .skeleton-block:nth-of-type(8) {
    border-top: 1px dashed transparent;
    border-left: 1px dashed transparent;
    border-radius: 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  .skeleton-block:nth-of-type(9) {
    border-top: 1px dashed transparent;
    border-left: 1px dashed transparent;
    border-radius: 0 0 var(--border-radius) 0;
    margin-left: -2px;
    margin-top: -2px;
  }

  @media screen and (min-width: 330px) {
    ui-main {
      aspect-ratio: 1/1.2;
    }
  }

  @media screen and (min-width: 350px) {
    ui-main {
      aspect-ratio: 1/1.1;
    }
  }

  @media screen and (min-width: 370px) {
    ui-main {
      aspect-ratio: 1/1;
    }
  }

  @media screen and (max-height: 400px) {
    .item[is-icon] .label,
    .item[is-image] .label {
      font-size: 14px;
      line-height: 14px;
    }
  }

  @media screen and (max-width: 600px) and (max-height: 500px) {
    .item-iconContainer {
      margin: 8px auto;
    }
  }

  /* screen height 720px */
  @media screen and (min-width: 600px) and (max-height: 700px) {
    /* move icon to the top of parent */
    .items6 .item-iconContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 10px auto;
    }
  }
`;
