import {html} from "lit";
import "@material/web/slider/slider";

import {WidgetBaseElement} from "./widget-base-element.js";
import {WidgetSliderStyles} from "./styles/widget-slider-styles.js";
import {mdDefaultRender} from "./utils/markdown.js";

import "./ui-parts/ui-container.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-main-image.js";
import "./ui-parts/ui-title.js";

/**
  ## Horizontal Slider

  - `range` mode interpolates in a range of numbers, bottom-[mid]-up
    of the range can have labels.
  - `list` mode displays ordered list of choices in a big readout above
*/
export class WidgetSlider extends WidgetBaseElement {
  constructor() {
    super();

    /**
     * @type {String}
     */
    this.answer = null;

    /**
     * Number of distinct values returned from the
     * control (`range` mode).
     */
    this.steps = 5;

    /**
     * Additional fixed image be displayed above the slider.
     */
    this.image = "";

    /**
     * When in `list` mode, use this as first value.
     */
    this.initialValue = -1;

    /**
     * Label for bottom of the range (`range` mode). Inline markdown formating (italic, bold).
     */
    this.labelMin = "";

    /**
     * Optional label for midpoint of the range (`range` mode).
     */
    this.labelMid = "";

    /**
     * Label for top of the range (`range` mode). Inline markdown formating (italic, bold).
     */
    this.labelMax = "";

    /**
     * Value of the slider while dragging.
     */
    this._sliderValue = 0;

    this._deselected = true;

    /**
     * One shot initializataion of default value.
     */
    this._alreadyInitialized = false;

    /**
     * Resulting value for bottom point (`range` mode).
     * **Beware** set this only after `numericMax`, otherwise you'll
     * get a failed widget due to invalid range (min > max).
     */
    this._numericMin = 1;

    /**
     * Resulting value for step size (`range` mode).
     */
    this._numericStep = 1;

    /**
     * List of values - this triggers the `list` mode,
     * displaying the ordered values in a big readout above.
     * [{id: slug, label: text to display}]
     * @type {Array<object>}
     */
    this.values = [];
  }

  static get styles() {
    return [super.styles, WidgetSliderStyles];
  }

  static get properties() {
    return {
      labelMin: {
        type: String,
      },

      labelMid: {
        type: String,
      },

      labelMax: {
        type: String,
      },

      _numericStep: {
        type: Number,
      },

      values: {
        type: Array,
      },

      image: {
        type: String,
      },

      _sliderValue: {
        type: Number,
      },

      _deselected: {
        type: Boolean,
      },
    };
  }

  render() {
    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main-image
          .src="${this._computeAssetPath(this.image)}"
          .question="${this.question}"
        ></ui-main-image>

        <ui-main>
          ${this._isRange(this.values)
            ? ""
            : html`
                <div id="readout">${this._calcReadout(this._sliderValue)}</div>
              `}

          <md-slider
            id="slider"
            min="${this._computeMin(this.values)}"
            max="${this._computeMax(this.values)}"
            step="${this._numericStep}"
            ?deselected="${this._deselected}"
            @change="${this._onSliderChange}"
            @input="${this._onSliderInput}"
            @click="${this._onSliderClick}"
            @touchend="${this._onSliderClick}"
          ></md-slider>

          <div id="labels">
            ${this.labelMin
              ? html`<div class="left">${mdDefaultRender(this.labelMin)}</div>`
              : ""}
            ${this.labelMid
              ? html`<div class="center">
                  ${mdDefaultRender(this.labelMid)}
                </div>`
              : ""}
            ${this.labelMax
              ? html`<div class="right">${mdDefaultRender(this.labelMax)}</div>`
              : ""}
          </div>
        </ui-main>
      </ui-container>
    `;
  }

  firstUpdated() {
    super.firstUpdated();

    // for multiple attach() calls do the init just once
    if (this._alreadyInitialized) return;
    this._alreadyInitialized = true;

    this._slider = this.shadowRoot.getElementById("slider");

    // use initialValue to init range mode
    if (this._isRange(this.values)) {
      if (this.initialValue === -1) {
        // put the knob to the midpoint

        this._slider.value = (this._numericMin + this._max) / 2;
      } else {
        // use the initial value
        this._slider.value = this.initialValue;
      }

      return;
    }

    // we're in list mode

    // move the slider to two thirds, if `initialValue`
    // is not set
    if (this.initialValue === -1) {
      this._slider.value = Math.floor((2 / 3) * this.values.length);
    } else {
      // use 1-based index not to confuse the sociologists:)
      this._slider.value = this.initialValue - 1;
    }

    this._sliderValue = this._slider.value;
  }

  /**
   * Compute min value for the md-slider.
   */
  _computeMin(values) {
    return this._isRange(values) ? this._numericMin : 0;
  }

  /**
   * Compute max value for the md-slider.
   */
  _computeMax(values) {
    if (this._isRange(values)) {
      this._max = this._numericMin + (this.steps - 1) * this._numericStep;
    } else {
      this._max = this.values.length - 1;
    }

    return this._max;
  }

  /**
   * Should this behve as a range slider?
   */
  _isRange(values) {
    return values === undefined || values.length === 0;
  }

  /**
   * Get string that should be displayed on the big readout.
   */
  _calcReadout(value) {
    if (this._isRange(this.values)) return value;

    const item = this.values[value];

    if (item === undefined || item.label === undefined) {
      return "[Missing label!]";
    }

    return item.label;
  }

  /**
   * Assign the correct value to answer according to current mode.
   */
  _setAnswer(value) {
    if (this._isRange(this.values)) {
      this.answer = String(value);
    } else {
      const item = this.values[value];

      if (item === undefined || item.id === undefined) {
        // eslint-disable-next-line no-console
        console.error("Slider value is missing id!");
      }

      this.answer = item.id;
    }
  }

  _onSliderChange(event) {
    if (!event.detail) return;

    this._deselected = false;
    this._setAnswer(event.detail.value);
  }

  _onSliderInput(event) {
    this._sliderValue = event.currentTarget.value;
  }

  _onSliderClick(event) {
    this._deselected = false;
    this._setAnswer(event.target.value);
  }
}

window.customElements.define("widget-slider", WidgetSlider);
