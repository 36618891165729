import {css, html, LitElement} from "lit";
import {ifDefined} from "lit/directives/if-defined.js";

import "./ui-button.js";
import "./ui-image.js";
import "./ui-svg.js";
import "./ui-multi-selector.js";
import {mdDefaultRender} from "../utils/markdown.js";
import {getAssetPath, getImageSrcSet} from "../utils/utils.js";

const styles = css`
  :host {
    display: block;
    width: 100%;
    /* aspect-ratio: var(--aspect-ratio); is only for newest browsers */
    /* Aspect ratio is set by padding-top */
    /* https://css-tricks.com/aspect-ratio-boxes/ */
    height: 0;
    overflow: hidden;
    padding-top: calc(100% / (var(--buttons-aspect-ratio)));
    position: relative;
  }

  /* container is used for the aspect ratio */
  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .button {
    height: 100%;
    border-radius: 0;
    padding: 0;
  }

  .item {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
  }

  .item:nth-of-type(1),
  .item:nth-of-type(1) .item-image {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    margin: 0;
  }

  :host([rtl]) .item:nth-of-type(1),
  :host([rtl]) .item:nth-of-type(1) .item-image {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  .item:nth-of-type(1) > .button {
    border-radius: var(--border-radius-inner) 0 0 var(--border-radius-inner);
  }

  :host([rtl]) .item:nth-of-type(1) > .button {
    border-radius: 0 var(--border-radius-inner) var(--border-radius-inner) 0;
  }

  .item:nth-last-of-type(1),
  .item:nth-last-of-type(1) .item-image {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  :host([rtl]) .item:nth-last-of-type(1),
  :host([rtl]) .item:nth-last-of-type(1) .item-image {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    margin: 0;
  }

  .item:nth-last-of-type(1) > .button {
    border-radius: 0 var(--border-radius-inner) var(--border-radius-inner) 0;
  }

  :host([rtl]) .item:nth-last-of-type(1) > .button {
    border-radius: var(--border-radius-inner) 0 0 var(--border-radius-inner);
  }

  .item:nth-of-type(2),
  .item:nth-of-type(3) {
    margin-left: -2px;
  }

  :host([rtl]) .item:nth-of-type(1) {
    margin-left: -2px;
  }

  :host([rtl]) .item:nth-of-type(3) {
    margin-left: 0;
  }

  .items1 .item {
    border-radius: var(--border-radius) !important;
    width: 100%;
  }

  .items1 .item > .button {
    border-radius: var(--border-radius-inner) !important;
  }

  .items2 .item {
    width: calc(100% / 2);
  }

  .items3 .item {
    width: calc(100% / 3);
  }

  .button .label {
    line-height: 13px;
    font-size: 13px;
    padding: 6px;
    box-sizing: border-box;
    /* IE11 */
    width: 100%;
    text-align: center;
    user-select: none;
  }

  .item[is-image].ui-selected {
    padding: 0 !important;
  }

  .item[is-icon] .button .label,
  .item[is-image] .button .label {
    box-sizing: border-box;
    text-align: center;
    padding: 4px;
  }

  .item-image {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;

    --ui-image-width: 100%;
  }

  .item-imageContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .item[is-label] .item-imageContainer {
    height: 70%;
  }

  .button ui-svg {
    display: block;
    width: 28px;
    height: 28px;
    margin: 0 auto;
    fill: var(--primary-text-color);
  }

  .button.false,
  .button.no {
    color: var(--no-button-color);
  }

  .button.false ui-svg,
  .button.no ui-svg {
    fill: var(--no-button-color);
  }

  .button.true,
  .button.yes {
    color: var(--yes-button-color);
  }

  .button.true ui-svg,
  .button.yes ui-svg {
    fill: var(--yes-button-color);
  }

  /* Question answers are equal */
  .item.ui-selected {
    border: 2px solid var(--primary-color);
    background-color: var(--light-primary-color);
    color: var(--text-light-primary-color);
  }

  .item.ui-selected ui-svg {
    fill: var(--text-light-primary-color);
  }

  .item[is-image] {
    padding: 1px;
  }

  .item[is-image]:nth-of-type(2),
  .item[is-image]:nth-of-type(3) {
    padding-left: 2px;
  }

  .item[is-image].ui-selected .item-image:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    background-color: var(--accent-color);
    opacity: 0.5;
    z-index: 1;
  }

  .items {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
  }

  .skeleton {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--text-secondary-color);
    background-color: var(--secondary-color);
    box-sizing: border-box;
  }

  .skeleton-block {
    border: 1px dashed var(--divider-color);
    border-left: 0;
    box-sizing: border-box;
    margin-left: -2px;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
  }

  .blocks2 .skeleton-block {
    width: calc(100% / 2);
  }

  .blocks3 .skeleton-block {
    width: calc(100% / 3);
  }

  .skeleton-block:nth-of-type(1) {
    border-left: 1px dashed var(--divider-color);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    margin-left: 0;
  }

  .skeleton-block:nth-last-of-type(1) {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }

  :host([rtl]) .skeleton-block:nth-of-type(1) {
    border-left: 0;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    margin-left: -2px;
  }

  :host([rtl]) .skeleton-block:nth-last-of-type(1) {
    border-left: 1px dashed var(--divider-color);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    margin-left: 0;
  }

  .blocks1 .skeleton-block {
    border-radius: var(--border-radius) !important;
  }

  @media screen and (min-width: 330px) {
    .button ui-svg {
      width: 30px;
      height: 30px;
    }

    .button .label {
      line-height: 15px;
      font-size: 14px;
    }
  }

  @media screen and (min-width: 330px) and (min-height: 700px) {
    .button ui-svg {
      width: 32px;
      height: 32px;
    }
  }

  @media screen and (min-width: 600px) {
    .button .label {
      line-height: 16px;
      padding: 8px;
    }
  }
`;

/**
 * Buttons for single and multiple choice questions.
 * @element ui-buttons
 * @event selection-changed - Fired when the selection changes.
 */
class UIButtons extends LitElement {
  constructor() {
    super();

    /**
     * Array of buttons to show. Array of `Object`s: {id, icon, image, label}.
     * @type {Array<object>}
     */
    this.buttons = [];

    /**
     * Base URL for media assets.
     * @type {string}
     */
    this.mediaBaseUrl = "";

    /**
     * Multi selection mode.
     * @type {boolean}
     */
    this.multi = false;
  }

  static get styles() {
    return [styles];
  }

  static get properties() {
    return {
      buttons: {type: Array},
      multi: {type: Boolean},
    };
  }

  render() {
    const renderItem = (item) => html`
      <div
        class="item"
        name="${item.id}"
        is-icon="${ifDefined(item.icon)}"
        is-image="${ifDefined(item.image)}"
        is-label="${ifDefined(item.label)}"
      >
        <ui-button class="button ${item.id}">
          ${item.image
            ? html`
                <div class="item-imageContainer">
                  <ui-image
                    class="item-image"
                    .src="${this._computeButtonImage(item.image)}"
                    srcset="${ifDefined(
                      getImageSrcSet(
                        this._computeButtonImage.bind(this),
                        item.image,
                      ),
                    )}"
                  ></ui-image>
                </div>
              `
            : null}
          ${item.icon
            ? html`<ui-svg
                .src="${getAssetPath(this.mediaBaseUrl, item.icon)}"
              ></ui-svg>`
            : null}
          ${item.label
            ? html`<div class="label">${mdDefaultRender(item.label)}</div>`
            : null}
        </ui-button>
      </div>
    `;

    return html`
      <div class="container">
        <ui-multi-selector
          class="items items${this.buttons.length}"
          id="items"
          ?multi="${this.multi}"
          @selection-changed="${this._onSelectionChanged}"
        >
          ${this.buttons.map(renderItem)}
        </ui-multi-selector>

        <div class="skeleton blocks${this.buttons.length}" id="skeleton">
          ${this.buttons.map(() => html`<div class="skeleton-block"></div>`)}
        </div>
      </div>
    `;
  }

  /**
   * Reset selected button.
   */
  reset() {
    this.shadowRoot.querySelector("ui-multi-selector").reset();
  }

  _computeButtonImage(path) {
    if (!path) return path;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (path.indexOf("/") !== -1) return getAssetPath(this.mediaBaseUrl, path);

    // imgix
    // borders are 1px, keep 1px more as padding (that's closer to the original code)
    // max 3 buttons

    // use 1 as default to avoid division by 0
    const nValues = this.buttons ? this.buttons.length : 1;
    const nBorders = nValues + 1;
    const availWidth = this.offsetWidth - 2 * nBorders;
    const singleWidth = Math.round(availWidth / nValues);

    return `${getAssetPath(
      this.mediaBaseUrl,
      path,
    )}?auto=compress,format&h=78&w=${singleWidth}`;
  }

  _onSelectionChanged(event) {
    this.dispatchEvent(
      new CustomEvent("selection-changed", {
        detail: {selection: event.detail.selection},
      }),
    );
  }
}

window.customElements.define("ui-buttons", UIButtons);
