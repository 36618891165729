import {css} from "lit";

export const WidgetSliderStyles = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  md-slider {
    --md-sys-color-primary: var(--primary-color);

    width: 100%;

    /* hide focus ring */
    --md-focus-ring-color: transparent;
    overflow: hidden;
  }

  md-slider[deselected] {
    --md-sys-color-primary: #bdbdbd;
  }

  #readout {
    color: var(--accent-color);
    font-size: 50px;
    line-height: 1.1;
    padding: 0 16px;
    text-align: center;
  }

  #labels {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
    padding: 0 16px !important;
    box-sizing: border-box;
    line-height: 18px;
  }

  .left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    width: calc(100% / 3);
    height: 36px;
  }

  .right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    width: calc(100% / 3);
    height: 36px;
    text-align: right;
  }

  .center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 8px;
    width: calc(100% / 3);
    height: 36px;
  }

  ui-main-image {
    --ui-main-image-padding-bottom: 8px;
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) {
    #readout {
      font-size: 60px;
    }
  }
`;
