import {css, html, LitElement} from "lit";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";

import {customEvent} from "../utils/utils.js";

const style = css`
  :host {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
`;

class UISvg extends LitElement {
  constructor() {
    super();
    this.width = 24;
    this.height = 24;
    this.rtlMirroring = false;
    this.src = null;
  }

  static get styles() {
    return style;
  }

  static get properties() {
    return {
      height: {
        type: Number,
      },

      /**
       * Set to true to enable mirroring of SVG.
       */
      rtlMirroring: {
        type: Boolean,
      },

      src: {
        type: String,
      },

      width: {
        type: Number,
      },

      _svg: {
        type: Object,
      },
    };
  }

  render() {
    return html`${unsafeSVG(this._svg)}`;
  }

  firstUpdated() {
    this.dispatchEvent(
      new CustomEvent("ui-svg-waiting-for-media", {
        detail: {src: this.src},
        bubbles: true,
        composed: true,
      }),
    );
  }

  updated(changedProperties) {
    if (changedProperties.has("src")) this._srcChanged(this.src);
  }

  /**
   * Get the image and displays it.
   */
  async _srcChanged(src) {
    // Get SVG file content.
    if (src) {
      this._loadStartTime = +new Date();

      const response = await fetch(src);

      this.dispatchEvent(
        new CustomEvent("ui-svg-media-loaded", {
          detail: {
            src,
            loadTime: +new Date() - this._loadStartTime,
          },
          bubbles: true,
          composed: true,
        }),
      );

      let responseText = await response.text();

      const preserveAspectRatio = "xMidYMid meet";
      // TODO: `pointer-events: none` works around https://crbug.com/370136
      // TODO: inline style may not be ideal, but avoids requiring a shadow-root
      let styles =
        "pointer-events: none; display: block; width: 100%; height: 100%;";
      const xmlns = "http://www.w3.org/2000/svg";
      const viewBox = responseText.match(/viewBox="([^"]+)"/)[1];

      if (this.rtlMirroring) {
        styles += "-webkit-transform:scale(-1,1);transform:scale(-1,1);";
      }

      // Set new SVG Attributes.
      responseText = responseText.replace(
        /<svg[\s\S]*?>/i,
        `<svg xmlns="${xmlns}" viewBox="${viewBox}" preserveAspectRatio="${preserveAspectRatio}" style="${styles}">`,
      );

      this._svg = responseText;

      await this.updateComplete;

      this.dispatchEvent(customEvent("load"));
    }
  }
}

window.customElements.define("ui-svg", UISvg);
