import {svg} from "lit";

const svgWrap = (content) => svg`
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
    width="24px" height="24px" viewBox="0 0 24 24">${content}</svg>
`;

export const arrowBackIcon = svgWrap(
  svg`<path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>`,
);

export const feedbackIcon = svgWrap(
  svg`<path d="M12 15q.425 0 .713-.288T13 14t-.288-.712T12 13t-.712.288T11 14t.288.713T12 15m0-4q.425 0 .713-.288T13 10V6q0-.425-.288-.712T12 5t-.712.288T11 6v4q0 .425.288.713T12 11m-6 7l-2.3 2.3q-.475.475-1.088.213T2 19.575V4q0-.825.588-1.412T4 2h16q.825 0 1.413.588T22 4v12q0 .825-.587 1.413T20 18zm-.85-2H20V4H4v13.125zM4 16V4z"/>`,
);

export const redoIcon = svgWrap(
  svg`<path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z"></path>`,
);

export const checkBoxIcon = svgWrap(
  svg`<path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"/>`,
);

export const checkBoxOutlineBlankIcon = svgWrap(
  svg`<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>`,
);

export const radioButtonCheckedIcon = svgWrap(
  svg`<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/><circle cx="12" cy="12" r="5"/>`,
);

export const radioButtonUncheckedIcon = svgWrap(
  svg`<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>`,
);

export const checkIcon = svgWrap(
  svg`<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>`,
);

export const clearIcon = svgWrap(
  svg`<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>`,
);

export const pigIcon =
  svgWrap(svg`<path d="M0.498,8.93h1.495c0.343-0.517,0.83-1.606,1.435-2.283L1.84,4.927C1.766,4.851,1.738,4.718,1.766,4.614
C1.792,4.513,1.87,4.417,1.971,4.386c1.901-0.596,3.828,0.513,3.83,0.51c1.687-1.05,3.381-1.48,5.664-1.48
c5.125,0,9.331,3.15,9.933,7.22c1.097-0.02,1.835-1.116,2.224-1.896c0.04-0.081,0.134-0.125,0.225-0.103
C23.937,8.658,24,8.739,24,8.833c0,1.208-0.32,3.395-2.51,4.47c-0.195,0.674-0.295,2.332-2.423,3.822v3.625
c0,0.617-0.497,1.115-1.111,1.115h-2.938c-0.615,0-1.115-0.498-1.115-1.115v-1.183c-0.586,0.182-1.707,0.285-2.602,0.285
c-0.726,0-1.346-0.072-2.044-0.193v1.091c0,0.617-0.499,1.115-1.112,1.115H5.203c-0.612,0-1.112-0.498-1.112-1.115v-3.551
c-1.054-0.817-1.639-2.061-2.098-3.095H0.498c-0.501,0-0.537-0.145-0.537-0.647V9.805C-0.039,9.303-0.003,8.93,0.498,8.93
L0.498,8.93z M16.081,8.279c0.438,0.361,1.087,0.298,1.449-0.141c0.359-0.44,0.299-1.086-0.137-1.448
C15.469,5.094,10.632,3.096,6.086,6.96C5.652,7.333,5.602,7.982,5.967,8.416c0.205,0.24,0.494,0.364,0.787,0.364
c0.235,0,0.472-0.081,0.666-0.246C11.768,4.835,15.908,8.138,16.081,8.279L16.081,8.279z"/>`);
