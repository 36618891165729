import {css, html, LitElement} from "lit";

const style = css`
  :host {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }

  :host([has-scroll]) {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  /* Scrollbar Width */
  :host::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Scrollbar Track */
  :host::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ddd;
    border-radius: 4px;
  }

  /* Scrollbar Handle */
  :host::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
  }

  .inner-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  :host([isFlex]) .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

class UIContainer extends LitElement {
  static get styles() {
    return style;
  }

  static get properties() {
    return {
      autoMainSize: {type: Boolean},

      hasScroll: {type: Boolean, reflect: true},

      isFlex: {type: Boolean, reflect: true},
    };
  }

  constructor() {
    super();
    this.autoMainSize = false;
    this.hasScroll = false;
    this.isFlex = false;
  }

  render() {
    return html`
      <div class="inner-container" id="innerContainer">
        <slot></slot>
      </div>
    `;
  }

  firstUpdated() {
    if (!this.isFlex) this.addEventListener("ui-main-resize", this._onResize);

    [this.uiTitleElement] = this.shadowRoot
      .querySelector("slot")
      .assignedElements()
      .filter((element) => element.localName === "ui-title");
    [this.uiMainElement] = this.shadowRoot
      .querySelector("slot")
      .assignedElements()
      .filter((element) => element.localName === "ui-main");
    [this.uiMainImageElement] = this.shadowRoot
      .querySelector("slot")
      .assignedElements()
      .filter((element) => element.localName === "ui-main-image");

    this._resizeObserver = new ResizeObserver(() => {
      // We wrap it in requestAnimationFrame to avoid this error in test - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        this._onResize();
      });
    });

    if (!this.isFlex) this._resizeObserver.observe(this);
  }

  _onResize() {
    if (this.autoMainSize) this._setMainHeight();
    else this._setTitleHeight();
  }

  _setMainHeight() {
    if (this.uiMainElement) {
      let newMainHeight = this.offsetHeight;

      if (this.uiTitleElement) {
        newMainHeight -= this.uiTitleElement.offsetHeight;
      }

      if (this.uiMainImageElement) {
        newMainHeight -= this.uiMainImageElement.offsetHeight;
      }

      if (newMainHeight >= 0) {
        this.uiMainElement.style.height = `${newMainHeight}px`;
      }
    }
  }

  _setTitleHeight() {
    if (this.uiTitleElement) {
      let newTitleHeight = this.offsetHeight;

      if (this.uiMainElement) newTitleHeight -= this.uiMainElement.offsetHeight;

      if (this.uiMainImageElement) {
        newTitleHeight -= this.uiMainImageElement.offsetHeight;
      }

      if (newTitleHeight >= 0) {
        this.uiTitleElement.style.height = `${newTitleHeight}px`;
      }
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._resizeObserver.disconnect();
  }
}

window.customElements.define("ui-container", UIContainer);
