import {unsafeHTML} from "lit/directives/unsafe-html.js";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";

/**
 * Convert single asterisk to emphasized text.
 * @param {string} text
 */
export const mdEm = (text) => {
  const bold = /\*(.*?)\*/gm;
  return text ? text.replace(bold, "<em>$1</em>") : text;
};

/**
 * Convert double asterisks to bold text.
 * @param {string} text
 */
export const mdBold = (text) => {
  const bold = /\*\*(.*?)\*\*/gm;
  return text ? text.replace(bold, "<strong>$1</strong>") : text;
};

/**
 * Convert markdown link to HTML link.
 * @param {string} text
 */
export const mdLink = (text) => {
  // eslint-disable-next-line no-useless-escape
  const link = /\[([^\]]+)\]\(([^\)]+)\)/gm;
  return text
    ? text.replace(link, '<a href="$2" target="_blank">$1</a>')
    : text;
};

/**
 * Convert double asterisks to bold text for SVG.
 * @param {string} text
 */
export const mdBoldSVG = (text) => {
  const bold = /\*\*(.*?)\*\*/gm;
  return text
    ? unsafeSVG(text.replace(bold, '<tspan font-weight="bold">$1</tspan>'))
    : text;
};

/**
 * Render markdown with emphasized and bold formats.
 * @param {string} text
 */
export const mdDefaultRender = (text) => unsafeHTML(mdEm(mdBold(text)));
