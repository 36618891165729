import {css, html, LitElement} from "lit";
import {rafPromise} from "../utils/utils.js";

const style = css`
  :host {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .content {
    display: inherit;
    justify-content: inherit;
    flex-direction: inherit;
    align-items: inherit;
    overflow: auto;
    width: 100%;
    height: 100%;
    position: inherit;
  }

  .shadow {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100px;
    pointer-events: none;
    opacity: 0;
  }

  .shadow-top {
    top: 0;
    background: linear-gradient(var(--tertiary-background-color), transparent);
  }

  .shadow-bottom {
    bottom: 0;
    background: linear-gradient(transparent, var(--tertiary-background-color));
  }
`;

class UIMain extends LitElement {
  static get styles() {
    return style;
  }

  static get properties() {
    return {
      autoMainSize: {type: Boolean},
    };
  }

  constructor() {
    super();
    this.autoMainSize = false;
  }

  render() {
    return html`
      <div class="shadow shadow-top"></div>
      <div class="shadow shadow-bottom"></div>
      <div class="content">
        <slot></slot>
      </div>
    `;
  }

  async firstUpdated() {
    // Wait for the content to be rendered before checking if the scroll is needed.
    await rafPromise();

    this._content = this.shadowRoot.querySelector(".content");
    this._shadowTop = this.shadowRoot.querySelector(".shadow-top");
    this._shadowBottom = this.shadowRoot.querySelector(".shadow-bottom");

    this._resizeObserver = new ResizeObserver(() => {
      // We wrap it in requestAnimationFrame to avoid this error in test - ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        const event = new CustomEvent("ui-main-resize", {
          bubbles: true,
          composed: true,
        });
        this.dispatchEvent(event);

        // If the content is scrollable after resizing, display the shadow.
        if (this._content.scrollHeight > this._content.offsetHeight) {
          this._shadowBottom.style.opacity = 1;
        } else {
          this._shadowBottom.style.opacity = 0;
        }
      });
    });
    this._resizeObserver.observe(this);

    // Display shadows when the content is scrolled.
    // This is based on the following article:
    // https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/
    this._content.addEventListener(
      "scroll",
      this._handleContentScroll.bind(this),
    );
  }

  // Handle the scroll event of the content.
  _handleContentScroll() {
    // Calculate the scroll height of the content.
    const contentScrollHeight =
      this._content.scrollHeight - this._content.offsetHeight;

    // Calculate the current scroll position.
    const currentScroll = this._content.scrollTop / contentScrollHeight;

    // Display shadows based on the current scroll position.
    this._shadowTop.style.opacity = currentScroll;
    this._shadowBottom.style.opacity = 1 - currentScroll;
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this._resizeObserver.disconnect();
    this._content.removeEventListener("scroll", this._handleContentScroll);
  }
}

window.customElements.define("ui-main", UIMain);
