// Mixins with JavaScript Classes
// https://justinfagnani.com/2015/12/21/real-mixins-with-javascript-classes/

/**
 * ## Rankchoice widget on top of `WidgetMultiChoiceMixin`
 *
 * Use `WidgetRankChoiceMixin` to implement rank choice widgets
 * with `ui-multi-selector`.
 * Ranking interferes with normal multichoice, so it has to be implemented in
 * the same behavior.
 */
export const WidgetRankChoiceMixin = (superclass) =>
  class extends superclass {
    constructor() {
      super();

      /**
       * Rank all items by successive selections.
       * Overrides multi.
       */
      this.rank = false;
    }

    static get properties() {
      return {
        rank: {
          type: Boolean,
        },
      };
    }

    updated(changedProperties) {
      if (super.updated) super.updated(changedProperties);

      if (changedProperties.has("rank")) this._rankSet(this.rank);
    }

    /**
     * Turn off `multi` if `rank` is set to avoid any interference.
     */
    _rankSet(rank) {
      if (rank) this.multi = false;
    }
  };
