import {css, html} from "lit";
import {ifDefined} from "lit/directives/if-defined.js";

import {WidgetBaseElement} from "./widget-base-element.js";
import {WidgetMultiChoiceMixin} from "./mixins/widget-multi-choice-mixin.js";
import {RandomizeMixin} from "./mixins/randomize-mixin.js";
import {mdDefaultRender} from "./utils/markdown.js";

import "./ui-parts/ui-container.js";
import "./ui-parts/ui-chip.js";
import "./ui-parts/ui-image.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-main-image.js";
import "./ui-parts/ui-svg.js";
import "./ui-parts/ui-title.js";
import "./ui-parts/ui-multi-selector.js";

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    padding: var(--widget-gutter);
    position: relative;
    height: 100%;
  }

  .chip {
    cursor: pointer;
  }

  .chip .image {
    display: block;
    width: 28px;
    height: 28px;
  }

  .chip .icon {
    display: block;
    width: 28px;
    height: 28px;
    fill: var(--primary-text-color);
  }

  .chip[is-icon] .chip-label,
  .chip[is-image] .chip-label {
    margin: 0 10px 0 -2px;
  }

  .chip.ui-selected ui-svg {
    fill: var(--accent-color);
  }

  .items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto;
    /* IE11 */
    width: 100%;
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) {
    .chip .icon,
    .chip .image {
      margin-left: 12px !important;
    }

    .chip[is-icon] .chip-label,
    .chip[is-image] .chip-label {
      margin: 0 12px 0 -4px;
    }
  }
`;

/**
  ## Choose from options, 'chips' appearance

  Answers as horizontally wrapped chips - according to
  [material design](https://material.io/design/components/chips.html).
  Can contain a small inline icon (maybe emoji-only?!).
*/
export class WidgetChips extends WidgetMultiChoiceMixin(
  RandomizeMixin(WidgetBaseElement),
) {
  constructor() {
    super();
    this.image = "";
    this.values = [];
  }

  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      /**
       * The options to show. Array of `Object`s:
       * - `id` is used as slug to save the answer
       * - `label` text to display (should render emoji)
       * - `image` optional url for image
       * - `icon` optional url for icon, always SVG
       * @type {Array<object>}
       */
      values: {
        type: Array,
      },

      /**
       * URL path to image.
       */
      image: {
        type: String,
      },
    };
  }

  render() {
    const renderItem = (item) => html`
      <ui-chip
        name="${item.id}"
        raised
        is-icon="${ifDefined(item.icon)}"
        is-image="${ifDefined(item.image)}"
        class="chip ${item.id}"
      >
        ${item.image
          ? html`
              <ui-image
                class="image"
                slot="label"
                .src="${this._computeItemImagePath(item.image)}"
              ></ui-image>
            `
          : ""}
        ${item.icon
          ? html`
              <ui-svg
                class="icon"
                .src="${this._computeAssetPath(item.icon)}"
                slot="label"
              ></ui-svg>
            `
          : ""}
        ${item.label
          ? html`
              <div class="chip-label" slot="label">
                ${mdDefaultRender(item.label)}
              </div>
            `
          : ""}
      </ui-chip>
    `;

    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main-image
          .src="${this._computeAssetPath(this.image)}"
          .question="${this.question}"
        ></ui-main-image>

        <ui-main>
          <ui-multi-selector
            class="items"
            id="items"
            ?multi="${this.multi}"
            @selection-changed="${(e) => {
              this._selection = e.detail.selection;
            }}"
          >
            ${this._encodeValues(this.values).map(renderItem)}
          </ui-multi-selector>
        </ui-main>
      </ui-container>
    `;
  }

  _computeItemImagePath(path) {
    if (!path) return path;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (path.indexOf("/") !== -1) return this._computeAssetPath(path);

    // imgix note
    // all the items occupy 28px of width and 28px of height

    const itemSize = 28;

    return `${this._computeAssetPath(path)}?auto=compress,format&w=${itemSize}`;
  }
}

window.customElements.define("widget-chips", WidgetChips);
