import {css, html} from "lit";
import {unsafeHTML} from "lit/directives/unsafe-html.js";

import {WidgetBaseElement} from "./widget-base-element.js";
import {mdLink} from "./utils/markdown.js";
import {checkIcon, clearIcon, pigIcon} from "./qset-icons.js";

import "./ui-parts/ui-container.js";
import "./ui-parts/ui-title.js";

const style = css`
  :host {
    display: block;
    box-sizing: border-box;
    overflow-y: auto;
  }

  [inline-flex] {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }

  ui-title {
    padding: var(--gutter) var(--gutter) 0 var(--gutter);
    box-sizing: border-box;
  }

  .Result {
    display: flex;
    flex-direction: column;

    padding: var(--gutter);
  }

  .Result-subtext {
    font-size: 14px;
    margin-top: 8px;
  }

  .Result-text .incentive-unit {
    font-weight: 400;
  }

  .Result-text .answer {
    line-height: 23px;
  }

  .ResultItem {
    display: flex;
    flex-direction: row;

    border-bottom: 1px solid var(--divider-color);
    padding: var(--gutter);
  }

  .ResultItem-icon {
    margin-right: 16px;
    width: 32px;
    height: 32px;
  }

  .ResultItem-icon.green {
    fill: var(--green-color);
  }

  .ResultItem-icon.red {
    fill: var(--red-color);
  }

  .ResultItem-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 0.000000001px;
  }

  .ResultItem-text .answer {
    font-weight: 500;
  }

  .ResultItem-value {
    min-width: 48px;
    text-align: right;
    font-size: 32px;
    line-height: 36px;
    margin-left: 16px;
  }

  .ResultItem-value--green {
    color: var(--green-color);
  }

  .ResultItem-value--red {
    color: var(--red-color);
  }
`;

/**
  ## Display results of a test

  Each result has it's checkmark based on condition written in trendaro condition DSL.
*/
export class WidgetQuizAnswers extends WidgetBaseElement {
  constructor() {
    super();

    this.values = [];
    // pre-fill answer
    this.answer = {
      value: true,
    };

    this.incentiveUnit = "{{pig}}";

    this.addEventListener("enter-stage", this._onEnterStage);
  }

  static get styles() {
    return [super.styles, style];
  }

  static get properties() {
    return {
      /**
       * Incentive unit, default value is "{{pig}}".
       */
      incentiveUnit: {type: String},

      /**
       * An array of `Object`s:
       * - `question`: Rephrased (shortened?) question.
       * - `correctAnswer`: Rephrased correct answer.
       * - `correctIf`: Condition (in [condition DSL](#TrendaroBehaviors.WidgetBehavior).
       *   If `true`, show checkmark, if `false` show 'cross'.
       * - `incentiveValue`: The widget will return sum of `incentiveValue`s for all correct
       *   answers (default: 0).
       */
      values: {
        type: Array,
      },
    };
  }

  render() {
    return html`
      ${this._isOnStage
        ? html`
            <ui-container isFlex>
              <ui-title
                .question="${this.question}"
                .details="${this.details}"
              ></ui-title>

              ${this.values.map(
                (item) => html`
                  <div class="ResultItem">
                    <div
                      class="ResultItem-icon ${this._getResultItemValueColor(
                        item.correctIf,
                      )}"
                    >
                      ${this._getIcon(item.correctIf)}
                    </div>
                    <div class="ResultItem-text">
                      <span>${item.question}</span>
                      <div class="answer">
                        ${unsafeHTML(mdLink(item.correctAnswer))}
                      </div>
                    </div>
                    ${this._computeIsWinningIncentive()
                      ? html`
                          <div
                            class="ResultItem-value ResultItem-value--${this._getResultItemValueColor(
                              item.correctIf,
                            )}"
                          >
                            ${this._getIncentiveValue(
                              item.correctIf,
                              item.incentiveValue,
                            )}
                          </div>
                        `
                      : ""}
                  </div>
                `,
              )}
              ${this._computeIsWinningIncentive()
                ? html`
                    <div class="Result">
                      <div class="Result-text">
                        Gratulujeme! Za své odpovědi získáváte
                        <span inline-flex
                          ><b class="answer"
                            >${this.answer.totalIncentiveValue}</b
                          >&nbsp;<span class="incentive-unit" inline-flex
                            >${this.incentiveUnit === "{{pig}}"
                              ? pigIcon
                              : ""}</span
                          >.</span
                        >
                      </div>
                      <div class="Result-subtext">
                        Pokračujte prosím dál a vyplňte nám údaje potřebné pro
                        výplatu odměny.
                      </div>
                    </div>
                  `
                : ""}
            </ui-container>
          `
        : ""}
    `;
  }

  _computeIsWinningIncentive() {
    return Boolean(this.values[0].incentiveValue);
  }

  _getIcon(correctIf) {
    if (this._evalCondition(correctIf)) return checkIcon;

    return clearIcon;
  }

  _getIncentiveValue(correctIf, incentiveValue) {
    if (incentiveValue) {
      if (this._evalCondition(correctIf)) return `+${incentiveValue}`;

      return 0;
    }

    return "";
  }

  _getResultItemValueColor(correctIf) {
    if (this._evalCondition(correctIf)) return "green";

    return "red";
  }

  /**
   * On enetering stage calculate the incentive value.
   */
  _onEnterStage() {
    const totalIncentiveValue = this.values.reduce((acc, x) => {
      if ("incentiveValue" in x && this._evalCondition(x.correctIf)) {
        return acc + Number(x.incentiveValue);
      }

      return acc;
    }, 0);

    const answer = {watched: true};

    if (totalIncentiveValue) answer.totalIncentiveValue = totalIncentiveValue;

    this.answer = answer;
  }
}

window.customElements.define("widget-quiz-answers", WidgetQuizAnswers);
