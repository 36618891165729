import {css, html, LitElement} from "lit";

const styles = css`
  :host {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;

    --ui-spinner-bg-color: #f3f3f3; /* Light grey */
  }

  :host([visible]) {
    display: flex;
  }

  .spinner {
    border: 10px solid var(--ui-spinner-bg-color);
    border-top: 10px solid #000;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

class UISpinner extends LitElement {
  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      /**
       * Whether the spinner is visible or not.
       */
      visible: {type: Boolean, reflect: true},
    };
  }

  render() {
    return html`<div class="spinner"></div>`;
  }
}

window.customElements.define("ui-spinner", UISpinner);
