import {html} from "lit";
import {ifDefined} from "lit/directives/if-defined.js";

import {WidgetBaseElement} from "./widget-base-element.js";
import {RandomizeMixin} from "./mixins/randomize-mixin.js";
import {WidgetPairsStyles} from "./styles/widget-pairs-styles.js";
import {mdDefaultRender} from "./utils/markdown.js";
import {throttleGate} from "./utils/utils.js";

import "./ui-parts/ui-button.js";
import "./ui-parts/ui-container.js";
import "./ui-parts/ui-chip.js";
import "./ui-parts/ui-image.js";
import "./ui-parts/ui-main.js";
import "./ui-parts/ui-svg.js";
import "./ui-parts/ui-title.js";

/**
  ## Assign items in chips to items in buttons

  Display several chips on top, display boxes (buttons at the bottom),
  each chip can be dragged to one of the boxes. When it's assigned, it
  disappears from the chips list. Instead of dragging, the items can be
  tapped in sequence.
*/
export class WidgetPairs extends RandomizeMixin(WidgetBaseElement) {
  constructor() {
    super();
    this.values = [];
    this.buttons = [];
    /**
     * Array of pairs encoded in `Object`s: `[{chip:id, button:id}]`
     * - `chip` is id of the chip
     * - `button` is id of the button where the chip was dropped
     * - `icon` optional url for icon, always SVG
     * @type {Array<object>}
     */
    this.answer = null;
    this.randomizeRecipeButtons = null;
    this.minAssignedChips = 0;
    /**
     * Instead of using `values` to populate the chips,
     * load answers of a previous question. `sourceData` is
     * the id of that question, and the expected format is
     * array of labels (ids are automatically generated as consecutive numbers)
     * @type {String}
     */
    this.sourceData = undefined;
    this._answer = [];
    this._chips = [];
    this._chipsPages = [];
    this._selectedChipsPage = 0;

    /**
     * if the user is moving dragable chip
     */
    this._inDragMove = false;

    /**
     * if the user is currently dragging a chip and not just clicking
     */
    this._inDragState = false;

    this._measurementChip = {};

    // throttle gates
    // 100 times a second should be enough
    this._throttleGateTouchMove = throttleGate(10);

    // 500ms is enough to prevent double tap
    this._throttleGateTouchStart = throttleGate(500);

    this.addEventListener("enter-stage", this._onEnterStage);
    this.addEventListener("exit-stage", this._onExitStage);
  }

  static get styles() {
    return [super.styles, WidgetPairsStyles];
  }

  static get properties() {
    return {
      /**
       * Array of options to choose from,
       * `{id: slug,
       * label: text to display, inline markdown formating (italic, bold)
       * image: linked image,
       * icon: SVG icon}`.
       * @type {Array<object>}
       */
      values: {
        type: Array,
      },

      /**
       * Buttons where chips are assigned. Array of `Object`s:
       * - `id` is used as slug to save the answer
       * - `image` optional url for image
       * - `label` optional text to display
       * - `icon` optional url for icon, always SVG
       * @type {Array<object>}
       */
      buttons: {
        type: Array,
      },

      /**
       * A [ranDSL](#TrendaroBehaviors.RandomizeBehavior) recipe, which will be applied
       * to the contents of `buttons` array.
       */
      randomizeRecipeButtons: {
        type: String,
      },

      /**
       * Minumum assigned chips for the answer to be valid.
       * 0 means all, which is default (to continue with no assignment
       * use `skipTimeout`).
       */
      minAssignedChips: {
        type: Number,
      },

      _chips: {
        type: Object,
      },

      _chipsPages: {
        type: Array,
      },

      _selectedChipsPage: {
        type: Number,
      },

      _measurementChip: {
        type: Object,
      },
    };
  }

  render() {
    const renderChipsPage = (page) => html`
      <div
        class="chips-selector ${`chipsPage${this._selectedChipsPage}` ===
        page.id
          ? "selected"
          : ""}"
        id="${page.id}"
      >
        ${page.chips.map(
          (chip) => html`
            <ui-chip
              class="chip"
              id="chip${chip.id}"
              page-id="${page.id}"
              is-icon="${ifDefined(chip.icon)}"
              is-image="${ifDefined(chip.image)}"
              @mousedown="${this._onTouchStart}"
              @touchstart="${this._onTouchStart}"
            >
              ${chip.image
                ? html`
                    <ui-image
                      class="image"
                      slot="label"
                      .src="${this._computeItemImagePath(chip.image)}"
                      srcset="${ifDefined(
                        this._computeImageSrcSet(
                          this._computeItemImagePath.bind(this),
                          chip.image,
                        ),
                      )}"
                    ></ui-image>
                  `
                : ""}
              ${chip.icon
                ? html`
                    <ui-svg
                      class="icon"
                      .src="${this._computeAssetPath(chip.icon)}"
                      slot="label"
                    ></ui-svg>
                  `
                : ""}
              ${chip.label
                ? html`
                    <div class="chip-label" slot="label">
                      ${mdDefaultRender(chip.label)}
                    </div>
                  `
                : ""}
            </ui-chip>
          `,
        )}
      </div>
    `;

    const renderMeasurementChip = (chip) => html`
      <ui-chip
        class="chip chip-measurement"
        id="measurementChip"
        is-icon="${ifDefined(chip.icon)}"
        is-image="${ifDefined(chip.image)}"
      >
        ${chip.image
          ? html`
              <ui-image
                class="image"
                slot="label"
                .src="${this._computeItemImagePath(chip.image)}"
                @ui-image-waiting-for-media="${(e) => e.stopPropagation()}"
                @ui-image-media-loaded="${(e) => e.stopPropagation()}"
              ></ui-image>
            `
          : ""}
        ${chip.icon
          ? html`
              <ui-svg
                class="icon"
                .src="${this._computeAssetPath(chip.icon)}"
                slot="label"
                @ui-svg-waiting-for-media="${(e) => e.stopPropagation()}"
                @ui-svg-media-loaded="${(e) => e.stopPropagation()}"
              ></ui-svg>
            `
          : ""}
        ${chip.label
          ? html`
              <div class="chip-label" slot="label">
                ${mdDefaultRender(chip.label)}
              </div>
            `
          : ""}
      </ui-chip>
    `;

    const renderItem = (item) => html`
      <div
        name="${item.id}"
        ?has-icon="${this._isValue(item.icon)}"
        ?has-image="${this._isValue(item.image)}"
        ?has-label="${this._isValue(item.label)}"
        class="item"
        @click="${this._onButtonClick}"
      >
        <ui-button class="button" tabindex="-1" name="${item.id}">
          ${item.image
            ? html`
                <div class="item-imageContainer">
                  <ui-image
                    class="item-image"
                    .src="${this._computeItemImagePath(item.image)}"
                    srcset="${ifDefined(
                      this._computeImageSrcSet(
                        this._computeItemImagePath.bind(this),
                        item.image,
                      ),
                    )}"
                  ></ui-image>
                </div>
              `
            : ""}
          ${item.icon
            ? html`
                <div class="item-iconContainer">
                  <ui-svg
                    class="item-icon"
                    .src="${this._computeAssetPath(item.icon)}"
                  ></ui-svg>
                </div>
              `
            : ""}
          ${item.label
            ? html`<div class="label">${mdDefaultRender(item.label)}</div>`
            : ""}
        </ui-button>
      </div>
    `;

    return html`
      <ui-container isFlex>
        <ui-title
          .question="${this.question}"
          .details="${this.details}"
        ></ui-title>

        <ui-main>
          ${renderMeasurementChip(this._measurementChip)}

          <div class="chips-counter">
            ${this._computeChipsPagesCounter(
              this._selectedChipsPage,
              this._chipsPages,
            )}
          </div>

          <div class="chips-pages" id="chipsPages">
            ${this._chipsPages.map(renderChipsPage)}
          </div>

          <div class="items items${this.buttons.length}" id="items">
            ${this._encodeValues(this.buttons).map(renderItem)}
          </div>

          <div class="skeleton blocks${this.buttons.length}">
            ${this.buttons.map(() => html`<div class="skeleton-block"></div>`)}
          </div>
        </ui-main>
      </ui-container>
    `;
  }

  _onStageReady() {
    this._displayChips();
  }

  _onButtonClick(event) {
    // if the user is dragging or the chip is not selected,
    // ignore button selections
    if (this._inDragState || !this._selectedChipElement) return;

    this._selectedButton = event.currentTarget.getAttribute("name");
    this.shadowRoot.querySelectorAll(".item").forEach((item) => {
      item.classList.remove("ui-selected");
    });
    event.currentTarget.classList.add("ui-selected");
    this._selectedButtonChanged();
  }

  _deselectButton() {
    if (this._selectedButton) {
      this.shadowRoot
        .querySelector(`.item[name="${this._selectedButton}"]`)
        .classList.remove("ui-selected");
      this._selectedButton = "";
    }
  }

  _deselectChip() {
    // deselect the chip element in UI
    // added optional chaining `?.` to prevent error when the chip is not in the DOM
    // the error is thrown when the user clicks on the button, found in Sentry
    // the error is not reproducible by manual testing
    // TODO: investigate why the chip is not in the DOM
    this._selectedChipElement?.classList.remove("ui-selected");

    // deselect the chip element in state
    this._selectedChipElement = null;
  }

  _computeChipsPagesCounter(selectedChipsPages, chipsPages) {
    if (chipsPages.length > 1) {
      // avoid the embarassig 5/4 counter..;)
      const current = Math.min(selectedChipsPages + 1, chipsPages.length);
      return `${current}/${chipsPages.length}`;
    }
    return "";
  }

  async _computeChipsPages() {
    //
    // Measure the width of all chips via rendering them in a hidden DOM element
    //
    const chips = [];
    const measurementChip = this.shadowRoot.getElementById("measurementChip");

    measurementChip.style.display = "flex";
    for (const value of this._encodeValues(this.values)) {
      const chip = {
        id: value.id,
        label: value.label,
        icon: value.icon,
        image: value.image,
      };

      this._measurementChip = chip;

      // eslint-disable-next-line no-await-in-loop
      await this.updateComplete;

      // Get chip width rounded up with 8px margin.
      chip.width = Math.ceil(measurementChip.getBoundingClientRect().width) + 8;

      chips.push(chip);
    }
    measurementChip.style.display = "none";

    //
    // get the page width, trunced value is enough,
    // we don't need to be precise and zoomed screen contains float value on Windows
    //
    let chipsPageWidth = Math.trunc(
      this.shadowRoot.getElementById("chipsPages").getBoundingClientRect()
        .width,
    );

    // Show one chip per row on IE11
    const isIEBrowser = /* @cc_on!@ */ false || !!document.documentMode;
    if (isIEBrowser) chipsPageWidth = 0;

    //
    // Lay out the measured chips into a box as wide as the currently available space
    //
    const chipRows = chips.reduce(
      (rows, chip) => {
        const currentRow = rows[rows.length - 1];
        const currentX = currentRow.reduce(
          (sum, _chip) => sum + _chip.width,
          0,
        );

        // chip fits in the current row
        const chipFits = currentX + chip.width < chipsPageWidth;

        // does not fit in, but it's the first on the row, place it anyways
        const chipFirstExceeding = currentX === 0 && !chipFits;

        // put chip the in the current row
        // or add a new row
        if (chipFits || chipFirstExceeding) currentRow.push(chip);
        else rows.push([chip]);

        // eslint-disable-next-line no-console
        // console.log(currentX, chip.width, chipFits, chipFirstExceeding);

        return rows;
      },
      [[]],
    );

    //
    // split the 'chips' into pages of given number of rows per page
    // - the rows are not explicit, they are 'expected to happen'
    //   when flex layout is used, so we need to merge the rows into one array
    //
    const chipsPages = [];
    const rowsPerPage = 2;
    for (
      let currentRow = 0;
      currentRow < chipRows.length;
      currentRow += rowsPerPage
    ) {
      const pageChips = chipRows
        .slice(currentRow, currentRow + rowsPerPage)
        .flat(1);

      chipsPages.push({
        id: `chipsPage${chipsPages.length}`,
        chips: pageChips,
      });
    }

    // eslint-disable-next-line no-console
    // console.log(this.id, '_computeChipsPages', chips, chipsPageWidth, chipRows, chipsPages);

    this._chipsPages = chipsPages;

    // event for testing
    setTimeout(() => {
      this.dispatchEvent(new Event("chips-pages-loaded"));
    }, 0);
  }

  _onEnterStage() {
    this._isEnteredStage = true;

    // randomize the target buttons if requested
    if (this.randomizeRecipeButtons) {
      this.buttons = this._randomize(this.buttons, this.randomizeRecipeButtons);
    }

    // Get values from answers of previous question
    if (this.sourceData !== undefined) {
      const data = this._getValue(`self.${this.sourceData}`);
      const values = [];

      for (let i = 0; i < data.length; i += 1) {
        values.push({id: `id${i}`, label: data[i]});
      }

      this.values = values;
    }

    // use length if minAssignedChips is 0 or if minAssignedChips > length
    this._requiredAssignments = Math.min(
      this.minAssignedChips || this.values.length,
      this.values.length,
    );

    // use names to deregister the listeners correctly
    this._boundOnTouchMove = this._onTouchMove.bind(this);
    this._boundOnTouchEnd = this._onTouchEnd.bind(this);
    this._boundOnGlobalTouchStart = this._onGlobalTouchStart.bind(this);

    document.addEventListener("mousemove", this._boundOnTouchMove);
    document.addEventListener("mouseup", this._boundOnTouchEnd);
    document.addEventListener("touchend", this._boundOnTouchEnd);

    // Disable passive event listener to prevent `touchmove` from scrolling window on iOS
    // https://stackoverflow.com/questions/49500339/cant-prevent-touchmove-from-scrolling-window-on-ios
    document.addEventListener("touchmove", this._boundOnTouchMove, {
      passive: false,
    });

    // help to disable scrolling window on iOS
    // https://stackoverflow.com/a/71762627/1614237
    document.addEventListener("touchstart", this._boundOnGlobalTouchStart, {
      passive: false, // for support to call preventDefault()
    });
  }

  _onExitStage() {
    this._isEnteredStage = false;
    document.removeEventListener("mousemove", this._boundOnTouchMove);
    document.removeEventListener("touchmove", this._boundOnTouchMove);
    document.removeEventListener("mouseup", this._boundOnTouchEnd);
    document.removeEventListener("touchend", this._boundOnTouchEnd);
    document.removeEventListener("touchstart", this._boundOnGlobalTouchStart);
  }

  _onGlobalTouchStart(event) {
    if (!this._inDragState) return;

    // Disable scroll on iOS
    event.preventDefault();
  }

  _onTouchStart(event) {
    // throttle touch start to prevent accidental double tap, especially on mobile (iOS)
    if (!this._throttleGateTouchStart()) return;

    // user is in drag state by mouse down or touch start event
    this._inDragState = true;

    // get the chip element
    this._selectedChipElement = event.currentTarget;

    // if the chip is not selected, ignore the event
    if (!this._selectedChipElement) return;

    // deselect all chips in UI
    const chips = this.shadowRoot.querySelectorAll(".chip");
    chips.forEach((item) => {
      item.classList.remove("ui-selected");
    });

    // select the chip element in UI
    this._selectedChipElement.classList.add("ui-selected");

    // clone the chip element to be able to move it
    this._selectedChipElementClone = this._selectedChipElement.cloneNode(true);

    // copy the chip position and size
    this._chips[this._selectedChipElement.id] = {};
    this._copyElementBox(
      this._selectedChipElement,
      this._chips[this._selectedChipElement.id],
    );
  }

  _onTouchMove(event) {
    if (!this._inDragState) return;

    // Disable scroll on mobile screen
    event.preventDefault();

    this._inDragMove = true;

    if (!this._throttleGateTouchMove()) return;

    // if the chip is not selected, ignore the event
    if (!this._selectedChipElement) return;

    const clientX =
      event.clientX || (event.touches && event.touches[0].clientX);
    const clientY =
      event.clientY || (event.touches && event.touches[0].clientY);

    if (!clientX || !clientY) return;

    this._selectedChipElement.classList.add("move");

    if (!this._selectedChipElementClone.classList.contains("clone")) {
      this._selectedChipElement.parentNode.insertBefore(
        this._selectedChipElementClone,
        this._selectedChipElement.nextSibling,
      );
      this._selectedChipElementClone.classList.add("clone");
    }

    // The chip position is relative to the div element with id `chipsPages`.
    // The element has style `position: relative;`.
    const x =
      clientX -
      this.shadowRoot.getElementById("chipsPages").getBoundingClientRect().left;
    const y =
      clientY -
      this.shadowRoot.getElementById("chipsPages").getBoundingClientRect().top;

    this._selectedChipElement.style.left = `${
      x - this._chips[this._selectedChipElement.id].width / 2
    }px`;
    this._selectedChipElement.style.top = `${
      y - this._chips[this._selectedChipElement.id].height / 2
    }px`;

    this._activeButton = this._getButtonFromPoint(
      clientX,
      clientY,
      this._chips[this._selectedChipElement.id].height / 2,
    );

    const buttons = this.shadowRoot.querySelectorAll(".item");
    buttons.forEach((button) => {
      button.classList.remove("over");
    });

    if (this._activeButton) this._activeButton.classList.add("over");
  }

  _onTouchEnd() {
    // if the user is not dragging the chip, ignore the event
    if (!this._inDragMove) {
      this._inDragState = false;
      return;
    }

    // if the chip is not selected, ignore the event
    if (!this._selectedChipElement) return;

    // put the chip element back to the original position
    this._selectedChipElement.classList.remove("move");

    // the chip is on active button
    if (this._activeButton) {
      const selectedChipEl = this._selectedChipElement;

      const activeButton = this._activeButton;
      this._activeButton = null;

      // reset the state so clicking on a button does not submit another answer
      this._deselectChip();

      // remove the chip element from the DOM
      // cloned chip `_selectedChipElementClone` stays in the DOM to maintain position of other chips
      selectedChipEl.remove();

      // save new pair of the chip and the button
      this._extendAnswer(activeButton.getAttribute("name"), selectedChipEl.id);

      // disable buttons if all chips are assigned
      if (this._answer.length === this.values.length) {
        const buttons = this.shadowRoot.querySelectorAll(".button");
        buttons.forEach((button) => {
          // eslint-disable-next-line no-param-reassign
          button.disabled = true;
        });
        this.shadowRoot.getElementById("items").style.pointerEvents = "none";
      }

      // deselect the drop target
      // a little later to indicate where did it go
      setTimeout(() => {
        activeButton.classList.remove("over");
      }, 300);
    } else {
      this._selectedChipElementClone.remove();
      this._deselectChip();
    }

    this._inDragMove = false;
    this._inDragState = false;
  }

  // get the button element from the point
  // topOffset is used to choice the button when the chip is over the button on the top
  _getButtonFromPoint(x, y, topOffset = 0) {
    const buttons = this.shadowRoot.querySelectorAll(".item");
    let buttonFromPoint = null;

    buttons.forEach((button) => {
      const {left, right, top, bottom} = button.getBoundingClientRect();

      if (x >= left && x <= right && y >= top - topOffset && y <= bottom) {
        buttonFromPoint = button;
      }
    });

    return buttonFromPoint;
  }

  _copyElementBox(element, destination) {
    const dest = destination;
    dest.left = element.getBoundingClientRect().left + window.pageXOffset;
    dest.top = element.getBoundingClientRect().top + window.pageYOffset;
    dest.width = element.offsetWidth;
    dest.height = element.offsetHeight;
  }

  _selectedButtonChanged() {
    // make a local copy, if the globals change eg during animation
    const selectedButton = this._selectedButton;
    const selectedChip = this._selectedChipElement.id;

    if (!selectedButton) return;

    // if no chip was selected, only flash the button
    if (!selectedChip) {
      setTimeout(() => {
        this._deselectButton();
      }, 300);

      return;
    }

    // 'fly' the chip to the button

    this._animateChip(selectedChip, selectedButton).then(() => {
      // save the answer after the animation is finished
      this._extendAnswer(selectedButton, selectedChip);

      // hide selected chip
      const chip = this.shadowRoot.querySelector(`.chip#${selectedChip}`);
      chip.style.opacity = "0";
      chip.style.pointerEvents = "none";

      // deselect the chip
      this._deselectChip();

      // deselect the button
      this._deselectButton();
    });
  }

  _extendAnswer(buttonId, chipId) {
    this._answer.push({
      chip: this._decodeSlug(chipId.substring(4)),
      button: this._decodeSlug(buttonId),
    });

    // do not require confirmation after assignment of last chip
    if (this._answer.length === this.values.length) {
      this._skipNextActionButton = true;
    }

    if (this._answer.length >= this._requiredAssignments) {
      this.answer = this._answer.slice(0);
    }

    // scroll to next page, if the current page is completed
    // FIXME(libor): why here, why so complicated?
    let chipsAvailable = 0;
    for (let index = 0; index <= this._selectedChipsPage; index += 1) {
      chipsAvailable += this._chipsPages[index].chips.length;
    }

    if (
      this._answer.length === chipsAvailable &&
      this._chipsPages.length > this._selectedChipsPage + 1
    ) {
      // Wait for animation of the chip move.
      setTimeout(() => {
        this._selectedChipsPage += 1;
        this._animateChipPage(this._selectedChipsPage).then(() => {
          this.dispatchEvent(new Event("animation-finished"));
        });
      }, 500);
    } else {
      // Wait for animation of the chip move.
      setTimeout(() => {
        this.dispatchEvent(new Event("animation-finished"));
      }, 500);
    }
  }

  // set up chip animation
  _animateChip(chipId, buttonId) {
    if (!buttonId || !chipId) {
      // this should not happen
      // eslint-disable-next-line no-console
      console.error("_animateChip() missing button or chip", buttonId, chipId);
      return Promise.resolve();
    }

    // Animation as progressive enhancement
    if (typeof document.head.animate !== "function") return Promise.resolve();

    const chip = this.shadowRoot.querySelector(`.chip#${chipId}`);
    const button = this.shadowRoot.querySelector(`.button[name="${buttonId}"]`);
    const buttonLeft = button.getBoundingClientRect().left;
    const buttonTop = button.getBoundingClientRect().top;
    const buttonWidth = button.offsetWidth;
    const chipLeft = chip.getBoundingClientRect().left;
    const chipTop = chip.getBoundingClientRect().top;
    const chipWidth = chip.offsetWidth;
    const translateX =
      buttonWidth / 2 - chipWidth / 2 - (chipLeft - buttonLeft);
    const translateY = buttonTop - chipTop + 8;

    const animation = chip.animate(
      {
        // opacity: [1, 0.5],
        transform: ["none", `translate(${translateX}px,${translateY}px)`],
      },
      {
        // direction: 'alternate',
        duration: 150,
        easing: "cubic-bezier(.4,0,.2,1)",
      },
    );

    return new Promise((resolve) => {
      animation.onfinish = resolve;
    });
  }

  // slide in next chipsPage
  _animateChipPage(pageId) {
    if (!pageId) {
      // this should not happen
      // eslint-disable-next-line no-console
      console.error("_animateChipPage() missing page", pageId);
      return Promise.resolve();
    }

    // Animation as progressive enhancement
    if (typeof document.head.animate !== "function") return Promise.resolve();

    const nextPage = this.shadowRoot.getElementById(`chipsPage${pageId}`);
    const chipsPagesWidth =
      this.shadowRoot.getElementById("chipsPages").offsetWidth;

    const animation = nextPage.animate(
      {
        opacity: [0, 1],
        left: [`${chipsPagesWidth}px`, 0],
        transform: ["none", "translateX(0px"],
      },
      {
        duration: 500,
        easing: "cubic-bezier(.4,0,.2,1)",
      },
    );

    return new Promise((resolve) => {
      animation.onfinish = resolve;
    });
  }

  async _displayChips() {
    await this.updateComplete;

    this._computeChipsPages();
  }

  _onWidgetResize() {
    if (this.offsetWidth) {
      this._setButtonsSize();

      // display chips only if the widget is in the stage
      // because the chips have inconsistencies in the width using getBoundingClientRect()
      // during a card animation
      if (this._isEnteredStage) this._displayChips();
    }
  }

  _setButtonsSize() {
    const aspectRatio = getComputedStyle(this).getPropertyValue(
      "--buttons-aspect-ratio",
    );
    const height =
      this.shadowRoot.getElementById("items").offsetWidth / aspectRatio;

    const items = this.shadowRoot.querySelectorAll(".item");
    items.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.style.height = `${height}px`;
    });

    const blocks = this.shadowRoot.querySelectorAll(".skeleton-block");
    blocks.forEach((block) => {
      // eslint-disable-next-line no-param-reassign
      block.style.height = `${height}px`;
    });
  }

  _computeItemImagePath(path) {
    if (!path) return path;

    // skip if the path doesn't contain image hash, ex. widgets demo
    if (path.indexOf("/") !== -1) return this._computeAssetPath(path);

    // imgix note
    // borders are 1px, keep 1px more as padding (that's closer to the original code)
    // the widget has 3, 4 or 6 button items

    // use 1 as default to avoid division by 0
    const itemsCount = this.values ? this.values.length : 1;
    let itemsCountRow = 1;

    if (itemsCount === 6) itemsCountRow = 3;
    else if (itemsCount === 4) itemsCountRow = 2;
    else if (itemsCount === 3) itemsCountRow = 3;

    const bordersCountRow = itemsCountRow + 1;
    const availWidth = this.offsetWidth - 2 * bordersCountRow;

    const itemWidth = Math.round(availWidth / itemsCountRow);
    const itemHeight = 78;

    return `${this._computeAssetPath(
      path,
    )}?auto=compress,format&w=${itemWidth}&h=${itemHeight}`;
  }
}

window.customElements.define("widget-pairs", WidgetPairs);
