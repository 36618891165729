import {html, css, LitElement} from "lit";
import {RandomizeMixin} from "./mixins/randomize-mixin.js";
import {customEvent, updateShadyStyles} from "./utils/utils.js";
import {SharedStyles} from "./styles/shared-styles.js";
import "./qset-questions.js";

// Polyfill for ElementInternals standard used in MD components, this is required by Safari < 16.4
// https://github.com/material-components/material-web/blob/main/docs/support.md
// https://github.com/calebdwilliams/element-internals-polyfill
import "element-internals-polyfill";

const style = css`
  :host {
    display: block;
    padding-top: 2px;
    box-sizing: border-box;
    z-index: 0; /* to be below a card contains outro widget */
  }

  .pages {
    overflow: hidden;
    height: 100%;
  }
`;

export class MinuteQset extends RandomizeMixin(LitElement) {
  constructor() {
    super();

    this.resumeData = {};
    this.lastQuestionIndex = -1;
  }

  static get styles() {
    return [SharedStyles, style];
  }

  static get properties() {
    return {
      /**
       * Json describing the actional questionnaire.
       *
       * Check [QSet docs](#qset-docs) for detailed description of possible values.
       */
      qset: {type: Object},

      /**
       * Key-value storage for additional info associated with the user.
       * E. g. for labels.
       *
       * @type {{set: {key: value}}}
       */
      userInfo: {
        type: Object,
      },

      /**
       * Last question index for compute currentQuestionIndex.
       */
      lastQuestionIndex: {
        type: Number,
      },

      /**
       * Resume data from previous qset state.
       */
      resumeData: {
        type: Object,
      },

      /**
       * Controls current state of the element. Can be one of:
       * `loading`, `questions`, `error`.
       */
      _selected: {
        type: String,
      },
    };
  }

  render() {
    return html`
      <qset-loading
        id="loading"
        ?hidden="${this._selected !== "loading"}"
      ></qset-loading>

      <qset-questions
        class="questions"
        id="questions"
        ?hidden="${this._selected !== "questions"}"
        .resumeData="${this.resumeData}"
        .lastQuestionIndex="${this.lastQuestionIndex}"
        .userInfo="${this.userInfo}"
        .qset="${this._qset}"
        .questions="${this._questions}"
        @widgets-ready="${this._showQuestions}"
        @loading-timeout="${this._onLoadingTimeout}"
        @qset-dismissed="${this._qsetDismissed}"
      ></qset-questions>

      <qset-error-view
        id="error"
        ?hidden="${this._selected !== "error"}"
      ></qset-error-view>
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has("qset")) this._newQset(this.qset);
  }

  _showLoading() {
    this._selected = "loading";
  }

  /**
   * Show the actual set questions with progress bar etc.
   */
  _showQuestions() {
    this._selected = "questions";
  }

  /**
   * Outro screens was dismissed, switch to the next state.
   */
  _qsetDismissed(event) {
    localStorage.removeItem("currentQuestionId");

    this.dispatchEvent(customEvent("qset-dismissed", event.detail, true));
  }

  /**
   * New qset arrived.
   * Fill in required fields with defaults,
   * handle failure in the load or show the intro.
   */
  _newQset(qset) {
    // nothing to do, go away;)
    if (!Object.keys(qset).length) {
      this.dispatchEvent(customEvent("qset-dismissed", null, true));
      return;
    }

    const newQset = qset;

    const setDefault = (key, value) => {
      if (newQset && !(key in newQset)) newQset[key] = value;
    };

    // set default values if they're not defined in qset
    setDefault("theme", "blue");
    setDefault("mediaBaseUrl", "");
    setDefault("cloudFlareBaseUrl", "");
    setDefault("hideBackButton", false);
    setDefault("randomizeRecipe", "");

    // new structure in Minute, support snake_case
    // and camelCase for randomize recipe
    if ("front_matter" in newQset) {
      const fm = newQset.front_matter;
      let rr = "";
      if ("randomizeRecipe" in fm) rr = fm.randomizeRecipe;

      if ("randomize_recipe" in fm) rr = fm.randomize_recipe;

      // eslint-disable-next-line no-param-reassign
      newQset.randomizeRecipe = rr;
    }

    // randomize questions
    // (no randomization happens if recipe is empty)
    // store this in qset, in case anything refers directly to that..
    if (
      newQset.randomizeRecipe &&
      newQset.result &&
      newQset.result.randomized_questions
    ) {
      newQset.questions = newQset.result.randomized_questions;
    } else {
      newQset.questions = this._randomize(
        newQset.questions,
        newQset.randomizeRecipe,
      );
      this.dispatchEvent(
        customEvent("save-randomized-questions", newQset.questions, true),
      );
    }

    if (newQset.theme) {
      if (newQset.theme.primary) {
        this.style.setProperty("--primary-color", newQset.theme.primary);
        this.style.setProperty("--accent-color", newQset.theme.primary);
      }
      if (newQset.theme.primaryText) {
        this.style.setProperty(
          "--text-primary-color",
          newQset.theme.primaryText,
        );
        this.style.setProperty(
          "--text-accent-color",
          newQset.theme.primaryText,
        );
      }
      if (newQset.theme.lightPrimary) {
        this.style.setProperty(
          "--light-primary-color",
          newQset.theme.lightPrimary,
        );
      }
      if (newQset.theme.lightPrimaryText) {
        this.style.setProperty(
          "--text-light-primary-color",
          newQset.theme.lightPrimaryText,
        );
      }
      if (newQset.theme.selectable) {
        this.style.setProperty("--secondary-color", newQset.theme.selectable);
      }
      if (newQset.theme.selectableText) {
        this.style.setProperty(
          "--text-secondary-color",
          newQset.theme.selectableText,
        );
      }
      if (newQset.theme.selectableBorder) {
        this.style.setProperty(
          "--divider-color",
          newQset.theme.selectableBorder,
        );
      }
      if (newQset.theme.buttonsYes) {
        this.style.setProperty("--yes-button-color", newQset.theme.buttonsYes);
      }
      if (newQset.theme.buttonsNo) {
        this.style.setProperty("--no-button-color", newQset.theme.buttonsNo);
      }
      if (newQset.theme.joystickGradientTop) {
        this.style.setProperty(
          "--action-button-top-gradient-color",
          newQset.theme.joystickGradientTop,
        );
      }
      if (newQset.theme.joystickGradientBottom) {
        this.style.setProperty(
          "--action-button-bottom-gradient-color",
          newQset.theme.joystickGradientBottom,
        );
      }
      if (newQset.theme.joystickText) {
        this.style.setProperty(
          "--action-button-text-color",
          newQset.theme.joystickText,
        );
      }
      if (newQset.theme.joystickShadow) {
        this.style.setProperty(
          "--action-button-shadow-color",
          newQset.theme.joystickShadow,
        );
      }
    }

    // this.updateStyles();
    updateShadyStyles(this);

    this._qset = newQset;
    this._questions = newQset.questions;
    this._showLoading();
  }

  _onLoadingTimeout() {
    this._selected = "error";
  }
}

window.customElements.define("minute-qset", MinuteQset);
