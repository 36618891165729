/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */

// Base58 Encoding/Decoding
// https://www.ietf.org/archive/id/draft-msporny-base58-03.txt

// Inspired by https://github.com/pur3miish/base58-js

/**
 * Base58 characters include numbers 123456789, uppercase ABCDEFGHJKLMNPQRSTUVWXYZ and lowercase abcdefghijkmnopqrstuvwxyz.
 * @typedef {String} base58Chars Base58 characters include numbers 123456789, uppercase ABCDEFGHJKLMNPQRSTUVWXYZ and lowercase abcdefghijkmnopqrstuvwxyz.
 */
const base58Chars =
  "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";

/**
 * Generates a mapping between base58 and ascii.
 * @returns {Array<Number>} mapping between ascii and base58.
 */
const createBase58Map = () => {
  const base58M = Array(256).fill(-1);

  for (let i = 0; i < base58Chars.length; i += 1) {
    base58M[base58Chars.charCodeAt(i)] = i;
  }

  return base58M;
};

const base58Map = createBase58Map();

/**
 * Converts a base58 string to the corresponding binary representation.
 * @param {base58Chars} base58String base58 encoded string.
 * @returns {Uint8Array} Binary representation for the base58 string.
 * @example
 * ```js
 * const bin = base58Decode("6MRy")
 * console.log(bin)
 * ```
 * Logged output will be Uint8Array(3) [15, 239, 64].
 */
const base58Decode = (base58String) => {
  if (!base58String || typeof base58String !== "string") {
    throw new Error(`Expected base58 string but got “${base58String}”`);
  }

  if (base58String.match(/[IOl0]/gmu)) {
    throw new Error(
      `Invalid base58 character “${base58String.match(/[IOl0]/gmu)}”`,
    );
  }

  const lz = base58String.match(/^1+/gmu);
  const psz = lz ? lz[0].length : 0;
  const size =
    ((base58String.length - psz) * (Math.log(58) / Math.log(256)) + 1) >>> 0;

  return new Uint8Array([
    ...new Uint8Array(psz),
    ...base58String
      .match(/.{1}/gmu)
      .map((i) => base58Chars.indexOf(i))
      .reduce((acc, i) => {
        acc = acc.map((j) => {
          const x = j * 58 + i;
          i = x >> 8;
          return x;
        });
        return acc;
      }, new Uint8Array(size))
      .reverse()
      .filter(
        (
          (lastValue) => (value) =>
            (lastValue = lastValue || value)
        )(false),
      ),
  ]);
};

/**
 * Converts a Uint8Array into a base58 string.
 * @param {Uint8Array} uint8array Unsigned integer array.
 * @returns {base58Chars} base58 string representation of the binary array.
 * @example <caption>Usage.</caption>
 * ```js
 * const str = base58Encode([15, 239, 64])
 * console.log(str)
 * ```
 * Logged output will be 6MRy.
 */
const base58Encode = (uint8array) => {
  const result = [];

  for (const byte of uint8array) {
    let carry = byte;

    for (let j = 0; j < result.length; j += 1) {
      const x = (base58Map[result[j]] << 8) + carry;
      result[j] = base58Chars.charCodeAt(x % 58);
      carry = (x / 58) | 0;
    }

    while (carry) {
      result.push(base58Chars.charCodeAt(carry % 58));
      carry = (carry / 58) | 0;
    }
  }

  for (const byte of uint8array) {
    if (byte) break;
    else result.push("1".charCodeAt(0));
  }

  result.reverse();

  // return string representation of the array of char codes
  return String.fromCharCode(...result);
};

/**
 * Encode slugs (ids) to Base58 in values array for using slugs in attributes of DOM.
 * @param {Array} values
 */
export const encodeValues = (values) => {
  // deep clone
  // https://stackoverflow.com/a/51969491/1614237
  const valuesClone = JSON.parse(JSON.stringify(values));

  return valuesClone.map((value) => {
    // don't encode `none` value, because ui-multi-selector uses `none` value for empty selection
    if (value.id === "none") return value;

    // convert utf8 string to Uint8Array
    const data = new TextEncoder().encode(value.id);

    // first char is always 'b' for valid `id` attribute, see https://stackoverflow.com/questions/70579/html-valid-id-attribute-values
    value.id = `b${base58Encode(data)}`;

    return value;
  });
};

/**
 * Decode slug (id) from Base58 to utf8 string.
 * @param {String} slug
 */
export const decodeSlug = (slug) => {
  // don't decode `none` value
  if (slug === "none") return slug;

  // remove first char from slug, because it's always 'b'
  const data = base58Decode(slug.slice(1));

  // convert Uint8Array to utf8 string
  return new TextDecoder().decode(data);
};

/**
 * Encode slug (id) from utf8 string to Base58.
 * @param {String} slug
 */
export const encodeSlug = (slug) => {
  // don't encode `none` value
  if (slug === "none") return slug;

  // convert utf8 string to Uint8Array
  const data = new TextEncoder().encode(slug);

  // first char is always 'b' for valid `id` attribute, see https://stackoverflow.com/questions/70579/html-valid-id-attribute-values
  return `b${base58Encode(data)}`;
};
