import {svg} from "lit";

export const WidgetEmotionIcons = {
  anger: svg`
    <g fill="#231f20" stroke="#231f20">
      <defs>
        <linearGradient id="anger-gradient" x1="12.119" y1="1.627" x2="12.034" y2="17.644" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#db3f54"/>
          <stop offset=".55" stop-color="#eeab6d"/>
          <stop offset=".759" stop-color="#f2c473"/>
          <stop offset="1" stop-color="#f7de79"/>
        </linearGradient>
      </defs>
      <circle id="background" cx="12" cy="12" r="10" fill="url(#anger-gradient)" stroke="none"/>
      <path d="M9.338 12.64a1.23 1.23 0 1 1-2.46 0 1.23 1.23 0 0 1 2.46 0" stroke="none"/>
      <path d="M5.926 10.339c1.458 1.705 4.28 1.555 4.28 1.555" stroke-miterlimit="10" fill="none" stroke-width=".909" stroke-linecap="round"/>
      <path d="M16.607 12.633a1.23 1.23 0 1 1-2.461 0 1.23 1.23 0 0 1 2.46 0" stroke="none"/>
      <path d="M18.08 10.333c-1.457 1.705-4.279 1.556-4.279 1.556" stroke-miterlimit="10" fill="none" stroke-width=".909" stroke-linecap="round"/>
      <path d="M9.04 17.96c3.01-2.507 5.927.106 5.927.106" stroke-miterlimit="10" fill="none" stroke-linecap="round"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  anxiety: svg`
    <g fill="#231f20" stroke="#231f20">
      <defs>
        <linearGradient id="anxiety-gradient" x1="36.78" y1="2.22" x2="36.864" y2="16.966" gradientUnits="userSpaceOnUse" gradientTransform="translate(-25.203 -.22)">
          <stop offset="0" stop-color="#f8dd7b"/>
          <stop offset=".547" stop-color="#c2caa5"/>
          <stop offset="1" stop-color="#7bb3df"/>
        </linearGradient>
      </defs>
      <circle id="background" cx="12" cy="12" r="10" overflow="visible" fill="url(#anxiety-gradient)" stroke="none"/>
      <path d="M9.576 17.435c2.462-1.884 4.856.087 4.856.087" stroke-miterlimit="10" fill="none" stroke-width="1.01" stroke-linecap="round"/>
      <path d="M18.96 6.947c-.057-.065-1.397-1.585-3.752-1.676-.194-.007-.361.114-.37.272-.01.158.14.293.336.3 2.002.078 3.194 1.42 3.206 1.433.069.08.178.122.29.122a.41.41 0 0 0 .202-.052c.16-.09.2-.27.087-.399M8.8 5.27c-2.356.093-3.696 1.612-3.752 1.677-.111.13-.073.308.087.399a.411.411 0 0 0 .203.052.39.39 0 0 0 .29-.122c.011-.013 1.204-1.355 3.206-1.433.195-.008.346-.142.336-.3-.01-.158-.176-.279-.37-.272" stroke="none"/>
      <path d="M11.502 10.326a2.853 2.853 0 1 1-5.706 0 2.853 2.853 0 0 1 5.706 0m6.709 0a2.853 2.853 0 1 1-5.706 0 2.853 2.853 0 0 1 5.706 0" fill="#fff" stroke="none"/>
      <path d="M7.852 10.462a.768.768 0 1 0 1.535 0 .768.768 0 0 0-1.535 0m6.734 0a.772.772 0 1 0 1.545 0 .772.772 0 0 0-1.545 0" stroke="none"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  boring: svg`
    <g fill="#231f20" stroke="#231f20">
      <circle id="background" cx="12" r="10" fill="#ffdd67" cy="12" stroke="none"/>
      <path d="M7.198 10.874a1.137 1.137 0 1 0 2.274 0 1.137 1.137 0 0 0-2.274 0m6.714-1.526a1.137 1.137 0 1 0 2.274 0 1.137 1.137 0 0 0-2.274 0" stroke="none"/>
      <path d="M15.886 15.196c-2.696-.554-5.257 1.717-5.257 1.717" stroke-linecap="round" stroke-miterlimit="10" fill="none"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  disgust: svg`
    <g fill="#231f20" stroke="#231f20">
      <defs>
        <linearGradient id="disgust-gradient" x1="36.78" y1="2.22" x2="36.695" y2="16.712" gradientUnits="userSpaceOnUse" gradientTransform="translate(-25.203 -.22)">
          <stop offset="0" stop-color="#eedc78"/>
          <stop offset=".518" stop-color="#c2caa5"/>
          <stop offset=".746" stop-color="#a9c879"/>
          <stop offset="1" stop-color="#91c64c"/>
        </linearGradient>
      </defs>
      <circle id="background" cx="12" cy="12" r="10" overflow="visible" fill="url(#disgust-gradient)" stroke="none"/>
      <path d="M15.72 12.729a3.024 3.024 0 0 0 3.033-3.033c0-.44-.11-.882-.275-1.268-.772-.358-1.737-.551-2.757-.551s-1.985.22-2.757.551c-.165.386-.275.8-.275 1.268a3.023 3.023 0 0 0 3.032 3.033" fill="#fff" stroke="none"/>
      <path d="M15.859 7.877c-.138.193-.22.441-.22.69 0 .689.55 1.24 1.24 1.24.689 0 1.24-.551 1.24-1.24 0-.11-.027-.194-.027-.276a6.443 6.443 0 0 0-2.233-.414" stroke="none"/>
      <path d="M8.278 12.729a3.024 3.024 0 0 0 3.032-3.033c0-.44-.11-.882-.275-1.268-.772-.358-1.737-.55-2.757-.55s-1.985.22-2.757.55c-.165.386-.276.8-.276 1.269a3.024 3.024 0 0 0 3.033 3.032" fill="#fff" stroke="none"/>
      <path d="M8.416 7.877a1.206 1.206 0 0 0-.221.69c0 .689.551 1.24 1.24 1.24.69 0 1.241-.551 1.241-1.24 0-.11-.027-.194-.027-.276a6.438 6.438 0 0 0-2.234-.414" stroke="none"/>
      <path d="M9.033 17.958c3.009-2.506 5.926.106 5.926.106" stroke-miterlimit="10" fill="none" stroke-linecap="round"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  excitement: svg`
    <g fill="#231f20" stroke="#231f20">
      <defs>
        <linearGradient id="excitement-gradient" x1="12.119" y1="1.627" x2="12.034" y2="17.644" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#db3f54"/>
          <stop offset=".55" stop-color="#eeab6d"/>
          <stop offset=".759" stop-color="#f2c473"/>
          <stop offset="1" stop-color="#f7de79"/>
        </linearGradient>
      </defs>
      <circle id="background" cx="12" cy="12" r="10" overflow="visible" fill="url(#excitement-gradient)" stroke="none"/>
      <path stroke-miterlimit="10" d="M10.174 4.85c-1.423-1.184-2.803.05-2.803.05m9.68-.05c-1.423-1.184-2.803.05-2.803.05" fill="none" stroke-linecap="round"/>
      <path d="M15.725 5.727c-1.682 0-3.033 2.175-3.033 4.884 0 .71.11 1.42.276 2.042.772.577 1.737.888 2.757.888s1.986-.356 2.758-.888a7.795 7.795 0 0 0 .276-2.042c0-2.709-1.352-4.884-3.034-4.884m-7.198 0c-1.682 0-3.033 2.175-3.033 4.884 0 .71.11 1.42.276 2.042.772.577 1.737.888 2.757.888s1.986-.356 2.758-.888a7.803 7.803 0 0 0 .275-2.042c0-2.709-1.35-4.884-3.033-4.884" fill="#fff" stroke="none"/>
      <path d="M12.003 19.963c2.602 0 4.691-1.39 4.691-3.122 0-.455-.17-.909-.426-1.307-1.194-.369-2.687-.567-4.265-.567-1.577 0-3.07.227-4.264.567-.256.398-.427.824-.427 1.307 0 1.731 2.09 3.122 4.691 3.122m1.92-10.191c0 1.319.796 2.388 1.777 2.388.982 0 1.777-1.07 1.777-2.388 0-1.32-.795-2.388-1.777-2.388-.98 0-1.777 1.069-1.777 2.388m-7.173 0c0 1.319.796 2.388 1.778 2.388.98 0 1.776-1.07 1.776-2.388 0-1.32-.795-2.388-1.776-2.388-.982 0-1.777 1.069-1.777 2.388" stroke="none"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  fear: svg`
    <g fill="#231f20" stroke="#231f20">
      <defs>
        <linearGradient id="fear-gradient" y2="16.966" gradientUnits="userSpaceOnUse" x2="36.864" gradientTransform="translate(-25.203 -.22)" y1="2.22" x1="36.78">
          <stop stop-color="#f8dd7b" offset="0"/>
          <stop stop-color="#c2caa5" offset=".547"/>
          <stop stop-color="#7bb3df" offset="1"/>
        </linearGradient>
      </defs>
      <circle id="background" cy="12" cx="12" overflow="visible" r="10" fill="url(#fear-gradient)" stroke="none"/>
      <path d="M11.297 9.074a2.852 2.852 0 1 1-5.704 0 2.852 2.852 0 0 1 5.704 0m6.705 0a2.852 2.852 0 1 1-5.704 0 2.852 2.852 0 0 1 5.704 0" fill="#fff" stroke="none"/>
      <path d="M7.648 9.205a.767.767 0 1 0 1.534 0 .767.767 0 0 0-1.534 0m6.731 0a.772.772 0 1 0 1.544.001.772.772 0 0 0-1.544-.001M9.952 16.676c0 1.79.889 3.24 1.985 3.24s1.985-1.45 1.985-3.24c0-1.79-.889-3.24-1.985-3.24s-1.985 1.45-1.985 3.24M18.953 5.76c-.056-.065-1.396-1.584-3.751-1.676-.194-.006-.36.114-.37.272-.01.158.141.292.336.3 2.002.078 3.194 1.42 3.205 1.432.13.13.331.159.492.07.16-.09.2-.269.087-.398M8.797 4.084c-2.355.092-3.695 1.611-3.75 1.676-.112.13-.073.307.086.398a.401.401 0 0 0 .203.052c.111 0 .22-.042.29-.121.011-.014 1.203-1.355 3.205-1.433.195-.007.345-.142.336-.3-.01-.158-.176-.278-.37-.272" stroke="none"/>
      <circle id="grayFilter" fill="#333" stroke="none" fill-opacity="0" cx="12" cy="12" r="10"/>
    </g>
  `,
  love: svg`
    <g>
      <circle id="background" cy="12" cx="12" r="10" fill="#ff5a79" stroke="none"/>
      <path fill="#fff" stroke="none" d="M12 18.156l-.897-.815c-3.183-2.887-5.284-4.791-5.284-7.127a3.366 3.366 0 0 1 3.399-3.399c1.076 0 2.108.5 2.782 1.291a3.7 3.7 0 0 1 2.781-1.291 3.365 3.365 0 0 1 3.399 3.399c0 2.336-2.101 4.24-5.284 7.133l-.896.809z"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  neutral: svg`
    <g fill="#231f20" stroke="#231f20">
      <circle id="background" cx="12" cy="12" r="10" fill="#d2d4cf" stroke="none"/>
      <path d="M7.429 10.143a1.143 1.143 0 1 0 2.286-.002 1.143 1.143 0 0 0-2.286.002m6.855 0a1.142 1.142 0 1 0 2.284 0 1.142 1.142 0 0 0-2.284 0" stroke="none"/>
      <path d="M9.443 15.945h5.111" fill="none" stroke-width=".767" stroke-linecap="round" stroke-miterlimit="10"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  relaxed: svg`
    <g fill="#231f20" stroke="#231f20">
      <defs>
        <radialGradient id="relaxed-gradient1" gradientUnits="userSpaceOnUse" cy="18.576" cx="-20.678" gradientTransform="matrix(0 -1.171 1 0 -.864 -9.79)" r="2.75">
          <stop stop-color="red" offset="0"/>
          <stop stop-color="#f7dd7b" offset="1"/>
        </radialGradient>
        <radialGradient id="relaxed-gradient2" gradientUnits="userSpaceOnUse" cy="18.576" cx="-20.678" gradientTransform="matrix(0 -1.171 1 0 -12.428 -9.837)" r="2.75">
          <stop stop-color="red" offset="0"/>
          <stop stop-color="#f7dd7b" offset="1"/>
        </radialGradient>
      </defs>
      <circle id="background" cy="12" cx="12" overflow="visible" r="10" fill="#f7dd7b" stroke="none"/>
      <path d="M9.281 10.354c-1.422-1.184-2.801.05-2.801.05m10.99-.05c-1.423-1.185-2.802.05-2.802.05" stroke-linecap="round" stroke-miterlimit="10" fill="none"/>
      <path d="M18.953 8.131c-.056-.065-1.395-1.584-3.75-1.676-.194-.006-.36.115-.37.272-.01.159.14.293.336.3 2.002.079 3.193 1.42 3.205 1.433.069.08.178.122.29.122a.41.41 0 0 0 .202-.052c.16-.09.2-.27.087-.399M8.797 6.455c-2.355.092-3.694 1.611-3.75 1.676-.112.13-.073.307.086.398a.411.411 0 0 0 .203.053.387.387 0 0 0 .29-.122c.012-.013 1.204-1.355 3.205-1.433.195-.007.346-.141.336-.3-.009-.158-.175-.278-.37-.272" stroke="none"/>
      <circle opacity=".2" cx="17.835" filter="url(#b)" cy="14.047" r="2.75" overflow="visible" fill="url(#relaxed-gradient1)"/>
      <circle opacity=".2" cx="6.271" filter="url(#d)" cy="14" r="2.75" overflow="visible" fill="url(#relaxed-gradient2)"/>
      <path d="M16.042 15.683c-4.125 3.039-8.14-.146-8.14-.146" stroke-linecap="round" stroke-miterlimit="10" fill="none"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  sad: svg`
    <g fill="#231f20" stroke="#231f20">
      <defs>
        <linearGradient id="sad-gradient" x1="36.78" y1="2.22" x2="36.949" y2="12.898" gradientUnits="userSpaceOnUse" gradientTransform="translate(-25.203 -.22)">
          <stop offset="0" stop-color="#7bb3df"/>
          <stop offset=".476" stop-color="#c2caa5"/>
          <stop offset="1" stop-color="#f8dd7b"/>
        </linearGradient>
      </defs>
      <circle id="background" cx="12" cy="12" r="10" overflow="visible" fill="url(#sad-gradient)" stroke="none"/>
      <path d="M6.833 11.47a1.13 1.13 0 1 0 2.258 0 1.13 1.13 0 0 0-2.258 0m8.187 0a1.13 1.13 0 1 0 2.259 0 1.13 1.13 0 0 0-2.258 0" stroke="none"/>
      <path d="M14.158 16.914c-2.194-1.485-4.308.049-4.308.049m8.337-7.488c-1.003-1.557-2.688-.791-2.688-.791m-9.595.791c1.003-1.557 2.689-.791 2.689-.791" stroke-miterlimit="10" fill="none" stroke-linecap="round"/>
      <path id="foreground" d="M19.5 20.405a1.314 1.314 0 1 1-2.628 0c0-.726 1.314-3.79 1.314-3.79s1.315 3.064 1.315 3.79" fill="#00adef" stroke="none"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  smile: svg`
    <g fill="#231f20" stroke="#231f20">
      <circle id="background" cx="12" cy="12" r="10" fill="#ffdd67" stroke="none"/>
      <path d="M7.484 10.259a1.128 1.128 0 1 0 2.256 0 1.128 1.128 0 0 0-2.256 0m6.774 0a1.129 1.129 0 1 0 2.258-.002 1.129 1.129 0 0 0-2.258.002" stroke="none"/>
      <path d="M14.963 15.972c-3.009 2.505-5.926-.106-5.926-.106" fill="none" stroke-linecap="round" stroke-miterlimit="10"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
  surprise: svg`
    <g fill="#231f20" stroke="#231f20">
      <defs>
        <linearGradient gradientTransform="translate(-.136 -.034)" id="surprise-gradient" x1="12.119" y1="1.627" x2="12.203" y2="7.39" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#db3f54"/>
          <stop offset=".404" stop-color="#eeab6d"/>
          <stop offset=".656" stop-color="#f2c473"/>
          <stop offset="1" stop-color="#f7de79"/>
        </linearGradient>
      </defs>
      <circle id="background" cx="12" cy="12" r="10" overflow="visible" fill="url(#surprise-gradient)" stroke="none"/>
      <path d="M6.587 9.366c0 1.02.616 1.849 1.375 1.849.76 0 1.376-.828 1.376-1.85 0-1.02-.616-1.848-1.376-1.848-.76 0-1.375.828-1.375 1.849m8.189 0c0 1.02.616 1.849 1.375 1.849.76 0 1.376-.828 1.376-1.85 0-1.02-.616-1.848-1.376-1.848-.76 0-1.375.828-1.375 1.849M8.979 16.05c0 2.238 1.353 4.052 3.022 4.052 1.669 0 3.021-1.814 3.021-4.051 0-2.238-1.352-4.052-3.021-4.052-1.67 0-3.022 1.814-3.022 4.052" stroke="none"/>
      <path stroke-miterlimit="10" d="M9.307 5.5c-1.423-1.184-2.802.05-2.802.05m10.992-.05c-1.423-1.184-2.802.05-2.802.05" fill="none" stroke-linecap="round"/>
      <circle id="grayFilter" fill="#333" fill-opacity="0" stroke="none" cx="12" cy="12" r="10"/>
    </g>
  `,
};
